import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EntityState, Predicate } from 'breeze-client';
import * as _ from 'lodash';

import { CrewType, Employee } from '../core/entities/entity-model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';

@Component({
    selector: 'crewtypes',
    template: `<h3>Crew type administration</h3>
               <hr />
               <button class="btn btn-primary pull-right" (click)="new()">Add new&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
               <list-table [list]="crewtypes" [headers]="displayFields" [type]="type" (delete)="delete($event)"></list-table>`
})
export class CrewTypesComponent implements OnInit
{
    type: string = 'sysadmin.crewtype';
    displayFields: string[] = ['Name', 'Ranking'];
    crewtypes: CrewType[];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, private router: Router, private messageService: MessageService) { }

    ngOnInit() {
        this.unitOfWork.crewTypeRepo.all(true).subscribe(cachedTypes => {
            if (cachedTypes.length === 0 || cachedTypes.length === 1)
                this.unitOfWork.crewTypeRepo.all().subscribe(dbTypes => this.crewtypes = dbTypes);
            else
                this.crewtypes = cachedTypes;
        });
    }

    edit(crewType: CrewType) {
        this.router.navigate(['/sysadmin/crewtype', crewType.id]);
    }

    delete(crewType: CrewType) {
        let employeeObservable = this.unitOfWork.employeeRepo.where(new Predicate('crewTypeId', '==', crewType.id)).subscribe(
            results => {
                let dependentEmployees = _.orderBy(results, [e => e.lastName, e => e.firstName]);

                let okToDelete = dependentEmployees.length == 0;

                if (!okToDelete) {
                    let dependentEmployeesString = '';
                    if (dependentEmployees.length > 0) {
                        dependentEmployeesString = '<b>Employees</b><ul>'
                            + _.map(dependentEmployees, e => '<li>' + e.lastName + ', ' + e.firstName + '</li>').join('') + '</ul>';
                    }

                    let errorMessage = 'Error. Failed to delete crew type. The following employees reference this crew type:<br/>' + dependentEmployeesString;

                    this.messageService.displayMessage('Can not delete crew type', errorMessage);
                }
                else {
                    this.messageService.displayConfirm('Delete crew type?', 'Really delete crew type "' + crewType.name + '"?', (yesNo: boolean) => {
                        if (yesNo) {
                            crewType.entityAspect.entityState = EntityState.Deleted;
                            this.unitOfWork.commit().then(response => this.unitOfWork.crewTypeRepo.all(true).subscribe(crewTypes => this.crewtypes = crewTypes));
                        }
                    });
                }
            }
        );
    }

    new() {
        this.router.navigate(['/sysadmin/crewtype']);
    }
}