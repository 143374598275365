import { Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { ChangePasswordComponent } from './change-password.component';
import { AdminSidemenuComponent, AdminComponent, CrewGroupsComponent, CrewGroupComponent, CoursesComponent, CrewTypesComponent, VesselsComponent, CourseComponent, VesselComponent, CrewTypeComponent, CourseResultsComponent, CourseResultComponent, UsersComponent, UserComponent, SalaryDetailsComponent } from './system/main';
import { CrewingComponent } from './crewing/main';
import { PrepareGuard } from './data/guards';
import { ScheduleComponent } from './schedule/main';
import { AuthGuard } from './core/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
const ɵ0 = { roles: ['CrewWrite', 'CrewRead'] }, ɵ1 = { roles: ['CrewWrite', 'CrewRead'] }, ɵ2 = { roles: ['SchedulesPlan', 'SchedulesRead'] }, ɵ3 = { roles: ['CrewRead', 'SchedulesRead'] }, ɵ4 = { roles: ['System'] };
const routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'crew', data: ɵ0, canActivate: [PrepareGuard, AuthGuard], component: CrewingComponent },
    { path: 'crew/:id', data: ɵ1, canActivate: [PrepareGuard, AuthGuard], component: CrewingComponent },
    { path: 'schedule', data: ɵ2, canActivate: [PrepareGuard, AuthGuard], component: ScheduleComponent },
    { path: 'changepassword', canActivate: [PrepareGuard], component: ChangePasswordComponent },
    //{ path: 'register', component: RegisterComponent },
    { path: 'home', data: ɵ3, canActivate: [PrepareGuard, AuthGuard], component: DashboardComponent },
    {
        path: 'sysadmin',
        component: AdminComponent,
        data: ɵ4,
        canActivate: [PrepareGuard, AuthGuard],
        children: [
            { path: 'users', component: UsersComponent },
            { path: 'user/:id', component: UserComponent },
            { path: 'user', component: UserComponent },
            { path: 'courseresults', component: CourseResultsComponent },
            { path: 'courseresult', component: CourseResultComponent },
            { path: 'courseresult/:id', component: CourseResultComponent },
            { path: 'crewtypes', component: CrewTypesComponent },
            { path: 'crewgroups', component: CrewGroupsComponent },
            { path: 'vessels', component: VesselsComponent },
            { path: 'vessel', component: VesselComponent },
            { path: 'vessel/:id', component: VesselComponent },
            { path: 'courses', component: CoursesComponent },
            { path: 'crewtype/:id', component: CrewTypeComponent },
            { path: 'crewtype', component: CrewTypeComponent },
            { path: 'crewgroup/:id', component: CrewGroupComponent },
            { path: 'crewgroup', component: CrewGroupComponent },
            { path: 'course/:id', component: CourseComponent },
            { path: 'course', component: CourseComponent },
            { path: 'salarydetails', component: SalaryDetailsComponent },
            { path: '', component: AdminSidemenuComponent, outlet: 'sidemenu' }
        ]
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
