import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CourseResult } from '../core/entities/entity-model';
import { MessageService } from '../core/message.service';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';

@Component({
        selector: 'courseresult',
    templateUrl: 'course-result.component.html'
})
export class CourseResultComponent implements OnInit {
    result: CourseResult = new CourseResult();
    courseResultForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private unitOfWork: VaderoTankUnitOfWorkService
        , private route: ActivatedRoute, private router: Router
        , private messageService: MessageService) {
        this.courseResultForm = this.formBuilder.group({
            'code': [null, Validators.required],
            'text': [null, Validators.required],
            'color': [null, Validators.required]
        });
    }

    ngOnInit() {
        this.route.params
            .subscribe(params => {
                if (params['id']) {
                    this.unitOfWork.courseResultRepo.withId(params['id'])
                        .subscribe(result => this.result = result);
                }
                else {
                    this.result = this.unitOfWork.createCourseResultFactory().create();
                }
            });
    }

    saveChanges() {
        this.unitOfWork.commit().catch(
            error => {
                this.messageService.displayMessage('Error', 'Error saving course result');
            }
        ).then(response => {
                this.router.navigate(['/sysadmin/courseresults']);
            });
    }

    cancel() {
        this.unitOfWork.rollback();
        this.router.navigate(['/sysadmin/courseresults']);
    }
}
