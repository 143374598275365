﻿<div class="navbar navbar-inverse">
    <div class="container">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" routerLink="/home">Väderö Crewing</a>
        </div>
        <div class="navbar-collapse collapse">
            <ul class="nav navbar-nav">
                <li *ngIf="!authorized" routerLinkActive="active">
                    <a routerLink="/login">Log in</a>
                </li>
                <li *ngIf="authorized" routerLinkActive="active">
                    <a routerLink="/changepassword">Change password</a>
                </li>
                <li *ngIf="authorized && hasRole('System')" ngbDropdown class="dropdown">
                    <a ngbDropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        System functions
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu" id="system-menu-dropdown">
                        <li routerLinkActive="active">
                            <a routerLink="sysadmin/users">User administration</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="sysadmin/crewtypes">Crew types</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="sysadmin/crewgroups">Crew groups</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="sysadmin/vessels">Vessels</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="sysadmin/courses">Training plans</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="sysadmin/courseresults">Training plans result codes</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="sysadmin/salarydetails">Salary details</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="authorized && (hasRole('SchedulesPlan') || hasRole('SchedulesRead'))" routerLinkActive="active">
                    <a routerLink="schedule">Schedule</a>
                </li>
                <li *ngIf="authorized && (hasRole('CrewRead') || hasRole('CrewWrite'))" routerLinkActive="active">
                    <a routerLink="crew">Crew</a>
                </li>
                <li *ngIf="authorized">
                    <a routerLink="logout">Logout</a>
                </li>
            </ul>
        </div>
    </div>
</div>