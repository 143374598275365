﻿import { UnitOfWork, IRepository, EntityManagerProvider, EntityFactory } from '../data/common';
import { Injectable } from '@angular/core';
import { Course, CourseFile, CourseResult, CrewGroup, CrewType, Employee, EmployeeFile, File, Schedule, TrainingPlan, Turn, User, Vessel } from '../core/entities/entity-model';
import * as breeze from 'breeze-client';
import * as moment from 'moment';

@Injectable()
export class VaderoTankUnitOfWorkService extends UnitOfWork {
    courseRepo: IRepository<Course>;
    courseFileRepo: IRepository<CourseFile>;
    courseResultRepo: IRepository<CourseResult>;
    crewGroupRepo: IRepository<CrewGroup>;
    crewTypeRepo: IRepository<CrewType>;
    employeeRepo: IRepository<Employee>;
    employeeFileRepo: IRepository<EmployeeFile>;
    fileRepo: IRepository<File>;
    scheduleRepo: IRepository<Schedule>;
    trainingPlanRepo: IRepository<TrainingPlan>;
    turnRepo: IRepository<Turn>;
    userRepo: IRepository<User>;
    vesselRepo: IRepository<Vessel>;

    private courseFactory: EntityFactory<Course>;
    private courseFileFactory: EntityFactory<CourseFile>;
    private courseResultFactory: EntityFactory<CourseResult>;
    private crewGroupFactory: EntityFactory<CrewGroup>;
    private crewTypeFactory: EntityFactory<CrewType>;
    private employeeFactory: EntityFactory<Employee>;
    private employeeFileFactory: EntityFactory<EmployeeFile>;
    private fileFactory: EntityFactory<File>;
    private scheduleFactory: EntityFactory<Schedule>;
    private trainingPlanFactory: EntityFactory<TrainingPlan>;
    private turnFactory: EntityFactory<Turn>;
    private userFactory: EntityFactory<User>;
    private vesselFactory: EntityFactory<Vessel>;

    constructor(emProvider: EntityManagerProvider) {
        super(emProvider);
        this.courseRepo = this.createRepository<Course>('Course', 'Courses', false);
        this.courseFileRepo = this.createRepository<CourseFile>('CourseFile', 'CourseFiles', false);
        this.courseResultRepo = this.createRepository<CourseResult>('CourseResult', 'CourseResults', false);
        this.crewGroupRepo = this.createRepository<CrewGroup>('CrewGroup', 'CrewGroups', false);
        this.crewTypeRepo = this.createRepository<CrewType>('CrewType', 'CrewTypes', false);
        this.employeeRepo = this.createRepository<Employee>('Employee', 'Employees', false);
        this.employeeFileRepo = this.createRepository<EmployeeFile>('EmployeeFile', 'EmployeeFiles', false);
        this.fileRepo = this.createRepository<File>('File', 'Files', false);
        this.scheduleRepo = this.createRepository<Schedule>('Schedule', 'Schedules', false);
        this.trainingPlanRepo = this.createRepository<TrainingPlan>('TrainingPlan', 'TrainingPlans', false);
        this.turnRepo = this.createRepository<Turn>('Turn', 'Turns', false);
        this.userRepo = this.createRepository<User>('User', 'Users', false);
        this.vesselRepo = this.createRepository<Vessel>('Vessel', 'Vessels', false);
    }

    createCourseFactory(): EntityFactory<Course> {
        if (!this.courseFactory)
            this.courseFactory = super.createFactory<Course>(Course);
        return this.courseFactory;
    }

    createCourseFileFactory(): EntityFactory<CourseFile> {
        if (!this.courseFileFactory)
            this.courseFileFactory = super.createFactory<CourseFile>(CourseFile);
        return this.courseFileFactory;
    }

    createCourseResultFactory(): EntityFactory<CourseResult> {
        if (!this.courseResultFactory)
            this.courseResultFactory = super.createFactory<CourseResult>(CourseResult);
        return this.courseResultFactory;
    }

    createCrewGroupFactory(): EntityFactory<CrewGroup> {
        if (!this.crewGroupFactory)
            this.crewGroupFactory = super.createFactory<CrewGroup>(CrewGroup);
        return this.crewGroupFactory;
    }

    createCrewTypeFactory(): EntityFactory<CrewType> {
        if (!this.crewTypeFactory)
            this.crewTypeFactory = super.createFactory<CrewType>(CrewType);
        return this.crewTypeFactory;
    }

    createEmployeeFactory(): EntityFactory<Employee> {
        if (!this.employeeFactory)
            this.employeeFactory = super.createFactory<Employee>(Employee);
        return this.employeeFactory;
    }

    createEmployeeFileFactory(): EntityFactory<EmployeeFile> {
        if (!this.employeeFileFactory)
            this.employeeFileFactory = super.createFactory<EmployeeFile>(EmployeeFile);
        return this.employeeFileFactory;
    }

    createFileFactory(): EntityFactory<File> {
        if (!this.fileFactory)
            this.fileFactory = super.createFactory<File>(File);
        return this.fileFactory;
    }

    createScheduleFactory(): EntityFactory<Schedule> {
        if (!this.scheduleFactory)
            this.scheduleFactory = super.createFactory<Schedule>(Schedule);
        return this.scheduleFactory;
    }

    createTrainingPlanFactory(): EntityFactory<TrainingPlan> {
        if (!this.trainingPlanFactory)
            this.trainingPlanFactory = super.createFactory<TrainingPlan>(TrainingPlan);
        return this.trainingPlanFactory;
    }

    createTurnFactory(): EntityFactory<Turn> {
        if (!this.turnFactory)
            this.turnFactory = super.createFactory<Turn>(Turn);
        return this.turnFactory;
    }

    createUserFactory(): EntityFactory<User> {
        if (!this.userFactory)
            this.userFactory = super.createFactory<User>(User);
        return this.userFactory;
    }

    createVesselFactory(): EntityFactory<Vessel> {
        if (!this.vesselFactory)
            this.vesselFactory = super.createFactory<Vessel>(Vessel);
        return this.vesselFactory;
    }
}