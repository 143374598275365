/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./crewgroup.component";
import * as i4 from "../core/vadero-tank-unit-of-work.service";
import * as i5 from "@angular/router";
import * as i6 from "../core/message.service";
var styles_CrewGroupComponent = [];
var RenderType_CrewGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CrewGroupComponent, data: {} });
export { RenderType_CrewGroupComponent as RenderType_CrewGroupComponent };
function View_CrewGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Name required!"]))], null, null); }
export function View_CrewGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Edit crew group"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 21, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.saveChanges() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i1.ɵangular_packages_forms_forms_bg, [], null, null), i0.ɵdid(5, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(7, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 12, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "label", [["for", "NameTB"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Name"])), (_l()(), i0.ɵeld(11, 0, null, null, 7, "input", [["class", "form-control"], ["id", "NameTB"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 14)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 14)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.crewGroup.name = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(13, { "invalid-control": 0 }), i0.ɵdid(14, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(16, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_j]], { form: [0, "form"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(18, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CrewGroupComponent_1)), i0.ɵdid(20, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵeld(23, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.crewGroupForm; _ck(_v, 5, 0, currVal_7); var currVal_15 = "form-control"; var currVal_16 = _ck(_v, 13, 0, (!_co.crewGroupForm.controls["name"].valid && _co.crewGroupForm.controls["name"].touched)); _ck(_v, 12, 0, currVal_15, currVal_16); var currVal_17 = _co.crewGroupForm.controls["name"]; var currVal_18 = _co.crewGroup.name; _ck(_v, 16, 0, currVal_17, currVal_18); var currVal_19 = !_co.crewGroupForm.controls["name"].valid; _ck(_v, 20, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 7).ngClassValid; var currVal_5 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i0.ɵnov(_v, 18).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 18).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 18).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 18).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 18).ngClassValid; var currVal_13 = i0.ɵnov(_v, 18).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 18).ngClassPending; _ck(_v, 11, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_20 = !_co.crewGroupForm.valid; _ck(_v, 21, 0, currVal_20); }); }
export function View_CrewGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "crewgroup", [], null, null, null, View_CrewGroupComponent_0, RenderType_CrewGroupComponent)), i0.ɵdid(1, 114688, null, 0, i3.CrewGroupComponent, [i1.FormBuilder, i4.VaderoTankUnitOfWorkService, i5.ActivatedRoute, i5.Router, i6.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CrewGroupComponentNgFactory = i0.ɵccf("crewgroup", i3.CrewGroupComponent, View_CrewGroupComponent_Host_0, {}, {}, []);
export { CrewGroupComponentNgFactory as CrewGroupComponentNgFactory };
