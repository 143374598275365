﻿import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { EntityState } from 'breeze-client';
import * as moment from 'moment';
import * as _ from 'lodash';

import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { Turn, Vessel, CrewType, Employee } from '../core/entities/entity-model';
import { TurnDocumentFlag } from '../core/entities/enums.model';

@Component({
        selector: 'vt-turn-edit',
    templateUrl: 'turn.component.html'
})
export class TurnComponent implements OnInit, OnChanges {
    @Input() turn: Turn;
    @Output() save: EventEmitter<Turn> = new EventEmitter<Turn>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Input() crewTypes: CrewType[];
    @Input() employee: Employee;
    @Input() employees: Employee[];
    @Input() vessel: Vessel;
    @Input() vessels: Vessel[];
    @Input() turns: Turn[];
    datePickerConfig: {} = {};
    turnForm: FormGroup;
    availableEmployees: Employee[] = [];
    turnsToRelieve: Turn[] = [];
    crewmanNotAvailable: boolean = false;
    employeeSelectList: any[] = [];

    get shouldSelectEmployee(): boolean {
        return !this.employee;
    }
    get shouldSelectVessel(): boolean {
        return !this.vessel;
    }

    constructor(formBuilder: FormBuilder, private unitOfWork: VaderoTankUnitOfWorkService) {
        this.turnForm = formBuilder.group({
            'crewType': [null, Validators.required],
            'employee': [
                this.shouldSelectEmployee ? null : { value: this.employee.id, disabled: true },
                this.shouldSelectEmployee ? Validators.required : []
            ],
            'vessel': [
                this.shouldSelectVessel ? null : { value: this.vessel.id, disabled: true },
                this.shouldSelectVessel ? Validators.required : []
            ],
            'signOn': [null],
            'signOff': [null],
            'relievesTurn': [null],
            'cOE': [null],
            'visa': [null],
            'lOG': [null]
        });
    }

    ngOnInit() {
        this.updateAvailableEmployees();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['turn'])
            this.updateAvailableEmployees();

        if (changes['turn'] && this.turn) {
            if (!!this.turn.employee) {
                this.turnForm.controls['employee'].disable();
            } else {
                this.turnForm.controls['employee'].enable();
            }

            // Makes None get pre-selected in the dropdown
            if (this.turn.relievesTurnId === '00000000-0000-0000-0000-000000000000') {
                this.turn.relievesTurnId = '';
            }

            // if (this.employees) {
            //    setTimeout(() => {
            //        var select = (jQuery('.select2') as any);
            //        select.select2();
            //    }, 0);
            // }
        }
    }

    getDateString(date: Date) {
        let dateToUse = moment(date).startOf('day') || moment().startOf('day');

        return dateToUse.format('YYYY-MM-DD');
    }

    statusForDropdown(e: Employee): string {
        var status = e.active == 'InActive' ? ', Inactive' : '';
        return ' (' + e.employeeNr + status + ')';
    }

    updateAvailableEmployees(): void {
        if (!this.turn)
            return;

        this.availableEmployees = _.filter(this.employees, e =>
            e.id === this.turn.employeeId || (e.active === 'Active' && !e.turns.some(t =>
                moment(t.signOn).startOf('day').isSameOrBefore(moment(this.turn.signOff).startOf('day')) &&
                moment(t.signOff).startOf('day').isSameOrAfter(moment(this.turn.signOn).startOf('day')))));

        this.turnsToRelieve = _.filter(this.turns, t => {
            return t.id !== this.turn.id &&
                t.crewTypeId === this.turn.crewTypeId &&
                moment(t.signOff).startOf('day').isBetween(
                    moment(this.turn.signOn).subtract(1, 'days'),
                    moment(this.turn.signOn).add(1, 'days'),
                    'day', '[]');
        });

        this.employeeSelectList = _.map(this.availableEmployees, e => {
            return { id: e.id, text: `${e.displayName} ${this.statusForDropdown(e)}` };
        });
    }

    updateCrewType(event: any, newCrewType: string) {
        this.updateAvailableEmployees();
    }

    updateDate(date: any, target: string) {
        this.turn[target] = date.jsdate;
        this.updateAvailableEmployees();

        if (this.turn.employee) {
            this.crewmanNotAvailable = this.turn.employee.turns.some(t => t.id != this.turn.id &&
                moment(t.signOn).startOf('day').isSameOrBefore(moment(this.turn.signOff).startOf('day')) &&
                moment(t.signOff).startOf('day').isSameOrAfter(moment(this.turn.signOn).startOf('day')))
        }
    }

    preselectCrewType(event: any) {
        this.turnForm.patchValue({
            employee: event.id
        });
        this.turn.employeeId = event.id;

        if (this.turn && this.turn.employee) {
            this.turn.crewTypeId = this.turn.employee.crewTypeId;
            // FIXME: this function now overrides the selected crewType no matter if the user selected a crewtype manually
            //if (this.turn.crewTypeId == null || this.turn.crewTypeId == "" || this.turn.crewTypeId == "00000000-0000-0000-0000-000000000000")
        }
    }

    saveFunc() {
        if (this.turn.entityAspect.entityState === EntityState.Unchanged)
            this.turn.entityAspect.entityState = EntityState.Modified;

        if (this.turn.relievesTurnId == '' || !_.includes(this.turnsToRelieve, this.turn.relievesTurn)) {
            this.turn.relievesTurn = null;
            this.turn.relievesTurnId = null;
        }

        /* EWW!
         * Breeze thinks Employee has changed, and wants to save it as well.
         * This fails randomly, as the Employee might be invalid from the database.
         * As we don't have any UI to edit the employee when we save this turn,
         * it doesn't make any sense.
         *
         * Trick Breeze into ignoring the "changes" to Employee
         */
        if (this.turn.employee)
            this.turn.employee.entityAspect.setUnchanged();
        /* /EWW */

        this.unitOfWork.commit().then(x => {
            this.save.emit();
        });
    }

    cancelFunc() {
        this.unitOfWork.rollback();
        this.cancel.emit();
    }

    get invalidDates(): boolean {
        return !this.turn || moment(this.turn.signOff).startOf('day').isSameOrBefore(moment(this.turn.signOn).startOf('day'));
    }
}
