import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BusyService {
    private busyCounter: number = 0;

    get isBusy(): boolean {
        return this.busyCounter > 0;
    }

    busy<T>(task: Observable<T> | Promise<T>): Observable<T> | Promise<T> {
        if (task instanceof Observable) {
            ++this.busyCounter;
            task.subscribe(x => {
                return --this.busyCounter;
            }, x => {
                return --this.busyCounter;
            });
        }

        if (task instanceof Promise) {
            ++this.busyCounter;
            task.then(x => --this.busyCounter, x => --this.busyCounter);
        }

        return task;
    }
}