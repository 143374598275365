import { Injectable } from "@angular/core";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { IAuthData } from "src/app/auth.interface";
import { LocalStorageService } from "angular-2-local-storage";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  public constructor(private localStorageService: LocalStorageService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authData: IAuthData = <IAuthData>this.localStorageService.get('authorizationData');
    if (authData)
      request = this.addToken(request, authData.token);

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.router.navigate(['/logout']);
    return throwError(request);
  }
}
