
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Predicate } from 'breeze-client';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Turn, Vessel, Employee } from '../core/entities/entity-model';
import { MessageService } from '../core/message.service';
import { BusyService } from '../core/busy.service';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { SalaryDetailCalculatorService } from './salary-detail-calculator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'salary-details',
  templateUrl: 'salary-details.component.html',
  styleUrls: ['salary-details.component.css']
})
export class SalaryDetailsComponent implements OnInit {
  @Input()
  selectedDate: moment.Moment = moment().startOf('day');
  datePickerConfig: {} = {
    format: "yyyy-mm",
    startView: "months",
    minViewMode: "months"
  };
  turns: Turn[];
  vessels: Vessel[];
  employees: Employee[];
  initialQueryExecuted: boolean = false;
  exportIsDisabled: boolean = true;
  turnsGroupedByVessel: {};
  turnsGroupedByVesselKeys: string[];
  private apiBase: string;

  constructor(private unitOfWork: VaderoTankUnitOfWorkService
    , private busyService: BusyService
    , private route: ActivatedRoute, private router: Router
    , private messageService: MessageService
    , private salaryDetailCalculatorService: SalaryDetailCalculatorService
  ) {
    this.apiBase = environment.apiBase;
  }

  ngOnInit() {
  }

  getSelectedDate(): string {
    return this.selectedDate.format('YYYY-MM-DD');
  }

  updateDate(date: any) {
    if (date)
      this.selectedDate = moment(date.formatted).startOf('day');
    else
      this.selectedDate = moment(moment.now()).startOf('day');

    this.updateSalaryDetails();
  }

  updateSalaryDetails() {
    this.initialQueryExecuted = true;

    var start: moment.Moment = moment(this.selectedDate).startOf('month');
    var end: moment.Moment = moment(this.selectedDate).endOf('month');

    var signOn = Predicate.create("signOn", ">=", start).and("signOn", "<=", end);
    var signOff = Predicate.create("signOff", "<=", end).and("signOff", ">=", start);
    var signOnOff = signOn.or(signOff);

    // FIXME: Sort employees on vessel by name

    (this.busyService.busy(this.unitOfWork.turnRepo.where(signOnOff)) as Observable<Turn[]>).subscribe(turn => {
      this.turns = turn;

      if (!_.isEmpty(turn)) {
        let employeeObs = this.unitOfWork.employeeRepo.where(Predicate.create("id", "In", _.uniq(_.map(turn, t => t.employeeId))));
        let vesselObs = this.unitOfWork.vesselRepo.where(Predicate.create("id", "In", _.uniq(_.map(turn, t => t.vesselId))));

        observableForkJoin(employeeObs, vesselObs).subscribe(res => {
          this.employees = res[0];
          this.vessels = res[1];

          // Should know both employees and vessels by now

          this.turnsGroupedByVessel = _.groupBy(_.sortBy(turn, t => t.vessel.name.toLowerCase()), t => t.vesselId);
          this.turnsGroupedByVesselKeys = _.keys(this.turnsGroupedByVessel);


          _.forIn(this.turnsGroupedByVessel, (turns, index) => {
            this.turnsGroupedByVessel[index] = _.groupBy(turns, (i: Turn) => i.employeeId)
          });
        });
        this.exportIsDisabled = false;
      } else {
        this.exportIsDisabled = true;
        this.employees = [];
        this.vessels = [];
        this.turnsGroupedByVessel = {};
        this.turnsGroupedByVesselKeys = [];
      }
    });
  }

  vesselNameChanged(turn: Turn, prevTurn: Turn): boolean {
    if (turn && prevTurn)
      return turn.vesselId != prevTurn.vesselId;

    return true;
  }

  vesselName(vesselId: string): string {
    return _.find(this.vessels, v => v.id == vesselId).name;
  }

  displayNameForEmployee(vesselId, employeeId): string {
    return this.orderedTurnsForEmployee(vesselId, employeeId)[0].employee.displayName;
  }

  orderedEmployeesForVessel(vesselId: string): string[] {
    return _.sortBy(_.keys(this.turnsGroupedByVessel[vesselId]), t =>
      _.find(this.employees, e => e.id == t).displayName.toLowerCase()
    );
  }

  orderedTurnsForEmployee(vesselId: string, employeeId: string): Turn[] {
    return _.sortBy(this.turnsGroupedByVessel[vesselId][employeeId], (t: Turn) => t.signOn);
  }

  daysOnboard(vesselId: string, employeeId: string): string {
    var turns = this.orderedTurnsForEmployee(vesselId, employeeId);
    return this.salaryDetailCalculatorService.daysOnboard(this.selectedDate.toDate(), turns);
  }

  daysAtHome(vesselId: string, employeeId: string): string {
    var turns = this.orderedTurnsForEmployee(vesselId, employeeId);
    return this.salaryDetailCalculatorService.daysAtHome(this.selectedDate.toDate(), turns);
  }

  exportSalaryDetailsLink(): string {
    return this.apiBase + 'api/SalaryDetails/Export/' + this.selectedDate.format('YYYY-MM');
  }
}
