/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dashboard-documents.component.ngfactory";
import * as i2 from "./dashboard-documents.component";
import * as i3 from "../core/vadero-tank-unit-of-work.service";
import * as i4 from "@angular/forms";
import * as i5 from "../core/message.service";
import * as i6 from "@angular/router";
import * as i7 from "../core/busy.service";
import * as i8 from "../core/auth.service";
import * as i9 from "./dashboard-upcoming-relieves.component.ngfactory";
import * as i10 from "./dashboard-upcoming-relieves.component";
import * as i11 from "./dashboard.component";
var styles_DashboardComponent = [];
var RenderType_DashboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
export function View_DashboardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dashboard-documents", [], null, null, null, i1.View_DashboardDocumentsComponent_0, i1.RenderType_DashboardDocumentsComponent)), i0.ɵdid(3, 4308992, null, 0, i2.DashboardDocumentsComponent, [i3.VaderoTankUnitOfWorkService, i4.FormBuilder, i5.MessageService, i6.ActivatedRoute, i6.Router, i7.BusyService, i8.AuthService], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "dashboard-upcoming-relieves", [], null, null, null, i9.View_DashboardUpcomingRelievesComponent_0, i9.RenderType_DashboardUpcomingRelievesComponent)), i0.ɵdid(6, 114688, null, 0, i10.DashboardUpcomingRelievesComponent, [i3.VaderoTankUnitOfWorkService, i4.FormBuilder, i5.MessageService, i6.ActivatedRoute, i6.Router, i7.BusyService, i8.AuthService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vadero-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i0.ɵdid(1, 114688, null, 0, i11.DashboardComponent, [i3.VaderoTankUnitOfWorkService, i7.BusyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i0.ɵccf("vadero-dashboard", i11.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
