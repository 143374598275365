﻿<div class="container">
    <div class="row">
        <div class="col-md-3">
            <h3>Upload files</h3>
            <div ng2FileDrop
                 [ngClass]="{ 'vt-file-over': hasDropZoneOver }"
                 (fileOver)="fileOver($event)"
                 [uploader]="uploader"
                 class="vt-file-drop-zone">
                Drop file here
            </div>

            <p>Or select files here:</p>
            <input type="file" ng2FileSelect [uploader]="uploader" multiple />
        </div>
        <div class="col-md-9">
            <h3>Upload queue</h3>
            <p>Queue length: {{ uploader?.queue?.length }}</p>
            <table class="table">
                <thead>
                    <tr>
                        <th width="50%">
                            Name
                        </th>
                        <th *ngIf="uploader.options.isHTML5">
                            Size
                        </th>
                        <th *ngIf="uploader.options.isHTML5">
                            Progress
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>{{ item?.file?.name }}</strong></td>
                        <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                        <td *ngIf="uploader.options.isHTML5">
                            <div class="progress" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                            </div>
                        </td>
                        <td class="text-center">
                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                        </td>
                        <td nowrap>
                            <button type="button" class="btn btn-success btn-xs" (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <span class="glyphicon glyphicon-upload"></span>
                            </button>
                            <button type="button" class="btn btn-warning btn-xs" (click)="item.cancel()" [disabled]="!item.isUploading">
                                <span class="glyphicon glyphicon-ban-circle"></span>
                            </button>
                            <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                                <span class="glyphicon glyphicon-trash"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                Queue progress:
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                </div>
            </div>
            <button type="button" class="btn btn-success btn-s" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                <span class="glyphicon glyphicon-upload"></span>&nbsp;Upload all
            </button>
            <button type="button" class="btn btn-warning btn-s" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                <span class="glyphicon glyphicon-ban-circle"></span>&nbsp;Cancel all
            </button>
            <button type="button" class="btn btn-danger btn-s" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                <span class="glyphicon glyphicon-trash"></span>&nbsp; Remove all
            </button>
        </div>
    </div>
</div>