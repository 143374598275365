import { Component, OnInit, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Course, CrewGroup, File, CourseFile } from '../core/entities/entity-model';
import { MessageService } from '../core/message.service';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'course',
  templateUrl: 'course.component.html'
})
export class CourseComponent implements OnInit {
  course: Course = new Course();
  courseForm: FormGroup;
  crewGroups: CrewGroup[];
  saveFileUrl: string;
  private apiBase: string;

  constructor(private formBuilder: FormBuilder, private unitOfWork: VaderoTankUnitOfWorkService
    , private route: ActivatedRoute, private router: Router
    , private messageService: MessageService) {
    this.apiBase = environment.apiBase;
    this.courseForm = this.formBuilder.group({
      'code': [null, Validators.required],
      'name': [null, Validators.required],
      'type': [null, Validators.required],
      'crewGroup': [null, Validators.required]
    });

    this.saveFileUrl = this.apiBase + '/api/File/Course/';
  }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        if (params['id']) {
          this.unitOfWork.courseRepo.withId(params['id'])
            .subscribe(course => {
              this.course = course;
              this.saveFileUrl = this.apiBase + '/api/File/Course/' + this.course.id;
            });
        }
        else {
          this.course = this.unitOfWork.createCourseFactory().create();
          this.course.type = "Mandatory";
        }

        this.unitOfWork.crewGroupRepo.all().subscribe(groups => this.crewGroups = groups);
        this.unitOfWork.courseFileRepo.all();
        this.unitOfWork.fileRepo.all();
      });
  }

  updateFiles() {
    this.unitOfWork.courseFileRepo.all();
    this.unitOfWork.fileRepo.all();
  }

  isInvalid(control: string): boolean {
    return !this.courseForm.controls[control].valid && this.courseForm.controls[control].touched;
  }

  removeFile(file: CourseFile) {
    this.messageService.displayConfirm('Delete file?', 'Really delete file ' + file.file.fileName + '?', (yesNo: boolean) => {
      if (yesNo) {
        file.file.entityAspect.setDeleted();
        file.entityAspect.setDeleted();
      }
    });
  }

  saveChanges() {
    this.unitOfWork.commit().catch(
      error => {
        this.messageService.displayMessage('Error', 'Error saving course');
      }
    ).then(response => {
      this.router.navigate(['/sysadmin/courses']);
    });
  }

  cancel() {
    this.unitOfWork.rollback();
    this.router.navigate(['/sysadmin/courses']);
  }
}
