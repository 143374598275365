﻿import { core } from 'breeze-client';
import { EntityBase, CourseType, CrewGroup, CourseFile } from './entity-model';

export class Course extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: Course) {}

    code: string;
    crewGroupId: string;
    id: string;
    name: string;
    type: string;
    crewGroup: CrewGroup
    courseFiles: CourseFile[];
}