﻿import { EntityBase, Employee, Schedule, Vessel, CrewType } from './entity-model';
import { TurnDocumentFlag } from './enums.model';
import { core } from 'breeze-client';
import * as moment from 'moment';

export class Turn extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: Turn) { }

    crewTypeId: string;
    employeeId: string;
    relievesTurnId: string;
    id: string;
    scheduleId: string;
    signOff: Date;
    signOn: Date;
    vesselId: string;
    employee: Employee;
    relievesTurn: Turn;
    schedule: Schedule;
    vessel: Vessel;
    crewType: CrewType;
    status: string;
    cOE: boolean;
    visa: string;
    lOG: string;

    allChecksOk(employee: Employee, signOff: Date): boolean {
        if (!this.coeCheckOk(employee, signOff)
            || !this.healthCheckOk(employee, signOff)
            || !this.visaCheckOk(this)
            || !this.logCheckOk(this))
            return false;

        if (this.turnRequires(this.crewType, 'endorsementCOC') && !this.cocCheckOk(employee, signOff))
            return false;

        if (this.turnRequires(this.crewType, 'endorsementTankOil') && !this.tankOilCheckOk(employee, signOff))
            return false;

        if (this.turnRequires(this.crewType, 'endorsementTankChem') && !this.tankChemCheckOk(employee, signOff))
            return false;

        if (this.turnRequires(this.crewType, 'endorsementGOC') && !this.gocCheckOk(employee, signOff))
            return false;

        return true;
    }

    coeCheckOk(employee: Employee, signOff: Date): boolean      { return this.cOE; }
    healthCheckOk(employee: Employee, signOff: Date): boolean   { return this.employeeDateCheck(employee, 'healthCertificationExpireDate', signOff); }
    cocCheckOk(employee: Employee, signOff: Date): boolean      { return this.employeeDateCheck(employee, 'endorsementExpirationDate', signOff); }
    tankOilCheckOk(employee: Employee, signOff: Date): boolean  { return this.employeeDateCheck(employee, 'tankerManOilExpirationDate', signOff); }
    tankChemCheckOk(employee: Employee, signOff: Date): boolean { return this.employeeDateCheck(employee, 'tankerManChemExpirationDate', signOff); }
    gocCheckOk(employee: Employee, signOff: Date): boolean      { return this.employeeDateCheck(employee, 'gOCExpirationDate', signOff); }
    visaCheckOk(turn: Turn): boolean                            { return this.visa != "" && this.visa != 'None'; }
    logCheckOk(turn: Turn): boolean                             { return this.lOG != "" && this.lOG != 'None'; }

    turnRequires(crewType: CrewType, check: string): boolean {
        if (!crewType)
            return false;

        switch (check) {
            case "endorsementCOC": return crewType.endorsementCOC;
            case "endorsementTankOil": return crewType.endorsementTankOil;
            case "endorsementTankChem": return crewType.endorsementTankChem;
            case "endorsementGOC": return crewType.endorsementGOC;
        }

        return false;
    }

    private employeeDateCheck(employee: Employee, attribute: string, signOff: Date): boolean {
        if (!employee || !signOff)
            return false;

        if (!employee[attribute])
            return false;

        return moment(signOff).startOf('day').isSameOrBefore(moment(employee[attribute]).startOf('day'));
    }
}