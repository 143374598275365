import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth.service';

interface LoadingScreen {
  finish: () => void;
}

interface WindowWithLoadingScreen extends Window {
  loading_screen: LoadingScreen;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.fillAuthData();
    let loading = (<WindowWithLoadingScreen><any>window).loading_screen;
    if (loading) loading.finish();
  }
}
