import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Turn } from '../core/entities/entity-model';

@Injectable()
export class SalaryDetailCalculatorService {
    daysOnboard(month: Date, turns: Turn[]): string {
        var ranges = _.map(turns,
            t => this.daysInRange(this.dateRangeForTurn(month, t))
        );
        return ranges.join(", ");
    }

    daysInRange(range: number[]): string {
        if (range.length == 1)
            return range[0] + ' (1)';

        var joined = [range[0], range[1]].join("-");
        var days = range[1] - range[0] + 1;
        var span = `(${days})`;
        return [joined, span].join(' ');
    }

    dateRangeForTurn(month: Date, turn: Turn): number[] {
        if (turn.signOn.getDate() == turn.signOff.getDate())
            return [parseInt(moment(turn.signOn).format("D"), 10)];

        var startDate = turn.signOn.getDate();
        var endDate = turn.signOff.getDate();

        if (turn.signOn.getMonth() < month.getMonth())
            startDate = 1;

        if (turn.signOff.getMonth() > month.getMonth() || turn.signOff.getFullYear() > month.getFullYear())
            endDate = moment(month).daysInMonth();

        return [startDate, endDate];
    }

    daysAtHome(month: Date, turns: Turn[]): string {
        var datesInMonth = [];
        for (var i = 1; i < moment(month).daysInMonth() + 1; i++) {
            datesInMonth.push(i);
        }

        var ranges = [];
        var lastDay = datesInMonth[datesInMonth.length - 1];
        var turnLeftMonth = false;

        // Full month off
        if (turns.length == 0)
            return this.daysInRange([1, lastDay]);

        // Full work month
        if (turns[0].signOn.getDate() == 1 && turns[0].signOff.getDate() == lastDay)
            return "";

        _.each(turns, (e: Turn) => {
            var lookingForStart = true;

            if (e.signOn.getDate() == 1
                || e.signOn.getMonth() != month.getMonth()) {
                // We're abord when we start!
                // Add signOff + 1 as the first day off, and look for the end
                ranges.push(e.signOff.getDate() + 1);
                lookingForStart = false;
            } else {
                // Our first day off is at the start
                if (ranges.length == 0) {
                    ranges.push(1);
                }
            }

            if (!lookingForStart && e.signOn.getMonth() != month.getMonth()) {
                turnLeftMonth = true;
            }
            else if (lookingForStart && e.signOff.getMonth() != month.getMonth()) {
                var range = e.signOn.getDate() - 1;
                if (turns.length >= 1 && ranges.indexOf(range) == -1)
                    ranges.push(range);

                turnLeftMonth = true;
            }
            else {
                _.each(datesInMonth, day => {
                    var d = lookingForStart ? e.signOn.getDate() : e.signOff.getDate();
                    if (day == d) {
                        if (lookingForStart) {
                            ranges.push(day - 1);
                            lookingForStart = false;
                        } else {
                            // Only add if we don't already have the day
                            if (day != lastDay && ranges.indexOf(day + 1) == -1) {
                                ranges.push(day + 1);
                            }
                            lookingForStart = true; // Found the end of another turn, reset
                        }
                    }
                });
            }
        });

        // We are likely running to the end of the month
        if (ranges.length % 2 == 1
            && !turnLeftMonth
            && ranges.indexOf(lastDay) == -1) {
            ranges.push(lastDay);
        }

        // eww…
        if (turns[turns.length - 1].signOff.getDate() != lastDay
            && ranges[ranges.length - 1] != lastDay
            && turns[turns.length - 1].signOff.getMonth() == month.getMonth()) {
            ranges.push(lastDay);
        }

        return _.map(_.chunk(ranges, 2), r => this.daysInRange(r)).join(", ");
    }
}