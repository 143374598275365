import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSpinKitModule } from 'ng-spin-kit';
import { BreezeBridgeHttpClientModule } from 'breeze-bridge2-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { MainMenuComponent } from './main-menu.component';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { SystemModule } from './system/system.module';
import { CrewingModule } from './crewing/main';
import { ScheduleModule } from './schedule/main';
import { LoadingComponent } from './loading.component';
import { VTErrorHandler } from './error-handler';
import { ChangePasswordComponent } from './change-password.component';
import { DashboardModule } from './dashboard/main';
import { LocalStorageModule } from 'angular-2-local-storage';

@NgModule({
  imports: [
    AppRoutingModule,
    BreezeBridgeHttpClientModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SystemModule,
    NgbModule.forRoot(),
    CrewingModule,
    ScheduleModule,
    DashboardModule,
    LocalStorageModule.forRoot({
      prefix: 'my-app',
      storageType: 'localStorage'
    }),
    NgSpinKitModule,
  ],
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    HomeComponent,
    LoadingComponent,
    LoginComponent,
    LogoutComponent,
    MainMenuComponent,
  ],
  providers: [
    { provide: ErrorHandler, useClass: VTErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
