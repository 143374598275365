﻿import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';
import { SelectModule } from 'ng2-select';

import { BasicInfoComponent } from './basic-info.component';
import { CrewingComponent } from './crewing.component';
import { DataModule } from '../data/data.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        MyDatePickerModule,
        NgbModule,
        NgbTabsetModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        SelectModule
    ],
    exports: [],
    declarations: [
        CrewingComponent,
        BasicInfoComponent
    ],
    providers: []
})
export class CrewingModule {}