/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./messagebox.component.ngfactory";
import * as i2 from "./messagebox.component";
import * as i3 from "./confirm.component.ngfactory";
import * as i4 from "./confirm.component";
import * as i5 from "./message-center.component";
import * as i6 from "../core/message.service";
var styles_MessageCenterComponent = [];
var RenderType_MessageCenterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageCenterComponent, data: {} });
export { RenderType_MessageCenterComponent as RenderType_MessageCenterComponent };
export function View_MessageCenterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "message-box", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.closeMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MessageBoxComponent_0, i1.RenderType_MessageBoxComponent)), i0.ɵdid(1, 49152, null, 0, i2.MessageBoxComponent, [], { show: [0, "show"], title: [1, "title"], message: [2, "message"] }, { closed: "closed" }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "confirm", [], null, [[null, "confirmed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirmed" === en)) {
        var pd_0 = (_co.confirm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ConfirmComponent_0, i3.RenderType_ConfirmComponent)), i0.ɵdid(3, 49152, null, 0, i4.ConfirmComponent, [], { show: [0, "show"], title: [1, "title"], message: [2, "message"] }, { confirmed: "confirmed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageService.show; var currVal_1 = _co.messageService.title; var currVal_2 = _co.messageService.message; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.messageService.showConfirm; var currVal_4 = _co.messageService.title; var currVal_5 = _co.messageService.message; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_MessageCenterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "message-center", [], null, null, null, View_MessageCenterComponent_0, RenderType_MessageCenterComponent)), i0.ɵdid(1, 49152, null, 0, i5.MessageCenterComponent, [i6.MessageService], null, null)], null, null); }
var MessageCenterComponentNgFactory = i0.ɵccf("message-center", i5.MessageCenterComponent, View_MessageCenterComponent_Host_0, {}, {}, []);
export { MessageCenterComponentNgFactory as MessageCenterComponentNgFactory };
