<div class="container body-content">
    <main-menu></main-menu>
    <div class="main-div">
        <router-outlet></router-outlet>
    </div>
    <vt-loading></vt-loading>
    <message-center></message-center>
    <hr />
    <footer>
        <p>&copy; Väderö Shipping v.1.0.8</p>
    </footer>
</div>
