﻿import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CrewType, Employee } from '../core/entities/entity-model';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
        selector: 'vt-search-crew',
    templateUrl: 'search-crew.component.html'
})
export class SearchCrewComponent implements OnChanges {
    @Input() show: boolean;
    @Output() done: EventEmitter<void> = new EventEmitter<void>();
    @Input() crewTypes: CrewType[];
    @Input() employees: Employee[];
    selectedDate: moment.Moment = moment();
    selectedCrewTypeId: string;
    selectedStatus: string;
    foundEmployees: Employee[];

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['show'] && this.show) {
            this.updateFoundEmployees();
        }
    }

    getDateString() {
        return moment(this.selectedDate).format('YYYY-MM-DD');
    }

    updateCrewType(crewTypeId:string) {
        this.selectedCrewTypeId = crewTypeId;
        this.updateFoundEmployees();
    }

    updateStatus(status: string) {
        this.selectedStatus = status;
        this.updateFoundEmployees();
    }

    updateFoundEmployees() {
        this.foundEmployees = _.orderBy(_.filter(this.employees, e =>
            e.turns.some(t =>
                this.selectedDate.isBetween(moment(t.signOn).startOf('day'), moment(t.signOff).startOf('day'), 'day', '[]'))
            && e.crewTypeId == this.selectedCrewTypeId
            && (this.selectedStatus === '*' || this.selectedStatus === e.status))
            , [e => e.lastName, e => e.firstName]);
    }

    updateDate(date: any) {
        this.selectedDate = moment(date.formatted).startOf('day');
        this.updateFoundEmployees();
    }

    doneFn() {
        this.done.emit();
    }
}
