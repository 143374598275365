/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal.component.ngfactory";
import * as i2 from "./modal.component";
import * as i3 from "./messagebox.component";
var styles_MessageBoxComponent = [];
var RenderType_MessageBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageBoxComponent, data: {} });
export { RenderType_MessageBoxComponent as RenderType_MessageBoxComponent };
export function View_MessageBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "modal-window", [], null, null, null, i1.View_ModalComponent_0, i1.RenderType_ModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalComponent, [], { modal: [0, "modal"], title: [1, "title"], show: [2, "show"] }, null), (_l()(), i0.ɵeld(2, 0, null, 1, 1, "div", [["class", "vt-modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, 2, 2, "div", [["class", "vt-modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Ok"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.title; var currVal_2 = _co.show; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.message; _ck(_v, 3, 0, currVal_3); }); }
export function View_MessageBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "message-box", [], null, null, null, View_MessageBoxComponent_0, RenderType_MessageBoxComponent)), i0.ɵdid(1, 49152, null, 0, i3.MessageBoxComponent, [], null, null)], null, null); }
var MessageBoxComponentNgFactory = i0.ɵccf("message-box", i3.MessageBoxComponent, View_MessageBoxComponent_Host_0, { show: "show", title: "title", message: "message" }, { closed: "closed" }, []);
export { MessageBoxComponentNgFactory as MessageBoxComponentNgFactory };
