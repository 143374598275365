import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CrewType, CrewGroup } from '../core/entities/entity-model';
import { MessageService } from '../core/message.service';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';

@Component({
        selector: 'crewtype',
    templateUrl: 'crewtype.component.html'
})
export class CrewTypeComponent implements OnInit {
    crewType: CrewType = new CrewType();
    crewTypeForm: FormGroup;
    crewGroups: CrewGroup[];

    constructor(private formBuilder: FormBuilder, private unitOfWork: VaderoTankUnitOfWorkService
        , private route: ActivatedRoute, private router: Router
        , private messageService: MessageService) {
        this.crewTypeForm = this.formBuilder.group({
            'name': [null, Validators.required],
            'ranking': [null, Validators.required],
            'group': [null, Validators.required],
            'endorsementCOC': [null],
            'endorsementTankOil': [null],
            'endorsementTankChem': [null],
            'endorsementGOC': [null],
        });
    }

    ngOnInit() {
        this.route.params
            .subscribe(params => {
                if (params['id']) {
                    this.unitOfWork.crewTypeRepo.withId(params['id'])
                        .subscribe(crewType => this.crewType = crewType);
                }
                else {
                    this.crewType = this.unitOfWork.createCrewTypeFactory().create();
                }

                this.unitOfWork.crewGroupRepo.all().subscribe(groups => this.crewGroups = groups);
            });
    }

    isInvalid(control: string): boolean {
        return !this.crewTypeForm.controls[control].valid && this.crewTypeForm.controls[control].touched;
    }

    saveChanges() {
        this.unitOfWork.commit().catch(
            error => {
                this.messageService.displayMessage('Error', 'Error saving crew type');
            }
        ).then(response => {
                this.router.navigate(['/sysadmin/crewtypes']);
            });
    }

    cancel() {
        this.unitOfWork.rollback();
        this.router.navigate(['/sysadmin/crewtypes']);
    }
}
