﻿<h3 class="page-header">Salary details</h3>

<div class="form-group">
    <label>Select a month to show:</label>
    <my-date-picker locale="sv" [selDate]="getSelectedDate" [options]="datePickerConfig" (dateChanged)="updateDate($event)"></my-date-picker>
</div>

<div *ngIf="initialQueryExecuted">
    <hr />

    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th colspan="3">{{selectedDate | date: "MMMM, yyyy"}}</th>
            </tr>
            <tr>
                <th></th>
                <th>Onboard</th>
                <th>Home</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let vesselId of turnsGroupedByVesselKeys">
                <tr class="vessel-name-changed">
                    <td><b>{{vesselName(vesselId)}}</b></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr *ngFor="let employeeId of orderedEmployeesForVessel(vesselId)">
                    <td>{{displayNameForEmployee(vesselId, employeeId)}}</td>
                    <td>{{daysOnboard(vesselId, employeeId)}}</td>
                    <td>{{daysAtHome(vesselId, employeeId)}}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>

    <a class="btn btn-primary" [href]="exportSalaryDetailsLink()" [class.disabled]="exportIsDisabled" target="_blank"><span style="color:white">Export salary details&nbsp;<span class="glyphicon glyphicon-floppy-save"></span></span></a>
</div>