
import { publish } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Predicate, EntityState } from 'breeze-client';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as jQuery from 'jquery';

import { Employee, CrewType, Turn, TrainingPlan, Course, CourseResult, File, Vessel } from '../core/entities/entity-model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';
import { BusyService } from '../core/busy.service';
import { AuthService } from '../core/auth.service';
import { environment } from 'src/environments/environment';

class SelectEmployee {
  id: string;
  text: string;

  constructor(id: string, text: string) {
    this.id = id;
    this.text = text;
  }
};

@Component({
  selector: 'crewing',
  templateUrl: 'crewing.component.html',
  styleUrls: ['crewing.component.css']
})
export class CrewingComponent implements OnInit, OnDestroy {
  employees: Employee[];
  selectEmployees: SelectEmployee[];
  employeeIds: SelectEmployee[];
  employee: Employee;
  crewTypes: CrewType[];
  files: File[];
  vessels: Vessel[];
  sortedTrainingPlans: TrainingPlan[];
  courseResults: CourseResult[];
  datePickerConfig: {} = {
    dateFormat: 'yyyy-mm-dd'
  };
  newTurn: Turn;
  newTurnDateError: boolean = false;
  newTrainingPlan: TrainingPlan = null;
  mandatoryCourses: Course[] = [];
  optionalCourses: Course[] = [];
  allTurns: Turn[];

  saveFileUrl: string;

  apiBase: string;
  fileApiBase: string;
  showNewTrainingDialog: boolean = false;
  editMode: boolean = false;
  invalidSubmit: boolean = false;

  includeInactiveEmployees: boolean = false;
  activeEmployees: Employee[] = [];

  //Forms
  newTurnForm: FormGroup;
  newTrainingForm: FormGroup;
  basicInfoForm: FormGroup;

  constructor(private unitOfWork: VaderoTankUnitOfWorkService, formBuilder: FormBuilder, private messageService: MessageService
    , private route: ActivatedRoute, private router: Router, private busyService: BusyService, private authService: AuthService) {
    this.apiBase = environment.apiBase;
    this.fileApiBase = this.apiBase + '/api/File/';
    this.saveFileUrl = this.apiBase + '/api/File/Employee/';
    this.newTurnForm = formBuilder.group({
      'crewType': [null, Validators.required],
      'vessel': [null, Validators.required],
      'cOE': [null],
      'visa': [null],
      'lOG': [null]
    });

    this.newTrainingForm = formBuilder.group({
      'type': ['Mandatory'],
      'course': [null, Validators.required],
      'result': [null, Validators.required]
    });

    this.basicInfoForm = formBuilder.group({
      'active': ['Active', Validators.required],
      'employeeNr': [null, Validators.required],
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'middleName': [null],
      'dateOfBirth': [null],
      'civilStatus': [null, Validators.required],
      'road': [null],
      'postalCode': [null],
      'city': [null],
      'country': [null],
      'email': [null],
      'crewType': [null, Validators.required],
      'placeOfBirth': [null],
      'nextOfKin': [null],
      'phoneHome': [null],
      'phoneMobile': [null],
      'country2': [null],
      'seaExperience': [null],
      'dateOfEmployment': [null]
    });
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  updateRoute(selection: SelectEmployee) {
    if (selection.id)
      this.router.navigate(['/crew', selection.id]);
    else
      this.router.navigate(['/crew']);
  }

  navigateToEmployee(employeeNumber: number) {
    this.updateRoute(new SelectEmployee(employeeNumber.toString(10), ""));
  }

  updateSelected(employeeNumber: number) {
    if (this.employee)
      this.dateifyEmployee(this.employee);

    this.employee = _.find(this.employees, emp => emp.employeeNr == employeeNumber);

    if (this.employee) {
      this.sortedTrainingPlans = _.sortBy(this.employee.trainingPlans, t => t.course.code);

      this.momentifyEmployee(this.employee);
      this.updateFileList();
      this.saveFileUrl = this.apiBase + '/api/File/Employee/' + this.employee.id;
    }
  }

  updateFileList() {
    let employeeFiles = this.unitOfWork.employeeFileRepo.whereInCache(new Predicate('employeeId', '==', this.employee.id));
    this.files = _.orderBy(employeeFiles.map(ef => ef.file), (f: File) => f.fileName);
  }

  updateFiles() {
    let promises: Promise<any>[] = [];
    promises.push(this.unitOfWork.employeeFileRepo.all().toPromise());
    promises.push(this.unitOfWork.fileRepo.all().toPromise());

    Promise.all(promises).then(x => {
      this.updateFileList();
    });
  }

  removeFile(file: File) {
    this.messageService.displayConfirm('Delete file?', 'Really delete ' + file.fileName + '?', (yesNo) => {
      if (yesNo) {
        let employeeFile = this.unitOfWork.employeeFileRepo.whereInCache(Predicate.and(new Predicate('fileId', '==', file.id), new Predicate('employeeId', '==', this.employee.id)))[0];
        employeeFile.entityAspect.entityState = EntityState.Deleted;
        file.entityAspect.entityState = EntityState.Deleted;
        this.updateFiles();
      }
    });
  }

  includeInactiveEmployeesChanged(): void {
    if (this.includeInactiveEmployees)
      this.activeEmployees = this.employees;
    else
      this.activeEmployees = _.filter(this.employees, e => e.active == 'Active');
  }

  momentify(date: Date): moment.Moment {
    if (date && !(date instanceof Date))
      return date;

    return date ? moment(date) : moment('0001-01-01');
  }

  dateify(momentToDateify: moment.Moment): Date {
    if (!moment.isMoment(momentToDateify))
      return momentToDateify;

    return momentToDateify && momentToDateify.year() > 1 ? momentToDateify.toDate() : null;
  }

  getSelectedId(): number {
    return this.employee ? this.employee.employeeNr : 0;
  }

  ngOnInit() {
    (this.busyService.busy(this.unitOfWork.vesselRepo.all()) as Observable<Vessel[]>).subscribe(vessels => this.vessels = _.sortBy(vessels, v => v.name));
    (this.busyService.busy(this.unitOfWork.crewTypeRepo.all()) as Observable<CrewType[]>).subscribe(types => this.crewTypes = _.sortBy(types, t => t.name));
    (this.busyService.busy(this.unitOfWork.turnRepo.all()) as Observable<Turn[]>).subscribe(turns => this.allTurns = _.sortBy(turns, t => t.signOn));
    (this.busyService.busy(this.unitOfWork.courseResultRepo.all()) as Observable<CourseResult[]>).subscribe(res => {
      this.courseResults = _.sortBy(res, r => r.code);
    });

    let promises: Promise<any>[] = [];
    promises.push((this.busyService.busy(this.unitOfWork.crewGroupRepo.all()) as Observable<any>).toPromise());
    promises.push((this.busyService.busy(this.unitOfWork.courseRepo.all()) as Observable<any>).toPromise());
    promises.push((this.busyService.busy(this.unitOfWork.fileRepo.all()) as Observable<any>).toPromise());
    promises.push((this.busyService.busy(this.unitOfWork.employeeFileRepo.all()) as Observable<any>).toPromise());
    promises.push((this.busyService.busy(this.unitOfWork.scheduleRepo.all()) as Observable<any>).toPromise());
    promises.push((this.busyService.busy(this.unitOfWork.trainingPlanRepo.all()) as Observable<any>).toPromise());

    let loadEmployees = this.busyService.busy(this.unitOfWork.employeeRepo.all()) as Observable<Employee[]>;

    Promise.all(promises).then(x => {
      loadEmployees.subscribe(emps => {
        this.employees = _.sortBy(emps, emp => emp.lastName.toLowerCase() + emp.firstName.toLowerCase());
        this.employeeIds = _.sortBy(_.map(emps, emp => new SelectEmployee(emp.employeeNr.toString(10), emp.employeeNr.toString(10) + emp.statusForDropdown)), e => parseInt(e.id, 10));
        this.selectEmployees = _.sortBy(_.map(emps, emp => new SelectEmployee(emp.employeeNr.toString(10), emp.displayName + emp.statusForDropdown)), e => e.text.toLowerCase());
        this.includeInactiveEmployeesChanged();

        let routeId = this.route.snapshot.params['id'];
        if (routeId && routeId !== '') {
          this.employee = this.employees.find(e => e.employeeNr === parseInt(routeId, 10));
          this.momentifyEmployee(this.employee);
        }
      });
    });

    this.route.params.subscribe(params => {
      this.updateSelected(parseInt(params['id']));
    });
  }

  ngOnDestroy() {
    if (this.employee) {
      this.employee.entityAspect.rejectChanges();
    }

    this.unitOfWork.rollback();
  }

  getFileUrl(file: File): string {
    switch (file.fileType) {
      case 'PDF':
        return 'http://api.vaderotank.se/PDF/' + file.fileName;
      case 'DOC':
        return 'http://api.vaderotank.se/DOC/' + file.fileName;
      default:
        return '';
    }
  }

  getExportLink(): string {
    let id = this.employee ? this.employee.id : 0;
    return this.apiBase + 'api/Crew/Export/' + id;
  }

  addTurn() {
    this.newTurn = this.unitOfWork.createTurnFactory().create();
    this.newTurn.status = 'Planned';
    this.newTurn.cOE = false;
    this.newTurn.visa = 'None';
    this.newTurn.lOG = 'None';
    this.newTurn.signOff = moment(new Date()).add(1, 'day').toDate();
    this.newTurn.signOn = new Date();
    this.newTurn.relievesTurnId = "";
    if (this.employee) {
      this.newTurn.employee = this.employee;
      this.newTurn.crewTypeId = this.employee.crewTypeId;
    }
  }

  toggleTrainingPlanEditMode(trainingPlan: TrainingPlan) {
    trainingPlan['editMode'] = !trainingPlan['editMode'];
  }

  removeTrainingPlan(trainingPlan: TrainingPlan) {
    this.messageService.displayConfirm('Delete training?', 'Really delete training with code ' + trainingPlan.course.code, (yesNo) => {
      if (yesNo) {
        trainingPlan.entityAspect.entityState = EntityState.Deleted;
        _.remove(this.sortedTrainingPlans, (plan) => plan.id === trainingPlan.id);
        //this.unitOfWork.commit().then(x => {
        //    _.remove(this.sortedTrainingPlans, (plan) => plan.id === trainingPlan.id);
        //    this.unitOfWork.trainingPlanRepo.all().subscribe(plans => {
        //        this.sortedTrainingPlans = _.sortBy(this.employee.trainingPlans, plan => plan.course.code);
        //    });
        //});
      }
    });
  }

  getSortedTurns(turns: Turn[]) {
    return _.filter(turns, t => t.entityAspect.entityState != EntityState.Added).sort((a, b) => {
      return b.signOn > a.signOn ? 1 : b.signOn < a.signOn ? -1 : 0;
    });
  }

  getRowClass(turn: Turn): string {
    if (turn && !turn.allChecksOk(turn.employee, turn.signOff))
      return 'warning';

    return '';
  }

  updateDate(date: any, dateToUpdate: moment.Moment) {
    if (dateToUpdate) {
      if (date.formatted)
        dateToUpdate.set({ year: date.date.year, month: date.date.month - 1, date: date.date.day });
      else
        dateToUpdate.set({ year: 1, month: 1, date: 1 });
    }
  }

  onSignOnDateChanged(date: any) {
    this.newTurn.signOn = new Date(date.formatted);
  }

  onSignOffDateChanged(date: any) {
    this.newTurn.signOff = new Date(date.formatted);
  }

  saveNewTurn() {
    this.employee.turns.push(this.newTurn);
    this.newTurn = null;
  }

  cancelNewTurn() {
    if (this.newTurn.entityAspect.entityState != EntityState.Detached)
      this.newTurn.entityAspect.setEntityState(EntityState.Deleted);

    this.newTurn = null;
  }

  momentifyEmployee(employee: Employee) {
    employee.bTMCourseDate = this.momentify(<Date>employee.bTMCourseDate);
    employee.cRAExpirationDate = this.momentify(<Date>employee.cRAExpirationDate);
    employee.fireCertificationDate = this.momentify(<Date>employee.fireCertificationDate);
    employee.fireCertificationExpireDate = this.momentify(<Date>employee.fireCertificationExpireDate);
    employee.healthCertificationExpireDate = this.momentify(<Date>employee.healthCertificationExpireDate);
    employee.medicalCertificationDate = this.momentify(<Date>employee.medicalCertificationDate);
    employee.medicalCertificationExpireDate = this.momentify(<Date>employee.medicalCertificationExpireDate);
    employee.passportExpirationDate = this.momentify(<Date>employee.passportExpirationDate);
    employee.sIRBExpireDate = this.momentify(<Date>employee.sIRBExpireDate);
    employee.sSOCourseDate = this.momentify(<Date>employee.sSOCourseDate);
    employee.dateOfBirth = this.momentify(<Date>employee.dateOfBirth);
    employee.dateOfEmployment = this.momentify(<Date>employee.dateOfEmployment);
    employee.endorsementDate = this.momentify(<Date>employee.endorsementDate);
    employee.endorsementExpirationDate = this.momentify(<Date>employee.endorsementExpirationDate);
    employee.tankerManOilDate = this.momentify(<Date>employee.tankerManOilDate);
    employee.tankerManOilExpirationDate = this.momentify(<Date>employee.tankerManOilExpirationDate);
    employee.tankerManChemDate = this.momentify(<Date>employee.tankerManChemDate);
    employee.tankerManChemExpirationDate = this.momentify(<Date>employee.tankerManChemExpirationDate);
    employee.gOCDate = this.momentify(<Date>employee.gOCDate);
    employee.gOCExpirationDate = this.momentify(<Date>employee.gOCExpirationDate);
  }

  dateifyEmployee(employee: Employee) {
    employee.bTMCourseDate = this.dateify(<moment.Moment>employee.bTMCourseDate);
    employee.cRAExpirationDate = this.dateify(<moment.Moment>employee.cRAExpirationDate);
    employee.fireCertificationDate = this.dateify(<moment.Moment>employee.fireCertificationDate);
    employee.fireCertificationExpireDate = this.dateify(<moment.Moment>employee.fireCertificationExpireDate);
    employee.healthCertificationExpireDate = this.dateify(<moment.Moment>employee.healthCertificationExpireDate);
    employee.medicalCertificationDate = this.dateify(<moment.Moment>employee.medicalCertificationDate);
    employee.medicalCertificationExpireDate = this.dateify(<moment.Moment>employee.medicalCertificationExpireDate);
    employee.passportExpirationDate = this.dateify(<moment.Moment>employee.passportExpirationDate);
    employee.sIRBExpireDate = this.dateify(<moment.Moment>employee.sIRBExpireDate);
    employee.sSOCourseDate = this.dateify(<moment.Moment>employee.sSOCourseDate);
    employee.dateOfBirth = this.dateify(<moment.Moment>employee.dateOfBirth);
    employee.dateOfEmployment = this.dateify(<moment.Moment>employee.dateOfEmployment);
    employee.endorsementDate = this.dateify(<moment.Moment>employee.endorsementDate);
    employee.endorsementExpirationDate = this.dateify(<moment.Moment>employee.endorsementExpirationDate);
    employee.tankerManOilDate = this.dateify(<moment.Moment>employee.tankerManOilDate);
    employee.tankerManOilExpirationDate = this.dateify(<moment.Moment>employee.tankerManOilExpirationDate);
    employee.tankerManChemDate = this.dateify(<moment.Moment>employee.tankerManChemDate);
    employee.tankerManChemExpirationDate = this.dateify(<moment.Moment>employee.tankerManChemExpirationDate);
    employee.gOCDate = this.dateify(<moment.Moment>employee.gOCDate);
    employee.gOCExpirationDate = this.dateify(<moment.Moment>employee.gOCExpirationDate);
  }

  saveBasicInfo() {
    if (!this.basicInfoForm.valid) {
      this.invalidSubmit = true;
      return;
    }

    this.saveEmployee();
  }

  saveDocs() {
    this.saveEmployee();
  }

  saveMisc() {
    this.saveEmployee();
  }

  saveTrainingPlans() {
    this.saveEmployee();
  }

  saveEmployee() {
    this.invalidSubmit = false;
    this.editMode = false;
    this.dateifyEmployee(this.employee);

    this.unitOfWork.commit().then(x => {
      this.momentifyEmployee(this.employee);
      this.navigateToEmployee(this.employee.employeeNr);
      this.ngOnInit();
      //this.route.params.subscribe(params => this.updateRoute this.updateSelected(parseInt(params['id'])));
    });
  }

  saveEmployeeInfo() {
    this.editMode = false;
    this.unitOfWork.commit();
  }

  getDateString(moment: moment.Moment) {
    return moment && moment.year() != 1 ? moment.format('YYYY-MM-DD') : '';
  }

  get employeeIsNew(): boolean {
    return this.employee.entityAspect.entityState.isAdded();
  }

  createNew() {
    if (this.employee)
      this.dateifyEmployee(this.employee);

    this.employee = this.unitOfWork.createEmployeeFactory().create();
    this.employee.firstName = ''; //To avoid "NULL"
    this.employee.lastName = ''; //To avoid "NULL"
    this.employee.crewTypeId = '';
    this.momentifyEmployee(this.employee);

    this.employee.employeeNr = _.max(_.map(this.employees, e => e.employeeNr)) + 1;
    this.editMode = true;
  }

  activateEditMode() {
    this.editMode = true;
  }

  cancelEdit() {
    this.invalidSubmit = false;
    let isEmployeeNew = this.employeeIsNew;
    this.employee.entityAspect.rejectChanges();
    this.unitOfWork.rollback();
    this.editMode = false;
    if (isEmployeeNew) {
      this.employee = null;
    }

    let getEmployeeNr = this.route.params.subscribe(params => {
      this.updateSelected(parseInt(params['id']));
    });
  }

  dateIsNotNull(date: Date | moment.Moment) {
    if (!date) return false;
    if (date instanceof Date)
      return date.getFullYear() != 1;
    if (moment.isMoment(date))
      return date.year() != 1;

    return false;
  }

  openNewTrainingPlanDialog() {
    let crewGroupId = this.employee.crewType.crewGroupId;
    this.mandatoryCourses = _.orderBy(_.filter(this.unitOfWork.courseRepo.whereInCache(Predicate.and(new Predicate('crewGroupId', '==', crewGroupId), new Predicate('type', '==', 'Mandatory'))), c => !this.employee.trainingPlans.some(p => p.courseId === c.id)), c => c.name);
    this.optionalCourses = _.orderBy(_.filter(this.unitOfWork.courseRepo.whereInCache(Predicate.not(Predicate.and(new Predicate('crewGroupId', '==', crewGroupId), new Predicate('type', '==', 'Mandatory')))), c => !this.employee.trainingPlans.some(p => p.courseId === c.id)), c => c.name);

    this.showNewTrainingDialog = true;
    this.newTrainingPlan = this.unitOfWork.createTrainingPlanFactory().create();
    this.newTrainingPlan.employeeId = this.employee.id;
    this.newTrainingPlan.type = 'Mandatory';
  }

  saveNewTraining() {
    if (!this.newTrainingForm.valid) return;
    this.showNewTrainingDialog = false;

    this.saveEmployee();
  }

  cancelNewTraining() {
    this.newTrainingPlan.entityAspect.rejectChanges();
    this.newTrainingPlan = null;
    this.showNewTrainingDialog = false;
  }

  getDateClass(date: moment.Moment): string {
    if (date && moment.isMoment(date) && this.dateIsNotNull(date)) {
      return date.startOf('day').isBefore(moment().startOf('day'), 'day') ? 'text-danger' : '';
    }

    return '';
  }
}
