﻿<div *ngIf="busy" class="loading-background">
    <div class="loading-middle">
        <div class="loading-inner">
            <div class="panel panel-default">
                <div class="panel-body">
                    <p>Please wait</p>
                    <sk-three-bounce></sk-three-bounce>
                </div>
            </div>
        </div>
    </div>
</div>