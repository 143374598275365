﻿import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'select2-fix',
    template: ''
})
export class Select2FixComponent implements OnInit, OnDestroy {
    ngOnInit() {
        jQuery('head').append('<style id="select2-fix">.select2-container { z-index: 2001 !important; }</style>');
    }

    ngOnDestroy() {
        jQuery('style#select2-fix').remove();
    }
}