import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable ,  Subscriber ,  Observer } from 'rxjs';
import { EntityState } from 'breeze-client';

import { CourseResult } from '../core/entities/course-result.model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';

@Component({
    selector: 'courseresults',
    template: `<h3>Course results administration</h3>
               <hr />
               <button class="btn btn-primary pull-right" (click)="new()">Add new&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
               <list-table [list]="results" [headers]="displayFields" [props]="props" [type]="type" (delete)="delete($event)"></list-table>`
})
export class CourseResultsComponent implements OnInit
{
    results: CourseResult[];
    type: string = 'sysadmin.courseresult';
    displayFields: string[] = ['Code', 'Description', 'Color'];
    props: string[] = ['code', 'text', 'color'];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, private router: Router, private messageService: MessageService) { }

    ngOnInit() {
        this.unitOfWork.courseResultRepo.all(true).subscribe(cachedResults => {
            if (cachedResults.length === 0 || cachedResults.length === 1)
                this.unitOfWork.courseResultRepo.all().subscribe(dbResults => {
                    this.results = dbResults;
                });
            else
                this.results = cachedResults;
        });
    }

    edit(result: CourseResult) {
        this.router.navigate(['/sysadmin/courseresult', result.id]);
    }

    delete(crewGroup: CourseResult) {
        this.messageService.displayConfirm('Delete course result?', 'Really delete course result?', (yesNo: boolean) => {
            if (yesNo) {
                crewGroup.entityAspect.entityState = EntityState.Deleted;
                this.unitOfWork.commit().then(response => this.unitOfWork.courseResultRepo.all(true).subscribe(results => this.results = results));
            }
        });
        
    }

    new() {
        this.router.navigate(['/sysadmin/courseresult']);
    }
}