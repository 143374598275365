/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.show = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "outerWrapper"], ["style", "z-index: 2000;"]], [[2, "dark", null], [2, "large", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "middleWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "innerWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "panel panel-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "panelIcon glyphicon glyphicon-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "panel-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "pull-right"], ["style", "display: inline-block;"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "vt-modal-body-container"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "panel-footer"]], null, null, null, null, null)), i1.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.closeable; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modal; var currVal_1 = _co.large; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.title; _ck(_v, 7, 0, currVal_2); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal-window", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalComponent, [], null, null)], null, null); }
var ModalComponentNgFactory = i1.ɵccf("modal-window", i3.ModalComponent, View_ModalComponent_Host_0, { modal: "modal", large: "large", title: "title", show: "show", closeable: "closeable" }, {}, [".vt-modal-header", ".vt-modal-body", ".vt-modal-footer"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
