﻿import { Component } from '@angular/core';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';

@Component({
    selector: 'admin-component',
    template: `<div class="row">
                    <div class="col-sm-3">
                        <router-outlet name="sidemenu"></router-outlet>
                    </div>
                    <div class="col-sm-9">
                        <router-outlet></router-outlet>
                    </div>
                </div>`
})
export class AdminComponent {
    constructor() {

    }
}