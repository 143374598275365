﻿import { EntityBase, CrewGroup, Employee, Schedule, Turn } from './entity-model';
import { core } from 'breeze-client';

export class CrewType extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: CrewType) {}

    crewGroupId: string;
    id: string;
    name: string;
    ranking: number;
    endorsementCOC: boolean;
    endorsementTankOil: boolean;
    endorsementTankChem: boolean;
    endorsementGOC: boolean;
    crewGroup: CrewGroup;
    employees: Employee[];
    schedules: Schedule[];
    turns: Turn[];
}