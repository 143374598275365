import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Vessel } from '../core/entities/entity-model';
import { MessageService } from '../core/message.service';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';

@Component({
    selector: 'vessel',
    templateUrl: 'vessel.component.html'
})
export class VesselComponent implements OnInit {
    vessel: Vessel = new Vessel();
    vesselForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private unitOfWork: VaderoTankUnitOfWorkService
        , private route: ActivatedRoute, private router: Router
        , private messageService: MessageService) {
        this.vesselForm = this.formBuilder.group({
            'name': [null, Validators.required],
            'callSign': [null, Validators.required],
            'dwt': [0, Validators.required],
            'grt': [0, Validators.required],
            'net': [0, Validators.required],
            'me': [0, Validators.required]
        });
    }

    ngOnInit() {
        this.route.params
            .subscribe(params => {
                if (params['id']) {
                    this.unitOfWork.vesselRepo.withId(params['id'])
                        .subscribe(vessel => this.vessel = vessel);
                }
                else {
                    this.vessel = this.unitOfWork.createVesselFactory().create();
                }
            });
    }

    isInvalid(control: string): boolean {
        return !this.vesselForm.controls[control].valid && this.vesselForm.controls[control].touched;
    }

    saveChanges() {
        this.unitOfWork.commit().catch(
            error => {
                this.messageService.displayMessage('Error', 'Error saving vessel');
            }
        ).then(response => {
                this.router.navigate(['/sysadmin/vessels']);
            });
    }

    cancel() {
        this.unitOfWork.rollback();
        this.router.navigate(['/sysadmin/vessels']);
    }
}
