import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Predicate } from 'breeze-client';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Employee, CrewType, Turn, Vessel } from '../core/entities/entity-model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';
import { BusyService } from '../core/busy.service';
import { AuthService } from '../core/auth.service';

@Component({
        selector: 'dashboard-upcoming-relieves',
    templateUrl: 'dashboard-upcoming-relieves.component.html',
    styles: ["th.date, td.date { min-width: 100px; }"]
})
export class DashboardUpcomingRelievesComponent implements OnInit {
    turns: Turn[]; // Only the ones we display
    allTurns: Turn[]; // All we need available
    vessels: Vessel[];
    crewTypes: CrewType[];
    employees: Employee[];

    turnToEdit: Turn;
    employee: Employee;
    vessel: Vessel;

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, formBuilder: FormBuilder, private messageService: MessageService
        , private route: ActivatedRoute, private router: Router, private busyService: BusyService, private authService: AuthService) { }

    ngOnInit() {
        var upcoming = Predicate.create("signOn", ">=", moment().subtract(1, 'day').startOf('day'));
        var relieves = Predicate.create("relievesTurnId", "!=", "00000000-0000-0000-0000-000000000000");

        (this.busyService.busy(this.unitOfWork.turnRepo.where(upcoming.and(relieves))) as Observable<Turn[]>).subscribe(turns => {
            this.turns = turns;
            this.unitOfWork.vesselRepo.all().subscribe(vessels => this.vessels = _.sortBy(vessels, v => v.name));
            this.unitOfWork.crewTypeRepo.all().subscribe(types => this.crewTypes = _.sortBy(types, c => c.ranking));
            this.unitOfWork.employeeRepo.all().subscribe(emps => this.employees = _.sortBy(emps, e => e.displayName.toLowerCase()));
            this.unitOfWork.turnRepo.all().subscribe(turns => this.allTurns = _.sortBy(turns, t => t.signOn));
        });
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

    editTurn(turn: Turn) {
        this.vessel = turn.vessel;
        this.employee = turn.employee;
        this.turnToEdit = turn;
    }

    saveTurn() {
        this.turnToEdit = null;
    }

    cancelEdit() {
        this.turnToEdit = null;
    }

    getRowClass(turn: Turn): string {
        if (turn && turn.employee)
            if (!turn.allChecksOk(turn.employee, turn.signOff))
                return 'warning';

        return '';
    }
}
