﻿import { EntityBase, Employee, File } from './entity-model';
import { core } from 'breeze-client';

export class EmployeeFile extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: EmployeeFile) { }

    id: string;
    employeeId: string;
    fileId: string;
    employee: Employee;
    file: File;
}