﻿<h3>Edit course result</h3>
<hr />
<form (ngSubmit)="saveChanges()" [formGroup]="courseResultForm">
    <div class="form-group">
        <label for="NameTB">Code</label>
        <input id="NameTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': !courseResultForm.controls['code'].valid && courseResultForm.controls['code'].touched }"
               [(ngModel)]="result.code" [formControl]="courseResultForm.controls['code']" />
        <div *ngIf="!courseResultForm.controls['code'].valid" class="alert alert-danger">Code required!</div>
    </div>
    <div class="form-group">
        <label for="DescriptionTB">Description</label>
        <input id="DescriptionTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': !courseResultForm.controls['text'].valid && courseResultForm.controls['text'].touched }"
               [(ngModel)]="result.text" [formControl]="courseResultForm.controls['text']" />
        <div *ngIf="!courseResultForm.controls['text'].valid" class="alert alert-danger">Description required!</div>
    </div>
    <div class="form-group">
        <label for="ColorTB">Color</label>
        <input id="ColorTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': !courseResultForm.controls['color'].valid && courseResultForm.controls['color'].touched }"
               [(ngModel)]="result.color" [formControl]="courseResultForm.controls['color']" />
        <div *ngIf="!courseResultForm.controls['color'].valid" class="alert alert-danger">Color required!</div>
    </div>

    <button class="btn btn-primary" type="submit" [disabled]="!courseResultForm.valid">Save</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
</form>