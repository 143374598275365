<h3>Crew</h3>
<hr />
<div class="row">
  <div class="col-sm-12">
    <div class="pull-right">
      <button *ngIf="!editMode && hasRole('CrewWrite')" type="button" style="margin: 5px 5px 5px 5px;"
        class="btn btn-primary pull-right" (click)="tabset.select('basic'); createNew();">Add new employee</button>
      <button *ngIf="!editMode && employee && hasRole('CrewWrite')" type="button" style="margin: 5px 5px 5px 5px;"
        class="btn btn-danger pull-right" (click)="deleteEmployee()">Delete</button>
    </div>
    <div id="employees-dropdown" *ngIf="employees">
      <h4>Select a person by name or employee number</h4>
      <div class="form-inline">
        <div class="form-group" style="min-width: 350px;">
          <ng-select [items]="selectEmployees" [disabled]="editMode" (selected)="updateRoute($event)"
            placeholder="Select employee">
          </ng-select>
        </div>
        <div class="form-group" style="min-width: 200px;">
          <ng-select [items]="employeeIds" [disabled]="editMode" (selected)="updateRoute($event)"
            placeholder="Select employee number">
          </ng-select>
        </div>
      </div>
    </div>
    <hr />
    <div id="employees-list" *ngIf="employees && !employee">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="includeInactiveEmployees" (change)="includeInactiveEmployeesChanged()" />
          Include inactive employees
        </label>
      </div>
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>Last name</th>
            <th>First name</th>
            <th>Employee number</th>
            <th>Employee status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of activeEmployees" (click)="navigateToEmployee(employee.employeeNr)">
            <td><a>{{employee.lastName}}</a></td>
            <td><a>{{employee.firstName + ' ' + (employee.middleName || '')}}</a></td>
            <td><a>{{employee.employeeNr}}</a></td>
            <td>{{employee.active == 'InActive' ? 'Inactive' : employee.active}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3 *ngIf="employee">{{employee.displayName}}</h3>

    <ngb-tabset #tabset>
      <ngb-tab class="active" id="basic" *ngIf="employee" title="Basic info" [disabled]="editMode">
        <ng-template ngbTabContent>
          <div *ngIf="employee && !editMode">
            <div class="clearfix">
              <button *ngIf="!editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right"
                (click)="activateEditMode()">Edit</button>
            </div>
            <basic-info [employee]="employee"></basic-info>
            <hr />
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Civil status: </label>
                  <span [ngSwitch]="employee.status">
                    <span *ngSwitchCase="'Married'">Married</span>
                    <span *ngSwitchCase="'Single'">Single</span>
                    <span *ngSwitchCase="'Cohabiting'">Cohabiting</span>
                  </span>
                </div>
                <div class="form-group">
                  <label>Road: </label>
                  <span>{{employee.road}}</span>
                </div>
                <div class="form-group">
                  <label>Postal code: </label>
                  <span>{{employee.postalCode}}</span>
                </div>
                <div class="form-group">
                  <label>City: </label>
                  <span>{{employee.city}}</span>
                </div>
                <div class="form-group">
                  <label>Country: </label>
                  <span>{{employee.country2}}</span>
                </div>
                <div class="form-group">
                  <label>Email: </label>
                  <span>{{employee.email}}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Next of kin: </label>
                  <span>{{employee.nextOfKin}}</span>
                </div>
                <div class="form-group">
                  <label>Phone (home): </label>
                  <span>{{employee.homePhone}}</span>
                </div>
                <div class="form-group">
                  <label>Phone (mobile): </label>
                  <span>{{employee.mobilePhone}}</span>
                </div>
                <div class="form-group">
                  <label>Country/Region: </label>
                  <span>{{employee.country}}</span>
                </div>
                <div class="form-group">
                  <label>Job status: </label>
                  <span>{{employee.active}}</span>
                </div>
                <div class="form-group">
                  <label>Sea experience: </label>
                  <blockquote>{{employee.experience}}</blockquote>
                </div>
              </div>
            </div>
          </div>
          <form *ngIf="employee && editMode" [formGroup]="basicInfoForm">
            <div class="row">
              <div class="clearfix">
                <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                  style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right"
                  (click)="saveBasicInfo()">Save</button>
                <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                  style="margin: 5px 5px 5px 5px;" class="btn btn-danger pull-right"
                  (click)="cancelEdit()">Cancel</button>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Employee number</label><span class="text-danger">*</span>
                  <p *ngIf="!employeeIsNew">{{employee.employeeNr}}</p>
                  <input [ngStyle]="{ 'visibility': employeeIsNew ? 'visible' : 'hidden' }" formControlName="employeeNr"
                    class="form-control" [(ngModel)]="employee.employeeNr" type="number" />
                  <div class="alert alert-danger"
                    *ngIf="basicInfoForm.controls['employeeNr'].hasError('required') && (invalidSubmit || basicInfoForm.controls['employeeNr'].touched)">
                    Employee number is required!</div>
                </div>
                <div class="form-group">
                  <label>Given name</label><span class="text-danger">*</span>
                  <input type="text" formControlName="firstName" [(ngModel)]="employee.firstName"
                    class="form-control" />
                  <div class="alert alert-danger"
                    *ngIf="basicInfoForm.controls['firstName'].hasError('required') && (invalidSubmit || basicInfoForm.controls['firstName'].touched)">
                    Given name is required!</div>
                </div>
                <div class="form-group">
                  <label>Surname</label><span class="text-danger">*</span>
                  <input type="text" formControlName="lastName" [(ngModel)]="employee.lastName" class="form-control" />
                  <div class="alert alert-danger"
                    *ngIf="basicInfoForm.controls['lastName'].hasError('required') && (invalidSubmit || basicInfoForm.controls['lastName'].touched)">
                    Surname is required!</div>
                </div>
                <div class="form-group">
                  <label>Date of birth</label>
                  <my-date-picker locale="sv" [options]="datePickerConfig"
                    [selDate]="getDateString(employee.dateOfBirth)"
                    (dateChanged)="updateDate($event, employee.dateOfBirth)"></my-date-picker>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Date of first employment</label>
                  <my-date-picker locale="sv" [options]="datePickerConfig"
                    [selDate]="getDateString(employee.dateOfEmployment)"
                    (dateChanged)="updateDate($event, employee.dateOfEmployment)"></my-date-picker>
                </div>
                <div class="form-group">
                  <label>Middle name</label>
                  <input type="text" formControlName="middleName" [(ngModel)]="employee.middleName"
                    class="form-control" />
                </div>
                <div class="form-group">
                  <label>Position</label><span class="text-danger">*</span>
                  <select formControlName="crewType" [(ngModel)]="employee.crewTypeId" class="form-control">
                    <option value="">Select position</option>
                    <option *ngFor="let crewType of crewTypes" [value]="crewType.id">{{crewType.name}}</option>
                  </select>
                  <div class="alert alert-danger"
                    *ngIf="basicInfoForm.controls['crewType'].hasError('required') && (invalidSubmit || basicInfoForm.controls['crewType'].touched)">
                    Position is required!</div>
                </div>
                <div class="form-group">
                  <label>Place of birth</label>
                  <input type="text" formControlName="placeOfBirth" [(ngModel)]="employee.placeOfBirth"
                    class="form-control" />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Civil status</label><span class="text-danger">*</span>
                  <select formControlName="civilStatus" [(ngModel)]="employee.status" class="form-control">
                    <option value="">Select civil status</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Cohabiting">Cohabiting</option>
                  </select>
                  <div class="alert alert-danger"
                    *ngIf="basicInfoForm.controls['civilStatus'].hasError('required') && (invalidSubmit || basicInfoForm.controls['civilStatus'].touched)">
                    Civil status is required!</div>
                </div>
                <div class="form-group">
                  <label>Road</label>
                  <input type="text" formControlName="road" [(ngModel)]="employee.road" class="form-control" />
                </div>
                <div class="form-group">
                  <label>Postal Code</label>
                  <input type="text" formControlName="postalCode" [(ngModel)]="employee.postalCode"
                    class="form-control" />
                </div>
                <div class="form-group">
                  <label>City</label>
                  <input type="text" formControlName="city" [(ngModel)]="employee.city" class="form-control" />
                </div>
                <div class="form-group">
                  <label>Country</label>
                  <input type="text" formControlName="country2" [(ngModel)]="employee.country2" class="form-control" />
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input type="email" formControlName="email" [(ngModel)]="employee.email" class="form-control" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Next of kin</label>
                  <input type="text" formControlName="nextOfKin" [(ngModel)]="employee.nextOfKin"
                    class="form-control" />
                </div>
                <div class="form-group">
                  <label>Phone (home)</label>
                  <input type="text" formControlName="phoneHome" [(ngModel)]="employee.homePhone"
                    class="form-control" />
                </div>
                <div class="form-group">
                  <label>Phone(mobile)</label>
                  <input type="text" formControlName="phoneMobile" [(ngModel)]="employee.mobilePhone"
                    class="form-control" />
                </div>
                <div class="form-group">
                  <label>Country/Region</label>
                  <input type="text" formControlName="country" [(ngModel)]="employee.country" class="form-control" />
                </div>
                <div class="form-group">
                  <label>Job status</label><span class="text-danger">*</span>
                  <select [(ngModel)]="employee.active" class="form-control" formControlName="active">
                    <option value="">Select job status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">Inactive</option>
                    <option value="Applicant">Applicant</option>
                  </select>
                  <div class="alert alert-danger"
                    *ngIf="basicInfoForm.controls['active'].hasError('required') && (invalidSubmit || basicInfoForm.controls['active'].touched)">
                    Job status is required!</div>
                </div>
                <div class="form-group">
                  <label>Sea experience</label>
                  <textarea class="form-control" formControlName="seaExperience"
                    [(ngModel)]="employee.experience"></textarea>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="docs" title="Documents" *ngIf="employee" [disabled]="editMode">
        <ng-template ngbTabContent>
          <!--<basic-info [employee]="employee"></basic-info>
                    <hr />-->
          <div *ngIf="employee && !editMode" style="margin-top: 5px;" class="row">
            <div class="clearfix">
              <button *ngIf="!editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right"
                (click)="activateEditMode()">Edit</button>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label>Endorsement</label>
                <span
                  *ngIf="employee.endorsementDate && dateIsNotNull(employee.endorsementDate)">{{employee.endorsementDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Tankerman oil</label>
                <span
                  *ngIf="employee.tankerManOilDate && dateIsNotNull(employee.tankerManOilDate)">{{employee.tankerManOilDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Tankerman chem</label>
                <span
                  *ngIf="employee.tankerManChemDate && dateIsNotNull(employee.tankerManChemDate)">{{employee.tankerManChemDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>GOC/GMDSS</label>
                <span
                  *ngIf="employee.gOCDate && dateIsNotNull(employee.gOCDate)">{{employee.gOCDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Fire certificate</label>
                <span
                  *ngIf="employee.fireCertificationDate && dateIsNotNull(employee.fireCertificationDate)">{{employee.fireCertificationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Passport nr</label>
                <span>{{employee.passportNr}}</span>
              </div>
              <div class="form-group">
                <label>SIRB nr</label>
                <span>{{employee.sIRBNr}}</span>
              </div>
              <div class="form-group">
                <label>Medical care</label>
                <span
                  *ngIf="employee.medicalCertificationDate && dateIsNotNull(employee.medicalCertificationDate)">{{employee.medicalCertificationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>SSO/ISPS course</label>
                <span
                  *ngIf="employee.sSOCourseDate && dateIsNotNull(employee.sSOCourseDate)">{{employee.sSOCourseDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>BTM/BRMS course</label>
                <span
                  *ngIf="employee.bTMCourseDate && dateIsNotNull(employee.bTMCourseDate)">{{employee.bTMCourseDate | date : 'yyyy-MM-dd'}}</span>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label>CRA valid until</label>
                <span [class]="getDateClass(employee.cRAExpirationDate)"
                  *ngIf="employee.cRAExpirationDate && dateIsNotNull(employee.cRAExpirationDate)">{{employee.cRAExpirationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Endorsement expire</label>
                <span [class]="getDateClass(employee.endorsementExpirationDate)"
                  *ngIf="employee.endorsementExpirationDate && dateIsNotNull(employee.endorsementExpirationDate)">{{employee.endorsementExpirationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Tankerman oil expire</label>
                <span [class]="getDateClass(employee.tankerManOilExpirationDate)"
                  *ngIf="employee.tankerManOilExpirationDate && dateIsNotNull(employee.tankerManOilExpirationDate)">{{employee.tankerManOilExpirationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Tankerman chem expire</label>
                <span [class]="getDateClass(employee.tankerManChemExpirationDate)"
                  *ngIf="employee.tankerManChemExpirationDate && dateIsNotNull(employee.tankerManChemExpirationDate)">{{employee.tankerManChemExpirationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>GOC/GMDSS expire</label>
                <span [class]="getDateClass(employee.gOCExpirationDate)"
                  *ngIf="employee.gOCExpirationDate && dateIsNotNull(employee.gOCExpirationDate)">{{employee.gOCExpirationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Fire certificate expire</label>
                <span [class]="getDateClass(employee.fireCertificationExpireDate)"
                  *ngIf="employee.fireCertificationExpireDate && dateIsNotNull(employee.fireCertificationExpireDate)">{{employee.fireCertificationExpireDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Health certificate expire</label>
                <span [class]="getDateClass(employee.healthCertificationExpireDate)"
                  *ngIf="employee.healthCertificationExpireDate && dateIsNotNull(employee.healthCertificationExpireDate)">{{employee.healthCertificationExpireDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Passport expire</label>
                <span [class]="getDateClass(employee.passportExpirationDate)"
                  *ngIf="employee.passportExpirationDate && dateIsNotNull(employee.passportExpirationDate)">{{employee.passportExpirationDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>SIRB expire</label>
                <span [class]="getDateClass(employee.sIRBExpireDate)"
                  *ngIf="employee.sIRBExpireDate && dateIsNotNull(employee.sIRBExpireDate)">{{employee.sIRBExpireDate | date : 'yyyy-MM-dd'}}</span>
              </div>
              <div class="form-group">
                <label>Medical care expire</label>
                <span [class]="getDateClass(employee.medicalCertificationExpireDate)"
                  *ngIf="employee.medicalCertificationExpireDate && dateIsNotNull(employee.medicalCertificationExpireDate)">{{employee.medicalCertificationExpireDate | date : 'yyyy-MM-dd'}}</span>
              </div>
            </div>

            <div class="col-sm-4">
              <ul class="vt-file-list">
                <li *ngFor="let file of files">
                  <a target="_blank" [href]="fileApiBase + file.id">{{file.fileName}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="employee && editMode">
            <div class="clearfix">
              <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right" (click)="saveDocs()">Save</button>
              <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-danger pull-right"
                (click)="cancelEdit()">Cancel</button>
            </div>
            <div class="row" style="margin-top: 5px;">
              <div class="col-sm-12">
                <vt-file-upload (update)="updateFiles()" [apiUrl]="saveFileUrl"></vt-file-upload>
                <hr />
              </div>
            </div>
            <div class="row" style="margin-top: 5px;">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>CRA Type</label>
                      <input type="text" class="form-control" [(ngModel)]="employee.cRA" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>CRA valid until</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.cRAExpirationDate)"
                        (dateChanged)="updateDate($event, employee.cRAExpirationDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Endorsement date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.endorsementDate)"
                        (dateChanged)="updateDate($event, employee.endorsementDate)"></my-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Endorsement expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.endorsementExpirationDate)"
                        (dateChanged)="updateDate($event, employee.endorsementExpirationDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Tankerman oil date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.tankerManOilDate)"
                        (dateChanged)="updateDate($event, employee.tankerManOilDate)"></my-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Tankerman oil expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.tankerManOilExpirationDate)"
                        (dateChanged)="updateDate($event, employee.tankerManOilExpirationDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Tankerman chem date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.tankerManChemDate)"
                        (dateChanged)="updateDate($event, employee.tankerManChemDate)"></my-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Tankerman chem expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.tankerManChemExpirationDate)"
                        (dateChanged)="updateDate($event, employee.tankerManChemExpirationDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>GOC/GMDSS date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.gOCDate)"
                        (dateChanged)="updateDate($event, employee.gOCDate)"></my-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>GOC/GMDSS expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.gOCExpirationDate)"
                        (dateChanged)="updateDate($event, employee.gOCExpirationDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Fire certification date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.fireCertificationDate)"
                        (dateChanged)="updateDate($event, employee.fireCertificationDate)"></my-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Fire certification expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.fireCertificationExpireDate)"
                        (dateChanged)="updateDate($event, employee.fireCertificationExpireDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Medical care</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.medicalCertificationDate)"
                        (dateChanged)="updateDate($event, employee.medicalCertificationDate)"></my-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Medical care expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.medicalCertificationExpireDate)"
                        (dateChanged)="updateDate($event, employee.medicalCertificationExpireDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-sm-offset-6">
                    <div class="form-group">
                      <label>Health certification expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.healthCertificationExpireDate)"
                        (dateChanged)="updateDate($event, employee.healthCertificationExpireDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Passport number</label>
                      <input type="text" class="form-control" [(ngModel)]="employee.passportNr" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Passport expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.passportExpirationDate)"
                        (dateChanged)="updateDate($event, employee.passportExpirationDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>SIRB number</label>
                      <input type="text" class="form-control" [(ngModel)]="employee.sIRBNr" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>SIRB expiration date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.sIRBExpireDate)"
                        (dateChanged)="updateDate($event, employee.sIRBExpireDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>SSO course date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.sSOCourseDate)"
                        (dateChanged)="updateDate($event, employee.sSOCourseDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>BTM course date</label>
                      <my-date-picker locale="sv" [options]="datePickerConfig"
                        [selDate]="getDateString(employee.bTMCourseDate)"
                        (dateChanged)="updateDate($event, employee.bTMCourseDate)"></my-date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <ul class="vt-file-list">
                  <li *ngFor="let file of files">
                    <button type="button" class="btn btn-link" (click)="removeFile(file)"><span
                        class="text-danger glyphicon glyphicon-remove"></span></button><a target="_blank"
                      [href]="fileApiBase + file.id">{{file.fileName}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="service" title="Sea Service" *ngIf="employee" [disabled]="editMode">
        <ng-template ngbTabContent>
          <!--<basic-info [employee]="employee"></basic-info>
                    <hr />-->
          <div style="margin-top: 5px;" class="row">
            <div class="col-sm-12">
              <div class="pull-right">
                <button *ngIf="hasRole('CrewWrite')" class="btn btn-primary" type="button" (click)="addTurn()"
                  [disabled]="newTurn">Add sea service&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
                <a class="btn btn-primary" role="button" [href]="getExportLink()"><span style="color:white">Export sea
                    service&nbsp;<span class="glyphicon glyphicon-floppy-save"></span></span></a>
              </div>

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>
                      Sign on
                    </th>
                    <th>
                      Sign off
                    </th>
                    <th>
                      Position
                    </th>
                    <th>
                      Vessel
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let turn of getSortedTurns(employee.turns)" [class]="getRowClass(turn)">
                    <td>
                      <span>{{turn.signOn | date : 'yyyy-MM-dd'}}</span>
                    </td>
                    <td>
                      <span>{{turn.signOff | date : 'yyyy-MM-dd'}}</span>
                    </td>
                    <td>
                      <span>{{turn.crewType.name}}</span>
                    </td>
                    <td>
                      <span>{{turn.vessel.name}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <vt-turn-edit [turn]="newTurn" [employee]="employee" [vessels]="vessels" [turns]="allTurns"
                [crewTypes]="crewTypes" (save)="saveNewTurn()" (cancel)="cancelNewTurn()"></vt-turn-edit>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="training" title="Personal Training" *ngIf="employee" [disabled]="editMode">
        <ng-template ngbTabContent>
          <!--<basic-info [employee]="employee"></basic-info>
                    <hr />-->
          <div class="row" style="margin-top: 5px;">
            <div class="clearfix">
              <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-left"
                (click)="openNewTrainingPlanDialog()">New training&nbsp;<span
                  class="glyphicon glyphicon-plus"></span></button>
              <button *ngIf="!editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right"
                (click)="activateEditMode()">Edit</button>
              <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right"
                (click)="saveTrainingPlans()">Save</button>
              <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-danger pull-right"
                (click)="cancelEdit()">Cancel</button>
            </div>
            <div class="col-sm-12">
              <h4>Mandatory courses</h4>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>
                      Code
                    </th>
                    <th>
                      Course
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Info
                    </th>
                    <th *ngIf="editMode">

                    </th>
                    <th *ngIf="editMode">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let trainingPlan of sortedTrainingPlans">
                    <ng-template [ngIf]="trainingPlan.course.type === 'Mandatory'">
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color">{{trainingPlan.course.code}}</span>
                      </td>
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color">{{trainingPlan.course.name}}</span>
                      </td>
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color"
                          *ngIf="!trainingPlan.editMode || !editMode">{{trainingPlan.courseResult.code}}</span>
                        <select class="form-control" *ngIf="trainingPlan.editMode && editMode"
                          [(ngModel)]="trainingPlan.courseResultId">
                          <option *ngFor="let courseResult of courseResults" [value]="courseResult.id">
                            {{courseResult.code}}</option>
                        </select>
                      </td>
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color"><a target="_blank"
                            *ngIf="trainingPlan.course.file"
                            [href]="getFileUrl(trainingPlan.course.file)">{{trainingPlan.course.file.fileName}}</a></span>
                      </td>
                      <td *ngIf="editMode" class="icon-col">
                        <button type="button" class="btn btn-link glyphicon glyphicon-pencil"
                          [ngClass]="{ 'glyphicon-pencil': !trainingPlan.editMode, 'glyphicon-ok': trainingPlan.editMode }"
                          (click)="toggleTrainingPlanEditMode(trainingPlan)"></button>
                      </td>
                      <td *ngIf="editMode" class="icon-col">
                        <button type="button" class="btn btn-link glyphicon glyphicon-remove"
                          (click)="removeTrainingPlan(trainingPlan)"></button>
                      </td>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
              <h4>Other courses</h4>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>
                      Code
                    </th>
                    <th>
                      Course
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Info
                    </th>
                    <th *ngIf="editMode"></th>
                    <th *ngIf="editMode"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let trainingPlan of sortedTrainingPlans">
                    <ng-template [ngIf]="trainingPlan.course.type != 'Mandatory'">
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color">{{trainingPlan.course.code}}</span>
                      </td>
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color">{{trainingPlan.course.name}}</span>
                      </td>
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color"
                          *ngIf="!trainingPlan.editMode">{{trainingPlan.courseResult.code}}</span>
                        <select class="form-control" *ngIf="trainingPlan.editMode"
                          [(ngModel)]="trainingPlan.courseResultId">
                          <option *ngFor="let courseResult of courseResults" [value]="courseResult.id">
                            {{courseResult.code}}</option>
                        </select>
                      </td>
                      <td>
                        <span [style.color]="trainingPlan.courseResult.color"><a target="_blank"
                            *ngIf="trainingPlan.course.file"
                            [href]="getFileUrl(trainingPlan.course.file)">{{trainingPlan.course.file.fileName}}</a></span>
                      </td>
                      <td *ngIf="editMode" class="icon-col">
                        <button type="button" class="btn btn-link glyphicon glyphicon-pencil"
                          [ngClass]="{ 'glyphicon-pencil': !trainingPlan.editMode, 'glyphicon-ok': trainingPlan.editMode }"
                          (click)="toggleTrainingPlanEditMode(trainingPlan)"></button>
                      </td>
                      <td *ngIf="editMode" class="icon-col">
                        <button type="button" class="btn btn-link glyphicon glyphicon-remove"
                          (click)="removeTrainingPlan(trainingPlan)"></button>
                      </td>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
              <modal-window *ngIf="newTrainingPlan" title="New training" [modal]="true" [show]="showNewTrainingDialog">
                <div class="vt-modal-body">
                  <div class="row">
                    <form [formGroup]="newTrainingForm">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label>Course type</label>
                          <label>
                            <input type="radio" formControlName="type" value="Mandatory"
                              [(ngModel)]="newTrainingPlan.type" checked />
                            Mandatory for crew group
                          </label>
                          <label>
                            <input type="radio" formControlName="type" value="Optional"
                              [(ngModel)]="newTrainingPlan.type" />
                            Optional
                          </label>
                        </div>
                        <div class="form-group">
                          <label for="courseDD">Course</label>
                          <select *ngIf="newTrainingPlan.type == 'Mandatory'" class="form-control"
                            formControlName="course" id="courseDD" [(ngModel)]="newTrainingPlan.courseId">
                            <option value="">Select mandatory course</option>
                            <option *ngFor="let course of mandatoryCourses" [value]="course.id">{{course.name}}</option>
                          </select>
                          <select *ngIf="newTrainingPlan.type == 'Optional'" class="form-control"
                            formControlName="course" id="courseDD" [(ngModel)]="newTrainingPlan.courseId">
                            <option value="">Select optional course</option>
                            <option *ngFor="let course of optionalCourses" [value]="course.id">{{course.name}}</option>
                          </select>
                          <div class="alert alert-danger"
                            *ngIf="!newTrainingForm.controls['course'].valid && newTrainingForm.controls['course'].touched">
                            Course required!</div>
                        </div>
                        <div class="form-group">
                          <label for="courseResultDD">Course result</label>
                          <select class="form-control" formControlName="result" id="courseResultDD"
                            [(ngModel)]="newTrainingPlan.courseResultId">
                            <option *ngFor="let result of courseResults" [value]="result.id">{{result.code}}</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="vt-modal-footer">
                  <button type="button" class="btn btn-lg btn-info btn-block" (click)="saveNewTraining()">Save</button>
                  <button type="button" class="btn btn-lg btn-danger btn-block"
                    (click)="cancelNewTraining()">Cancel</button>
                </div>
              </modal-window>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="misc" title="Misc." *ngIf="employee" [disabled]="editMode">
        <div *ngIf="employee">
          <ng-template ngbTabContent>
            <!--<basic-info [employee]="employee"></basic-info>
                        <hr />-->
            <div class="clearfix">
              <button *ngIf="!editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right"
                (click)="activateEditMode()">Edit</button>
              <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-primary pull-right" (click)="saveMisc()">Save</button>
              <button *ngIf="editMode && employee && hasRole('CrewWrite')" type="button"
                style="margin: 5px 5px 5px 5px;" class="btn btn-danger pull-right"
                (click)="cancelEdit()">Cancel</button>
            </div>
            <div class="row" style="margin-top: 5px;">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Info</label>
                  <blockquote *ngIf="!editMode">{{employee.info}}</blockquote>
                  <textarea *ngIf="editMode" type="text" class="form-control" [(ngModel)]="employee.info"></textarea>
                </div>
                <div class="form-group">
                  <label>Other info</label>
                  <blockquote *ngIf="!editMode">{{employee.otherInfo}}</blockquote>
                  <textarea *ngIf="editMode" type="text" class="form-control"
                    [(ngModel)]="employee.otherInfo"></textarea>
                </div>
                <!--<div type="button" *ngIf="editMode" class="btn btn-lg btn-block btn-primary" (click)="saveEmployeeInfo()">Save</div>
                                <div type="button" *ngIf="editMode" class="btn btn-lg btn-block btn-danger" (click)="editMode = false">Cancel</div>-->
              </div>
            </div>
          </ng-template>
        </div>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
