﻿import { Component, Input } from '@angular/core';
import { Employee } from '../core/entities/entity-model';

@Component({
        selector: 'basic-info',
    templateUrl: 'basic-info.component.html'
})
export class BasicInfoComponent {
    @Input() employee: Employee;
}
