/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./select2-fix.component";
var styles_Select2FixComponent = [];
var RenderType_Select2FixComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Select2FixComponent, data: {} });
export { RenderType_Select2FixComponent as RenderType_Select2FixComponent };
export function View_Select2FixComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_Select2FixComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "select2-fix", [], null, null, null, View_Select2FixComponent_0, RenderType_Select2FixComponent)), i0.ɵdid(1, 245760, null, 0, i1.Select2FixComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Select2FixComponentNgFactory = i0.ɵccf("select2-fix", i1.Select2FixComponent, View_Select2FixComponent_Host_0, {}, {}, []);
export { Select2FixComponentNgFactory as Select2FixComponentNgFactory };
