﻿import { EntityBase, CourseResult, Employee, Course } from './entity-model';
import { core, EntityQuery } from 'breeze-client';

export class TrainingPlan extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: TrainingPlan) {}

    courseId: string;
    courseResultId: string;
    employeeId: string;
    id: string;
    type: string;
    employee: Employee;
    courseResult: CourseResult;
    course: Course;
}