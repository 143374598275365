﻿import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'message-box',
    template: `<modal-window [title]="title" [modal]="true" [show]="show">
                    <div class="vt-modal-body">
                        <p [innerHtml]="message"></p>
                    </div>
                    <div class="vt-modal-footer">
                        <button class="btn btn-primary" (click)="close()">Ok</button>
                    </div>
                </modal-window>`
})
export class MessageBoxComponent {
    @Input() show: boolean;
    @Input() title: string;
    @Input() message: string;
    @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    public close() {
        this.closed.emit();
    }
}
