import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EntityState } from 'breeze-client';

import { User } from '../core/entities/user.model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';
import { AuthService } from '../core/auth.service';

@Component({
    selector: 'users',
    template: `<h3>User administration</h3>
               <hr />
               <button class="btn btn-primary pull-right" (click)="new()">Add new&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
               <list-table [list]="users" [headers]="displayFields" [type]="type" (delete)="delete($event)"></list-table>`
})
export class UsersComponent implements OnInit {
    type: string = 'sysadmin.user';
    displayFields: string[] = ['Name', 'UserName'];
    users: User[];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, private router: Router
        , private messageService: MessageService, private authService: AuthService) { }

    ngOnInit() {
        this.unitOfWork.userRepo.all(true).subscribe(cachedUsers => {
            if (cachedUsers.length === 0)
                this.unitOfWork.userRepo.all().subscribe(dbUsers => this.users = dbUsers);
            else
                this.users = cachedUsers;
        });
    }

    edit(user: User) {
        this.router.navigate(['/sysadmin/user', user.id]);
    }

    delete(user: User) {
        if (this.authService.authentication.user.id === user.id) {
            this.messageService.displayMessage('Error', 'You cannot delete yourself');
        }
        else {
            this.messageService.displayConfirm('Delete user?', 'Really delete user "' + user.name + '"? (This cannot be undone!)', (yesNo: boolean) => {
                if (yesNo) {
                    user.entityAspect.entityState = EntityState.Deleted;
                    this.unitOfWork.commit().then(response => this.unitOfWork.userRepo.all(true).subscribe(users => this.users = users));
                }
            });
        }
    }

    new() {
        this.router.navigate(['/sysadmin/user']);
    }
}