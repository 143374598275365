﻿import { Component, OnInit } from '@angular/core';
import { Predicate } from 'breeze-client';
import { AuthService } from './core/auth.service';
import { VaderoTankUnitOfWorkService } from './core/vadero-tank-unit-of-work.service';
import { User } from './core/entities/entity-model';

@Component({
        selector: 'main-menu',
    templateUrl: 'main-menu.component.html'
})
export class MainMenuComponent implements OnInit
{
    constructor(private authService: AuthService) {}

    ngOnInit() {

    }

    get authorized(): boolean {
        return this.authService.authentication.isAuth;
    }

    hasRole(role: string): boolean {
        return this.authService.hasRole(role);
    }
}
