﻿<h3>System administration</h3>
<hr />
<ul class="list-menu">
    <li>
        <a [ngClass]="{ 'active': routeIsActive('sysadmin/users') || routeIsActive('sysadmin/user') }" routerLink="/sysadmin/users">User administration</a>
        <ul class="sub-menu" *ngIf="routeIsActive('sysadmin/user')">
            <li *ngIf="!hasId">
                <span>New user</span>
            </li>
            <li *ngIf="hasId">
                <span>Edit user</span>
            </li>
        </ul>
    </li>
    <li>
        <a [ngClass]="{ 'active': routeIsActive('sysadmin/crewtypes') || routeIsActive('sysadmin/crewtype') }" routerLink="/sysadmin/crewtypes">Crew types</a>
        <ul class="sub-menu" *ngIf="routeIsActive('sysadmin/crewtype')">
            <li *ngIf="!hasId">
                <span>New crew type</span>
            </li>
            <li *ngIf="hasId">
                <span>Edit crew type</span>
            </li>
        </ul>
    </li>
    <li>
        <a [ngClass]="{ 'active': routeIsActive('sysadmin/crewgroups') || routeIsActive('sysadmin/crewgroup') }" routerLink="/sysadmin/crewgroups">Crew groups</a>
        <ul class="sub-menu" *ngIf="routeIsActive('sysadmin/crewgroup')">
            <li *ngIf="!hasId">
                <span>New crew group</span>
            </li>
            <li *ngIf="hasId">
                <span>Edit crew group</span>
            </li>
        </ul>
    </li>
    <li>
        <a [ngClass]="{ 'active': routeIsActive('sysadmin/vessels') || routeIsActive('sysadmin/vessel') }" routerLink="/sysadmin/vessels">Vessels</a>
        <ul class="sub-menu" *ngIf="routeIsActive('sysadmin/vessel')">
            <li *ngIf="!hasId">
                <span>New vessel</span>
            </li>
            <li *ngIf="hasId">
                <span>Edit vessel</span>
            </li>
        </ul>
    </li>
    <li>
        <a [ngClass]="{ 'active': routeIsActive('sysadmin/courses') || routeIsActive('sysadmin/course') }" routerLink="/sysadmin/courses">Training plans</a>
        <ul class="sub-menu" *ngIf="routeIsActive('sysadmin/course')">
            <li *ngIf="!hasId">
                <span>New training plan</span>
            </li>
            <li *ngIf="hasId">
                <span>Edit training plan</span>
            </li>
        </ul>
    </li>
    <li>
        <a [ngClass]="{ 'active': routeIsActive('sysadmin/courseresults') || routeIsActive('sysadmin/courseresults') }" routerLink="/sysadmin/courseresults">Training plans, result codes</a>
        <ul class="sub-menu" *ngIf="routeIsActive('sysadmin/courseresult')">
            <li *ngIf="!hasId">
                <span>New result code</span>
            </li>
            <li *ngIf="hasId">
                <span>Edit result code</span>
            </li>
        </ul>
    </li>
    <li>
        <a [ngClass]="{ 'active': routeIsActive('sysadmin/salarydetails') || routeIsActive('sysadmin/salarydetails') }" routerLink="/sysadmin/salarydetails">Salary details</a>
    </li>
</ul>
