﻿import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let permittedRoles = route.data['roles'] as string[];

        if (!this.authService.authentication.isAuth)
            return false;

        if (permittedRoles.length > 0) {
            if (!permittedRoles.some(r => this.authService.hasRole(r))) {
                return false;
            }
        }

        return true;
    }
}