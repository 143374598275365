
import {from as observableFrom,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Entity, FetchStrategySymbol, EntityManager, FetchStrategy, EntityType, EntityQuery, Predicate } from 'breeze-client';

export interface IRepository<T extends Entity> {
    withId(key: any): Observable<T>;
    where(predicate: Predicate): Observable<T[]>;
    whereInCache(predicate: Predicate): T[];
    all(fromCache?: boolean): Observable<T[]>;
}

export class Repository<T extends Entity> implements IRepository<T> {

    private _resourceNameSet: boolean;
    protected _defaultFetchStrategy: FetchStrategySymbol;

    constructor(private _manager: EntityManager,
        protected _entityTypeName: string,
        protected _resourceName: string,
        protected _isCachedBundle: boolean = false) {

        this._defaultFetchStrategy = _isCachedBundle ? FetchStrategy.FromLocalCache : FetchStrategy.FromServer;
    }

    protected get manager(): EntityManager {
        if (this._resourceNameSet) return this._manager;
        let metadataStore = this._manager.metadataStore;

        let entityType = <EntityType>metadataStore.getEntityType(this._entityTypeName || '', true);
        if (entityType) {
            entityType.setProperties({ defaultResourceName: this.localResourceName });
            metadataStore.setEntityTypeForResourceName(this.localResourceName, entityType);
        }

        return this._manager;
    }

    protected get localResourceName() {
        return this._isCachedBundle ? this._entityTypeName : this._resourceName;
    }

    withId(key: any): Observable<T> {
        if (!this._entityTypeName)
            throw new Error("Repository must be created with an entity type specified");

        return observableFrom(this.manager.fetchEntityByKey(this._entityTypeName, key, true)).pipe(
            catchError((err, caught) => {
                if (err.status == 404)
                    return caught;

                throw err;
            }),
            map(data => {
                var test = 'test';
                return <T><any>data.entity;
            }),);
    };

    where(predicate: Predicate): Observable<T[]> {
        let query = this.baseQuery().where(predicate);

        return observableFrom(this.executeQuery(query));
    };

    whereInCache(predicate: Predicate): T[] {
        let query = this.baseQuery().where(predicate);

        return <any[]>this.executeCacheQuery(query);
    };

    all(fromCache: boolean = false): Observable<T[]> {
        let query = this.baseQuery();
        let promise = new Promise<T[]>((resolve, reject) => resolve(<T[]>this.executeCacheQuery(query)));
        if (fromCache)
            return observableFrom(promise);
        else
            return observableFrom(this.executeQuery(query));
    };

    protected baseQuery(): EntityQuery {
        return EntityQuery.from(this.localResourceName);
    }

    protected executeQuery(query: EntityQuery, fetchStrategy?: FetchStrategySymbol): Promise<T[]> {
        let q = query.using(fetchStrategy || this._defaultFetchStrategy);
        return this.manager.executeQuery(q).then(data => {
            return data.results as T[];
        }).catch(e => {
            if (e.status == 404) {
                return [];
            }

            // Something else happend, rethrow the exception
            throw e;
        });
    }

    protected executeCacheQuery(query: EntityQuery): Entity[] {
        return this.manager.executeQueryLocally(query);
    }
}
