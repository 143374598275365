﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/auth.service';

@Component(
    {
        selector: 'logout-component',
        template: ''
    }
)
export class LogoutComponent implements OnInit
{
    constructor(private authService: AuthService, private router: Router){}

    ngOnInit(): void
    {
        this.authService.logOut();
        this.router.navigateByUrl('/login');
    }
}