import { throwError as observableThrowError, Observable } from 'rxjs';
import { flatMap, map, catchError, shareReplay } from 'rxjs/operators';

import { Injectable, Inject } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { IAuthentication, IRegistrationRequest, ILoginData, IAuthData, ILoginResponse } from '../auth.interface';
import { IChangePasswordRequest } from './entities/changepasswordrequest';
import { Predicate } from 'breeze-client';
import { User } from './entities/entity-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  public authentication: IAuthentication = { isAuth: false, user: null };
  private apiBaseUrl: string;
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.apiBaseUrl = environment.apiBase;
  }

  saveRegistration(registration: IRegistrationRequest): Observable<any> {
    this.logOut();
    return this.http.post(this.apiBaseUrl + 'api/account/register', registration);
  }

  login(loginData: ILoginData): Observable<any> {
    let data = 'grant_type=password&username=' + loginData.userName + '&password=' + loginData.password;
    let token: string = null;
    return this.http.post(this.apiBaseUrl + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .pipe(
        flatMap((response: ILoginResponse) => {
          token = response.access_token;
          this.localStorageService.set('authorizationData', { token: token, user: null });
          return this.http.get(this.apiBaseUrl + 'api/Users/Me');
        }),
        map((r: User) => {
          this.localStorageService.set('authorizationData', { token: token, user: r });
          this.authentication.isAuth = true;
          this.authentication.user = r;
        }),
        catchError(error => {
          this.logOut();
          return observableThrowError(error || 'Server error');
        })
      );
  }

  hasRole(role: string) {
    if (this.authentication.isAuth) {
      if (!this.authentication.user) {
        this.logOut();
        return;
      }

      return this.authentication.user.vTUserRoles.indexOf(role) != -1;
    }

    return false;
  }

  changePassword(request: IChangePasswordRequest): Observable<any> {
    return this.http.put(this.apiBaseUrl + 'api/Users/ChangePassword', request);
  }

  logOut() {
    this.localStorageService.remove('authorizationData');
    this.authentication.isAuth = false;
    this.authentication.user = null;
  }

  public fillAuthData() {
    let authData: IAuthData = <IAuthData>this.localStorageService.get('authorizationData');
    if (authData) {
      this.authentication.isAuth = true;
      this.authentication.user = authData.user;
    }
  }
}
