import { EntityManager, Entity, SaveOptions, EntityChangedEventArgs, EntityState } from 'breeze-client';
import { Subject } from 'rxjs';
import { EntityManagerProvider } from './entity-manager-provider.service';
import { Repository } from './repository';
export class EntityFactory {
    constructor(_type, _manager) {
        this._type = _type;
        this._manager = _manager;
    }
    create(config) {
        let entityTypeName = this._type.name;
        let inst = this._manager.createEntity(entityTypeName, config, EntityState.Added);
        // OLD version - did not allow config.
        // let inst = new this.type();
        // this.entityManagerProvider.manager().addEntity(inst);
        return inst;
    }
}
export class SavedOrRejectedArgs {
}
export class UnitOfWork {
    constructor(_emProvider) {
        this._emProvider = _emProvider;
        this.entityChangedSubject = new Subject();
    }
    get manager() {
        if (!this._manager) {
            this._manager = this._emProvider.newManager();
            this._manager.entityChanged.subscribe(args => {
                this.entityChangedSubject.next(args);
            });
        }
        return this._manager;
    }
    get entityChanged() {
        return this.entityChangedSubject.asObservable();
    }
    static get savedOrRejected() {
        return UnitOfWork.savedOrRejectedSubject.asObservable();
    }
    hasChanges() {
        return this.manager.hasChanges();
    }
    getChanges() {
        return this.manager.getChanges();
    }
    commit() {
        let saveOptions = new SaveOptions({ resourceName: 'savechanges' });
        return this.manager.saveChanges(null, saveOptions)
            .then((saveResult) => {
            UnitOfWork.savedOrRejectedSubject.next({
                entities: saveResult.entities,
                rejected: false
            });
            return saveResult.entities;
        });
    }
    rollback() {
        let pendingChanges = this.manager.getChanges();
        this.manager.rejectChanges();
        UnitOfWork.savedOrRejectedSubject.next({
            entities: pendingChanges,
            rejected: true
        });
    }
    clear() {
        this._emProvider.reset(this.manager);
    }
    shelve(key, clear = false) {
        let data = this.manager.exportEntities(null, { asString: false, includeMetadata: false });
        UnitOfWork.shelveSets[key] = data;
        if (clear) {
            this._emProvider.reset(this.manager);
        }
    }
    unshelve(key, clear = true) {
        let data = UnitOfWork.shelveSets[key];
        if (!data) {
            return false;
        }
        if (clear) {
            // Clear the entity manager and don't bother importing lookup data from masterManager.
            this.manager.clear();
        }
        this.manager.importEntities(data);
        // Delete the shelveSet
        delete UnitOfWork.shelveSets[key];
        return true;
    }
    static deleteShelveSet(key) {
        delete UnitOfWork.shelveSets[key];
    }
    createRepository(entityTypeName, resourceName, isCached = false) {
        return new Repository(this.manager, entityTypeName, resourceName, isCached);
    }
    createFactory(type) {
        return new EntityFactory(type, this.manager);
    }
}
UnitOfWork.shelveSets = {};
UnitOfWork.savedOrRejectedSubject = new Subject();
