﻿<form [formGroup]="loginForm" (ngSubmit)="submit()">
    <modal-window [title]="'Log in'" [modal]="false" [show]="true">
        <div class="vt-modal-body">
            <div class="form-horizontal">
                <span class="text-danger"></span>
                <div class="form-group">
                    <label for="usernameTB" class="control-label col-sm-2">Username</label>
                    <div class="col-sm-10">
                        <input id="usernameTB" type="text" class="form-control" [ngClass]="{ 'invalid-control': !loginForm.controls['userName'].valid && loginForm.controls['userName'].touched }" [formControl]="loginForm.controls['userName']" />
                        <div *ngIf="!loginForm.controls['userName'].valid && loginForm.controls['userName'].touched" class="alert alert-danger">Username required!</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="passwordTB" class="control-label col-sm-2">Password</label>
                    <div class="col-sm-10">
                        <input type="password" class="form-control" [ngClass]="{ 'invalid-control': !loginForm.controls['password'].valid && loginForm.controls['password'].touched }" id="passwordTB" [formControl]="loginForm.controls['password']" />
                        <div *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched" class="alert alert-danger">Password required!</div>
                        <div *ngIf="loginForm.controls['password'].hasError('minlength') && loginForm.controls['password'].touched" class="alert alert-danger">Password must be at least 4 characters!</div>
                        <div *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched" class="alert alert-danger">Password can be at most 10 characters!</div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <input id="rememberMeCB" type="checkbox" [formControl]="loginForm.controls['rememberMe']" />
                            <label for="rememberMeCB">Remember me</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vt-modal-footer">
            <div class="clearfix">
                <button type="submit" class="btn btn-lg btn-info btn-block">Sign in</button>
            </div>
        </div>
    </modal-window>
</form>