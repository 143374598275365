<div class="row">
    <div class="col-sm-6">
        <label for="vesselDD">Select a vessel:&nbsp;</label>
        <select id="vesselDD" [ngModel]="selectedVesselId" (ngModelChange)="updateSelectedVessel($event)" class="form-control">
            <option value="">Select a vessel</option>
            <option *ngFor="let vessel of vessels" [value]="vessel.id">{{vessel.name}}</option>
        </select>
    </div>
    <div class="col-sm-6">
        <label>Select a date:&nbsp;</label>
        <my-date-picker locale="sv" [selDate]="getDateString()" [options]="datePickerConfig" (dateChanged)="updateDate($event)"></my-date-picker>
    </div>
</div>
<hr />
<div *ngIf="selectedVessel">
    <h4>{{selectedVessel.name}}</h4>
    <button type="button" class="btn btn-primary" style="margin-bottom: 5px;" (click)="addCrew()">Add crew&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
    <button type="button" class="btn btn-default" style="margin: 0 0 5px 5px;" (click)="searchCrew()">Search crew&nbsp;<span class="glyphicon glyphicon-search"></span></button>
    <button type="button" class="btn btn-default" style="margin: 0 0 5px 5px;" (click)="printSchedule()">Print &nbsp;<span class="glyphicon glyphicon-print"></span></button>
    <div class="clearfix">
        <button type="button" class="btn btn-default pull-left" (click)="addDays(-30)">&lt;&lt;-30</button>
        <button type="button" class="btn btn-default pull-left" (click)="addDays(-10)">&lt;-10</button>
        <button type="button" class="btn btn-default pull-right" (click)="addDays(30)">&gt;&gt;+30</button>
        <button type="button" class="btn btn-default pull-right" (click)="addDays(10)">&gt;+10</button>
    </div>
    <table class="calendar-table">
        <thead>
            <tr>
                <th></th>
                <th></th>
                <th class="year-header" *ngFor="let year of yearsArray" [attr.colspan]="year.numColumns">{{year.name}}</th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th class="month-header" *ngFor="let month of monthsArray" [attr.colspan]="month.numColumns">{{month.name}}</th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th class="week-header" *ngFor="let week of weeksArray" [attr.colspan]="week.numColumns">{{week.name}}</th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th *ngFor="let date of datesArray" [class]="getHeaderClass(date)"><span>{{date.date()}}</span><br /><i>&nbsp;{{getDayOfWeek(date)}}</i></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
                <th *ngIf="hasRole('SchedulesPlan')" class="icon-col"></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let turn of turns; let i = index;">
                <tr class="calendar-row">
                    <td class="employee-cell" nowrap>
                        <a [title]="turn?.employee?.lastName + ', ' + turn?.employee?.firstName" routerLink="{{'/crew/' + turn?.employee?.employeeNr}}">{{turn?.employee?.lastName + ', ' + turn?.employee?.firstName.substr(0, 1)}}</a>
                    </td>
                    <td class="employee-cell" nowrap>
                        <b>{{turn?.crewType?.name}}</b>
                    </td>
                    <td *ngFor="let date of datesArray" [class]="getCellClass(turn, date)"><div class="content"><b>{{getWeekNumberString(turn, date)}}</b><br /></div></td>
                    <td *ngIf="hasRole('SchedulesPlan')" class="icon-col">
                        <button type="button" *ngIf="turn?.status === 'Confirmed'" class="btn btn-link btn-danger" (click)="removeTurn(turn)" title="Remove service"><span class="glyphicon glyphicon-remove text-danger"></span></button>
                        <button type="button" *ngIf="turn?.status === 'Planned'" class="btn btn-link btn-danger" (click)="confirmTurn(turn)" title="Confirm service"><span class="glyphicon glyphicon-ok"></span></button>
                    </td>
                    <td *ngIf="hasRole('SchedulesPlan')" class="icon-col">
                        <button *ngIf="turn?.status === 'Confirmed'" type="button" class="btn btn-link btn-info" (click)="editTurn(turn)" title="Edit service"><span class="glyphicon glyphicon-pencil text-info"></span></button>
                    </td>
                    <td *ngIf="hasRole('SchedulesPlan')" class="icon-col">
                        <button *ngIf="turn?.status === 'Confirmed'" type="button" class="btn btn-link btn-successful" (click)="addTurn(turn.employee)" title="Add new service"><span class="glyphicon glyphicon-plus text-info"></span></button>
                    </td>
                </tr>
                <tr [class]="getRowSpacerClass(turn, turns[i+1])">
                    <td></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <a class="btn btn-primary" role="button" [href]="getExportLink()"><span style="color:white">Export schedule&nbsp;<span class="glyphicon glyphicon-floppy-save"></span></span></a>
</div>
<vt-turn-edit [turn]="turnToEdit" [vessel]="selectedVessel" [employees]="employees" [turns]="turnsForVessel" [crewTypes]="crewTypes" (save)="saveTurn($event)" (cancel)="cancelTurn()"></vt-turn-edit>
<vt-search-crew [show]="showSearch" [employees]="employees" [crewTypes]="crewTypes" (done)="closeSearch()"></vt-search-crew>
<select2-fix></select2-fix>
