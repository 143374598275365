import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { User } from '../core/entities/entity-model';
import { MessageService } from '../core/message.service';
import { AuthService } from '../core/auth.service';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';

@Component({
        selector: 'user',
    templateUrl: 'user.component.html'
})
export class UserComponent implements OnInit {
    user: User = new User();
    userId: string;
    userForm: FormGroup;
    roles: string[] = ['System', 'CrewRead', 'CrewWrite', 'SchedulesPlan', 'SchedulesRead'];
    newPassword: string;

    constructor(private formBuilder: FormBuilder, private unitOfWork: VaderoTankUnitOfWorkService
        , private route: ActivatedRoute, private router: Router
        , private messageService: MessageService, private authService: AuthService) {
        this.userForm = this.formBuilder.group({
            'name': [null, Validators.required],
            'userName': [null, Validators.required],
            'password': [null, Validators.compose([Validators.minLength(4), Validators.maxLength(16)])]
        });
    }

    ngOnInit() {
        this.route.params
            .subscribe(params => {
                if (params['id']) {
                    this.unitOfWork.userRepo.withId(params['id'])
                        .subscribe(user => {
                            this.user = user;
                        });
                }
                else {
                    this.user = this.unitOfWork.createUserFactory().create();
                }
            });
    }

    roleSelected(role: string): boolean {
        if (!this.user || !this.user.vTUserRoles)
            return false;
        switch (role) {
            case 'System':
                return this.user.vTUserRoles.indexOf('System') != -1;
            case 'CrewRead':
                return this.user.vTUserRoles.indexOf('CrewRead') != -1;
            case 'CrewWrite':
                return this.user.vTUserRoles.indexOf('CrewWrite') != -1;
            case 'SchedulesPlan':
                return this.user.vTUserRoles.indexOf('SchedulesPlan') != -1;
            case 'SchedulesRead':
                return this.user.vTUserRoles.indexOf('SchedulesRead') != -1;
            default: return false;
        }
    }

    updateRoles(role: string, event: any): void {
        let checked = event.target.checked;
        switch (role) {
            case 'System':
                if (checked)
                    this.user.vTUserRoles += (this.user.vTUserRoles ? ', ' : '') + 'System';
                else
                    this.user.vTUserRoles = this.user.vTUserRoles.replace('System, ', '').replace('System', '');
                break;
            case 'CrewRead':
                if (checked)
                    this.user.vTUserRoles += (this.user.vTUserRoles ? ', ' : '') + 'CrewRead';
                else
                    this.user.vTUserRoles = this.user.vTUserRoles.replace('CrewRead, ', '').replace('CrewRead', '');
                break;
            case 'CrewWrite':
                if (checked)
                    this.user.vTUserRoles += (this.user.vTUserRoles ? ', ' : '') + 'CrewWrite';
                else
                    this.user.vTUserRoles = this.user.vTUserRoles.replace('CrewWrite, ', '').replace('CrewWrite', '');
                break;
            case 'SchedulesPlan':
                if (checked)
                    this.user.vTUserRoles += (this.user.vTUserRoles ? ', ' : '') + 'SchedulesPlan';
                else
                    this.user.vTUserRoles = this.user.vTUserRoles.replace('SchedulesPlan, ', '').replace('SchedulesPlan', '');
                break;
            case 'SchedulesRead':
                if (checked)
                    this.user.vTUserRoles += (this.user.vTUserRoles ? ', ' : '') + 'SchedulesRead';
                else
                    this.user.vTUserRoles = this.user.vTUserRoles.replace('SchedulesRead, ', '').replace('SchedulesRead', '');
                break;
            default: break;
        }
    }

    saveChanges() {
        this.unitOfWork.commit().catch(
            error => {
                this.messageService.displayMessage('Error', 'Error saving user');
            }
        ).then(response => {
            if (this.newPassword) {
                this.authService.changePassword({ id: this.user.id, password: this.newPassword }).toPromise().then(x => this.router.navigate(['/sysadmin/users']));
            }
            else {
                this.router.navigate(['/sysadmin/users']);
            }
            });
    }

    cancel() {
        this.unitOfWork.rollback();
        this.router.navigate(['/sysadmin/users']);
    }
}
