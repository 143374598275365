﻿import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';
import { SelectModule } from 'ng2-select';

import { DataModule } from '../data/data.module';
import { SharedModule } from '../shared/shared.module';

import { ScheduleComponent } from './schedule.component';
import { SearchCrewComponent } from './search-crew.component';
import { Select2FixComponent } from './select2-fix.component';

@NgModule({
    imports: [
        BrowserModule,
        DataModule,
        FormsModule,
        MyDatePickerModule,
        NgbModule,
        NgbTabsetModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        SelectModule
    ],
    exports: [],
    declarations: [
        ScheduleComponent,
        SearchCrewComponent,
        Select2FixComponent
    ],
    providers: []
})
export class ScheduleModule {}