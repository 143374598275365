﻿<modal-window [title]="'Search crew'" [large]="true" [modal]="true" [show]="show">
    <div class="vt-modal-body">
        <div class="form-inline">
            <div class="form-group" style="width: 300px;">
                <label>Date:</label>
                <my-date-picker locale="sv" [selDate]="getDateString()" (dateChanged)="updateDate($event)"></my-date-picker>
            </div>
            <div class="form-group">
                <label>Crew type:</label>
                <select class="form-control" [ngModel]="selectedCrewTypeId" (ngModelChange)="updateCrewType($event)">
                    <option *ngFor="let crewType of crewTypes" [value]="crewType.id">{{crewType.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Status:</label>
                <select [ngModel]="selectedStatus" (ngModelChange)="updateStatus($event)" class="form-control">
                    <option value="*" selected>All</option>
                    <option value="Active">Active</option>
                    <option value="InActive">Inactive</option>
                    <option value="Applicant">Applicant</option>
                </select>
            </div>
        </div>
        <div style="max-height: 300px; overflow-y: scroll;">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>
                            Surname
                        </th>
                        <th>
                            Given name
                        </th>
                        <th>
                            Emp. nr
                        </th>
                        <th>
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let employee of foundEmployees">
                        <td>{{employee.lastName}}</td>
                        <td>{{employee.firstName}}</td>
                        <td>{{employee.employeeNr}}</td>
                        <td>{{employee.active}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="vt-modal-footer">
        <div class="clearfix">
            <button type="button" class="btn btn-primary pull-right" (click)="doneFn()">Ok</button>
        </div>
    </div>
</modal-window>
