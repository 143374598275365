
import {zip as observableZip,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntityState, Predicate } from 'breeze-client';
import * as _ from 'lodash';

import { CrewGroup, CrewType, Course } from '../core/entities/entity-model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';

@Component({
    selector: 'crewgroups',
    template: `<h3>Crew group administration</h3>
               <hr />
               <button class="btn btn-primary pull-right" (click)="new()">Add new&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
               <list-table [list]="groups" [headers]="displayFields" [type]="type" (delete)="delete($event)"></list-table>`
})
export class CrewGroupsComponent implements OnInit
{
    type: string = 'sysadmin.crewgroup';
    displayFields: string[] = ['Name'];
    groups: CrewGroup[];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, private router: Router, private messageService: MessageService) { }

    ngOnInit() {
        this.unitOfWork.crewGroupRepo.all(true).subscribe(cachedGroups => {
            if (cachedGroups.length === 0 || cachedGroups.length === 1)
                this.unitOfWork.crewGroupRepo.all().subscribe(dbGroups => this.groups = dbGroups);
            else
                this.groups = cachedGroups;
        });
    }

    edit(crewGroup: CrewGroup) {
        this.router.navigate(['/sysadmin/crewgroup', crewGroup.id]);
    }

    delete(crewGroup: CrewGroup) {
        let courseObservable = this.unitOfWork.courseRepo.where(new Predicate('crewGroupId', '==', crewGroup.id));
        let crewTypeObservable = this.unitOfWork.crewTypeRepo.where(new Predicate('crewGroupId', '==', crewGroup.id));
        observableZip(courseObservable, crewTypeObservable).subscribe(results => {
            let dependentCourses = <Course[]>results[0];
            let dependentCrewTypes = <CrewType[]>results[1];

            let okToDelete = dependentCourses.length == 0 && dependentCrewTypes.length == 0;

            if (!okToDelete) {
                let dependentCoursesString = '';
                if (dependentCourses.length > 0) {
                    dependentCoursesString = '<b>Courses</b><ul>' + _.map(dependentCourses, c => '<li>' + c.name + '</li>').join('') + '</ul>';
                }

                let dependentCrewTypesString = '';
                if (dependentCrewTypes.length > 0) {
                    dependentCrewTypesString = '<b>Crew types</b><ul>' + _.map(dependentCrewTypes, c => '<li>' + c.name + '</li>').join('') + '</ul>';
                }

                let errorMessage = 'Error. Failed to delete crew group. The following objects reference this group:<br/>' + dependentCoursesString + dependentCrewTypesString;

                this.messageService.displayMessage('Can not delete crew group', errorMessage);
            }
            else {
                this.messageService.displayConfirm('Delete crew group?', 'Really delete crew group ' + crewGroup.name + '?', (yesNo: boolean) => {
                    if (yesNo) {
                        crewGroup.entityAspect.entityState = EntityState.Deleted;
                        this.unitOfWork.commit().then(response => this.unitOfWork.crewGroupRepo.all(true).subscribe(groups => this.groups = groups));
                    }
                });
            }
        });
    }

    new() {
        this.router.navigate(['/sysadmin/crewgroup']);
    }
}