﻿import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { EntityState } from 'breeze-client';
import * as moment from 'moment';
import * as _ from 'lodash';

import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { Turn, Vessel, CrewType, Employee } from '../core/entities/entity-model';
import { TurnDocumentFlag } from '../core/entities/enums.model';

@Component({
        selector: 'turn-checks',
    templateUrl: 'turn-checks.component.html'
})
export class TurnChecksComponent implements OnInit {
    @Input() turn: Turn;
    turnForm: FormGroup;

    constructor(formBuilder: FormBuilder, private unitOfWork: VaderoTankUnitOfWorkService) {
        this.turnForm = formBuilder.group({
            'cOE': [null],
            'visa': [null],
            'lOG': [null]
        });
    }

    ngOnInit() {
    }

    getFormattedDate(input: Date | moment.Moment): string {
        if (!input)
            return '';

        var val = moment(input);
        if (val.year && val.year() < 1900)
            return 'Invalid date';

        return val.format("YYYY-MM-DD");
    }

    turnRequires(crewType: CrewType, check: string): boolean {
        if (!this.turn)
            return false;

        return this.turn.turnRequires(crewType, check);
    }
    coeCheckOk(employee: Employee, signOff: Date): boolean { return this.turn.coeCheckOk(employee, signOff); }
    healthCheckOk(employee: Employee, signOff: Date): boolean { return this.turn.healthCheckOk(employee, signOff); }
    cocCheckOk(employee: Employee, signOff: Date): boolean { return this.turn.cocCheckOk(employee, signOff); }
    tankOilCheckOk(employee: Employee, signOff: Date): boolean { return this.turn.tankOilCheckOk(employee, signOff); }
    tankChemCheckOk(employee: Employee, signOff: Date): boolean { return this.turn.tankChemCheckOk(employee, signOff); }
    gocCheckOk(employee: Employee, signOff: Date): boolean { return this.turn.gocCheckOk(employee, signOff); }
    visaCheckOk(turn: Turn): boolean { return this.turn.visaCheckOk(turn); }
    logCheckOk(turn: Turn): boolean { return this.turn.logCheckOk(turn); }
}
