﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SelectModule } from 'ng2-select';
import { MyDatePickerModule } from 'mydatepicker';
import {
    CamelCasePipe, ConfirmComponent, FileUploadComponent, ListTableComponent, MessageCenterComponent
    , MessageBoxComponent, ModalComponent, TurnComponent, TurnChecksComponent
} from './main';
import { FileDropDirective, FileSelectDirective, FileUploadModule } from 'ng2-file-upload';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MyDatePickerModule,
        FileUploadModule,
        RouterModule,
        SelectModule
    ],
    exports: [
        CamelCasePipe,
        ConfirmComponent,
        FileUploadComponent,
        ListTableComponent,
        MessageCenterComponent,
        MessageBoxComponent,
        ModalComponent,
        TurnComponent,
        TurnChecksComponent
    ],
    declarations: [
        CamelCasePipe,
        ConfirmComponent,
        //FileDropDirective,
        //FileSelectDirective,
        FileUploadComponent,
        ListTableComponent,
        MessageCenterComponent,
        MessageBoxComponent,
        ModalComponent,
        TurnComponent,
        TurnChecksComponent
    ],
    providers: []
})
export class SharedModule {}