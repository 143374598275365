﻿import { EntityBase, TrainingPlan } from './entity-model';
import { core } from 'breeze-client';

export class CourseResult extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: CourseResult) { }

    id: string;
    code: string;
    text: string;
    color: string;
    trainingPlans: TrainingPlan[];
}