﻿import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { DataModule } from '../data/data.module';
import { SharedModule } from '../shared/shared.module';

import { DashboardComponent } from './dashboard.component';
import { DashboardDocumentsComponent } from './dashboard-documents.component';
import { DashboardUpcomingRelievesComponent } from './dashboard-upcoming-relieves.component';

@NgModule({
    imports: [
        BrowserModule,
        DataModule,
        RouterModule,
        SharedModule
    ],
    exports: [],
    declarations: [
        DashboardComponent,
        DashboardDocumentsComponent,
        DashboardUpcomingRelievesComponent
    ],
    providers: [
    ]
})
export class DashboardModule { }