﻿import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelcase'
})
export class CamelCasePipe implements PipeTransform {
    transform(input: string): string {
        if (typeof input !== 'string' || !input) {
            return input;
        }
        if (input.length === 1)
            return input.toLowerCase();

        return input[0].toLowerCase() + input.substr(1).replace(' ', '');
    }
}