﻿<h3>Edit crew type</h3>
<hr />
<form (ngSubmit)="saveChanges()" [formGroup]="crewTypeForm">
    <div class="form-group">
        <label for="NameTB">Name</label>
        <input id="NameTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('name') }"
               [(ngModel)]="crewType.name" [formControl]="crewTypeForm.controls['name']" />
        <div *ngIf="isInvalid('name')" class="alert alert-danger">Name required!</div>
    </div>
    <div class="form-group">
        <label for="RankingTB">Ranking</label>
        <input id="RankingTB" type="number" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('ranking') }"
               [(ngModel)]="crewType.ranking" [formControl]="crewTypeForm.controls['ranking']" />
        <div *ngIf="isInvalid('ranking')" class="alert alert-danger">Ranking required!</div>
    </div>
    <div class="form-group">
        <label for="CrewGroupDD">Crew group</label>
        <select id="CrewGroupDD" class="form-control" [ngClass]="{ 'invalid-control': isInvalid('group') }"
                [(ngModel)]="crewType.crewGroupId" [formControl]="crewTypeForm.controls['group']">
            <option value="">Select crew group</option>
            <option *ngFor="let group of crewGroups" [value]="group.id">{{group.name}}</option>
        </select>
        <div *ngIf="isInvalid('group')" class="alert alert-danger">Crew group required!</div>
    </div>
    <div class="form-group">
        <label>Required endorsements</label>
        <div class="checkbox">
            <label>
                <input type="checkbox" [(ngModel)]="crewType.endorsementCOC" [formControl]="crewTypeForm.controls['endorsementCOC']">
                COC
            </label>
        </div>
        <div class="checkbox">
            <label>
                <input type="checkbox" [(ngModel)]="crewType.endorsementTankOil" [formControl]="crewTypeForm.controls['endorsementTankOil']">
                Tankerman Oil
            </label>
        </div>
        <div class="checkbox">
            <label>
                <input type="checkbox"  [(ngModel)]="crewType.endorsementTankChem" [formControl]="crewTypeForm.controls['endorsementTankChem']">
                Tankerman Chem
            </label>
        </div>
        <div class="checkbox">
            <label>
                <input type="checkbox"  [(ngModel)]="crewType.endorsementGOC" [formControl]="crewTypeForm.controls['endorsementGOC']">
                GOC/GMDSS
            </label>
        </div>
    </div>

    <button class="btn btn-primary" type="submit" [disabled]="!crewTypeForm.valid">Save</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
</form>