﻿import { EntityBase, Course, File } from './entity-model';
import { core } from 'breeze-client';

export class CourseFile extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: CourseFile) { }

    id: string;
    courseId: string;
    fileId: string;
    course: Course;
    file: File;
}