﻿import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'confirm',
    template: `<modal-window [title]="title" [modal]="true" [show]="show">
                    <div class="vt-modal-body">
                        <p>{{message}}</p>
                    </div>
                    <div class="vt-modal-footer">
                        <button class="btn btn-lg btn-info btn-block" (click)="confirm(true)">Yes</button>
                        <button class="btn btn-lg btn-danger btn-block" (click)="confirm(false)">No</button>
                    </div>
                </modal-window>`
})
export class ConfirmComponent {
    @Input() show: boolean;
    @Input() title: string;
    @Input() message: string;
    @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

    public confirm(yesNo: boolean) {
        this.confirmed.emit(yesNo);
    }
}
