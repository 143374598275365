﻿import { EntityBase, ScheduleStatus, CrewType, Vessel, Employee, Turn } from './entity-model';
import { core } from 'breeze-client';

export class Schedule extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(enums: Schedule) {}

    crewTypeId: string;
    employeeId: string;
    id: string;
    signOff: Date;
    signOn: Date;
    status: ScheduleStatus;
    vesselId: string;
    turns: Turn[];
    employee: Employee;
    vessel: Vessel;
    crewType: CrewType;
}