﻿<h3>Edit user</h3>
<hr />
<form (ngSubmit)="saveChanges()" [formGroup]="userForm">
    <div class="form-group">
        <label for="NameTB">Name</label>
        <input id="NameTB" type="text" class="form-control"
               [(ngModel)]="user.name" [formControl]="userForm.controls['name']" />
        <div *ngIf="!userForm.controls['name'].valid && userForm.controls['name'].touched" class="alert alert-danger">Name required!</div>
    </div>
    <div class="form-group">
        <label for="UserNameTB">Username</label>
        <input id="UserNameTB" type="text" class="form-control" [(ngModel)]="user.userName" formControlName="userName" />
        <div *ngIf="userForm.controls['userName'].hasError('required') && userForm.controls['userName'].touched" class="alert alert-danger">Username required!</div>
        <div *ngIf="userForm.controls['userName'].hasError('duplicate') && userForm.controls['userName'].touched" class="alert alert-danger">Username already exists!</div>
    </div>
    <div class="form-group">
        <i>(Only fill this in if you are changing the password)</i><br />
        <label for="passwordTB">Password</label>
        <input type="password" class="form-control" [(ngModel)]="newPassword" formControlName="password" />
        <div *ngIf="userForm.controls['password'].hasError('minLength') && userForm.controls['password'].touched" class="alert alert-danger">Password must be at least 4 characters!</div>
        <div *ngIf="userForm.controls['password'].hasError('maxLength') && userForm.controls['password'].touched" class="alert alert-danger">Password can be at most 10 characters!</div>
    </div>
    <div class="form-group">
        <div *ngFor="let role of roles" class="checkbox">
            <label>
                <input type="checkbox" name="roles" value="{{role}}" [disabled]="authService.authentication.user.id === user.id && role === 'System'" [checked]="roleSelected(role)" (change)="updateRoles(role, $event)" />
                {{role}}
            </label>
        </div>
    </div>

    <button class="btn btn-primary" type="submit" [disabled]="!userForm.valid">Save</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
</form>