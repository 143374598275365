﻿import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthService } from './auth.service';
import { VaderoTankUnitOfWorkService } from './vadero-tank-unit-of-work.service';
import { LocalStorageModule, LocalStorageService } from 'angular-2-local-storage';
import { BusyService } from './busy.service';
import { MessageService } from './message.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from './auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './interceptors';

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    NgbModule,
  ],
  providers: [
    AuthGuard,
    AuthService,
    BusyService,
    VaderoTankUnitOfWorkService,
    MessageService,

    httpInterceptorProviders
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
