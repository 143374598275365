﻿<div class="outerWrapper" [class.dark]="modal" [class.large]="large" style="z-index: 2000;" *ngIf="show">
    <div class="middleWrapper">
        <div class="innerWrapper">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <span class="panelIcon glyphicon glyphicon-user"></span>
                    <h3 class="panel-title">
                        {{title}}
                    </h3>
                    <button *ngIf="closeable" type="button" class="close" (click)="show = false">&times;</button>
                    <div style="display: inline-block;" class="pull-right">
                        <ng-content select=".vt-modal-header"></ng-content>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="vt-modal-body-container">
                        <ng-content select=".vt-modal-body"></ng-content>
                    </div>
                </div>
                <div class="panel-footer">
                    <ng-content select=".vt-modal-footer"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>