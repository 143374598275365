<table class="table table-hover">
                    <thead>
                        <tr>
                            <th *ngFor="let header of headers">
                                <button type="button" class="btn btn-link" (click)="order(header)">{{header}}&nbsp;<span *ngIf="orderedBy(header)" class="glyphicon" [ngClass]="{ 'glyphicon-chevron-up': reversed, 'glyphicon-chevron-down': !reversed }"></span></button>
                            </th>
                            <th class="icon-col"></th>
                            <th class="icon-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of orderedList">
                            <td *ngFor="let header of headers">
                                <a [routerLink]="getStateLink(row)">{{getRowProperty(row, header)}}</a>
                            </td>
                            <td>
                                <button type="button" (click)="edit(row)" class="btn btn-link glyphicon glyphicon-pencil"></button>
                            </td>
                            <td>
                                <button type="button" (click)="deleteRow(row)" class="btn btn-link glyphicon glyphicon-remove"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>