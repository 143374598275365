
import {zip as observableZip,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntityState, Predicate } from 'breeze-client';
import * as _ from 'lodash';

import { Course, TrainingPlan, Employee } from '../core/entities/entity-model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';

@Component({
    selector: 'courses',
    template: `<h3>Course administration</h3>
               <hr />
               <button class="btn btn-primary pull-right" (click)="new()">Add new&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
               <list-table [list]="courses" [headers]="displayFields" [type]="type" (delete)="delete($event)"></list-table>`
})
export class CoursesComponent implements OnInit
{
    type: string = 'sysadmin.course';
    displayFields: string[] = ['Name', 'Code'];
    courses: Course[];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, private router: Router, private messageService: MessageService) { }

    ngOnInit() {
        this.unitOfWork.courseRepo.all(true).subscribe(cachedCourses => {
            if (cachedCourses.length === 0 || cachedCourses.length === 1)
                this.unitOfWork.courseRepo.all().subscribe(dbCourses => this.courses = dbCourses);
            else
                this.courses = cachedCourses;
        });
    }

    edit(course: Course) {
        this.router.navigate(['/sysadmin/course', course.id]);
    }

    delete(course: Course) {
        let employeeObservable = this.unitOfWork.employeeRepo.all();
        let trainingPlanObservable = this.unitOfWork.trainingPlanRepo.where(new Predicate('courseId', '==', course.id));
        observableZip(employeeObservable, trainingPlanObservable).subscribe(results => {
            let employees = <Employee[]>results[0];
            let dependentTrainingPlans = <TrainingPlan[]>results[1];

            let okToDelete = dependentTrainingPlans.length == 0;

            if (!okToDelete) {
                let dependentTrainingPlansString = '';
                if (dependentTrainingPlans.length > 0) {
                    dependentTrainingPlansString = '<b>Employees</b><ul>'
                        + _.map(dependentTrainingPlans, p => '<li>' + _.find(employees, e => p.employeeId === e.id).lastName + ', ' + _.find(employees, e => p.employeeId === e.id).firstName + '</li>').join('') + '</ul>';
                }

                let errorMessage = 'Error. Failed to delete course. The following employees reference this course:<br/>' + dependentTrainingPlansString;

                this.messageService.displayMessage('Can not delete course', errorMessage);
            }
            else {
                this.messageService.displayConfirm('Delete course?', 'Really delete course?', (yesNo: boolean) => {
                    if (yesNo) {
                        course.entityAspect.entityState = EntityState.Deleted;
                        this.unitOfWork.commit().then(response => this.unitOfWork.courseRepo.all(true).subscribe(courses => this.courses = courses));
                    }
                });  
            }
        }); 
    }

    new() {
        this.router.navigate(['/sysadmin/course']);
    }
}