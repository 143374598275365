import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './core/auth.service';
import { ILoginData } from './auth.interface';
import { BusyService } from './core/busy.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'login-component',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  loginForm: FormGroup;

  constructor(fb: FormBuilder, private authService: AuthService, private router: Router, private busyService: BusyService) {
    this.loginForm = fb.group({
      'userName': [null, Validators.required],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(16)])],
      'rememberMe': false
    });
  }

  submit() {
    let loginData: ILoginData = { userName: <string>this.loginForm.controls['userName'].value, password: <string>this.loginForm.controls['password'].value };
    (this.busyService.busy(this.authService.login(loginData)) as Observable<any>).subscribe(x => {
      this.router.navigateByUrl('/home');
    },
      (x: Response) => {
        if (x.status === 400) {
          alert('Incorrect username or password');
        }
      });
  }
}
