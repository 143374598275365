﻿import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class VTErrorHandler extends ErrorHandler {
    constructor() {
        super();
    }
    handleError(error: any) {
        alert('An error has occurred...');
        super.handleError(error);
    }
}