/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./main-menu.component.ngfactory";
import * as i3 from "./main-menu.component";
import * as i4 from "./core/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "./loading.component.ngfactory";
import * as i7 from "./loading.component";
import * as i8 from "./core/busy.service";
import * as i9 from "./shared/message-center.component.ngfactory";
import * as i10 from "./shared/message-center.component";
import * as i11 from "./core/message.service";
import * as i12 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container body-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "main-menu", [], null, null, null, i2.View_MainMenuComponent_0, i2.RenderType_MainMenuComponent)), i1.ɵdid(2, 114688, null, 0, i3.MainMenuComponent, [i4.AuthService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "main-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "vt-loading", [], null, null, null, i6.View_LoadingComponent_0, i6.RenderType_LoadingComponent)), i1.ɵdid(7, 49152, null, 0, i7.LoadingComponent, [i8.BusyService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "message-center", [], null, null, null, i9.View_MessageCenterComponent_0, i9.RenderType_MessageCenterComponent)), i1.ɵdid(9, 49152, null, 0, i10.MessageCenterComponent, [i11.MessageService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9 V\u00E4der\u00F6 Shipping v.1.0.8"]))], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i12.AppComponent, [i4.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
