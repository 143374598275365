﻿import { MetadataStore } from 'breeze-client';
import { Course, CrewGroup, CrewType, Employee, Schedule, TrainingPlan, Turn, User, Vessel } from '../core/entities/entity-model';

export class RegistrationHelper {

    static register(metadataStore: MetadataStore) {
        metadataStore.registerEntityTypeCtor('Course', Course, Course.initializer);
        metadataStore.registerEntityTypeCtor('CrewGroup', CrewGroup, CrewGroup.initializer);
        metadataStore.registerEntityTypeCtor('CrewType', CrewType, CrewType.initializer);
        metadataStore.registerEntityTypeCtor('Employee', Employee, Employee.initializer);
        metadataStore.registerEntityTypeCtor('Schedule', Schedule, Schedule.initializer);
        metadataStore.registerEntityTypeCtor('TrainingPlan', TrainingPlan, TrainingPlan.initializer);
        metadataStore.registerEntityTypeCtor('Turn', Turn, Turn.initializer);
        metadataStore.registerEntityTypeCtor('User', User, User.initializer);
        metadataStore.registerEntityTypeCtor('Vessel', Vessel, Vessel.initializer);
    }
}
