﻿<h3>Edit vessel</h3>
<hr />
<form (ngSubmit)="saveChanges()" [formGroup]="vesselForm">
    <div class="form-group">
        <label for="NameTB">Name</label>
        <input id="NameTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('name') }"
               [(ngModel)]="vessel.name" [formControl]="vesselForm.controls['name']" />
        <div *ngIf="isInvalid('name')" class="alert alert-danger">Name required!</div>
    </div>
    <div class="form-group">
        <label for="CallSignTB">Call sign</label>
        <input id="CallSignTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('callSign') }"
               [(ngModel)]="vessel.callSign" [formControl]="vesselForm.controls['callSign']" />
        <div *ngIf="isInvalid('callSign')" class="alert alert-danger">Call sign required!</div>
    </div>
    <div class="form-group">
        <label for="DWTTB">DWT</label>
        <input id="DWTTB" type="number" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('dwt') }"
               [(ngModel)]="vessel.deadweightTonnage" [formControl]="vesselForm.controls['dwt']" />
        <div *ngIf="isInvalid('dwt')" class="alert alert-danger">DWT required!</div>
    </div>
    <div class="form-group">
        <label for="GRTTB">GRT</label>
        <input id="GRTTB" type="number" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('grt') }"
               [(ngModel)]="vessel.grossRegisterTonnage" [formControl]="vesselForm.controls['grt']" />
        <div *ngIf="isInvalid('grt')" class="alert alert-danger">GRT required!</div>
    </div>
    <div class="form-group">
        <label for="NetTB">NET</label>
        <input id="NetTB" type="number" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('net') }"
               [(ngModel)]="vessel.netTonnage" [formControl]="vesselForm.controls['net']" />
        <div *ngIf="isInvalid('net')" class="alert alert-danger">NET required!</div>
    </div>
    <div class="form-group">
        <label for="MeTB">ME</label>
        <input id="MeTB" type="number" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('me') }"
               [(ngModel)]="vessel.me" [formControl]="vesselForm.controls['me']" />
        <div *ngIf="isInvalid('me')" class="alert alert-danger">ME required!</div>
    </div>

    <button class="btn btn-primary" type="submit" [disabled]="!vesselForm.valid">Save</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
</form>
