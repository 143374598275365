﻿export { AdminComponent } from './admin.component';
export { CrewGroupsComponent } from './crewgroups.component';
export { AdminSidemenuComponent } from './admin-sidemenu.component';
export { CrewGroupComponent } from './crewgroup.component';
export { CoursesComponent } from './courses.component';
export { CrewTypesComponent } from './crewtypes.component';
export { VesselsComponent } from './vessels.component';
export { CourseComponent } from './course.component';
export { VesselComponent } from './vessel.component';
export { CrewTypeComponent } from './crewtype.component';
export { CourseResultsComponent } from './course-results.component';
export { CourseResultComponent } from './course-result.component';
export { UsersComponent } from './users.component';
export { UserComponent } from './user.component';
export { SalaryDetailsComponent } from './salary-details.component';