﻿import { Component } from '@angular/core';
import { BusyService } from './core/busy.service';

@Component({
        selector: 'vt-loading',
    templateUrl: 'loading.component.html',
    styleUrls: ['loading.component.css']
})
export class LoadingComponent {
    constructor(private busyService: BusyService) { }

    get busy(): boolean {
        return this.busyService.isBusy;
    }
}
