﻿import { EntityBase, EmploymentStatus, CrewType, TrainingPlan, Schedule, Turn, EmployeeFile } from './entity-model';
import { core } from 'breeze-client';
import * as moment from 'moment';

export class Employee extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: Employee) {

    }

    active: string;
    bTMCourseDate: Date | moment.Moment;
    city: string;
    country: string;
    country2: string;
    cRA: string;
    cRAExpirationDate: Date | moment.Moment;
    crewTypeId: string;
    dateOfBirth: Date | moment.Moment;
    dateOfEmployment: Date | moment.Moment;
    email: string;
    employeeNr: number;
    endorsementDate: Date | moment.Moment;
    endorsementExpirationDate: Date | moment.Moment;
    experience: string;
    fireCertificationDate: Date | moment.Moment;
    fireCertificationExpireDate: Date | moment.Moment;
    firstName: string;
    gOCDate: Date | moment.Moment;
    gOCExpirationDate: Date | moment.Moment;
    healthCertificationExpireDate: Date | moment.Moment;
    homePhone: string;
    id: string;
    info: string;
    lastName: string;
    medicalCertificationDate: Date | moment.Moment;
    medicalCertificationExpireDate: Date | moment.Moment;
    middleName: string;
    mobilePhone: string;
    nextOfKin: string;
    otherInfo: string;
    passportExpirationDate: Date | moment.Moment;
    passportNr: string;
    placeOfBirth: string;
    postalCode: string;
    road: string;
    sIRBExpireDate: Date | moment.Moment;
    sIRBNr: string;
    sSOCourseDate: Date | moment.Moment;
    status: string;
    tankerManChemDate: Date | moment.Moment;
    tankerManChemExpirationDate: Date | moment.Moment;
    tankerManOilDate: Date | moment.Moment;
    tankerManOilExpirationDate: Date | moment.Moment;
    crewType: CrewType;
    trainingPlans: TrainingPlan[];
    schedules: Schedule[];
    turns: Turn[];
    employeeFiles: EmployeeFile[];

    get statusForDropdown(): string {
        if (this.active == 'Active')
            return '';

        return '   [Inactive]';
    }

    get displayName(): string {
        return this.lastName + ', ' + this.firstName + (this.middleName != null ? ` ${this.middleName}` : '');
    }

    momentify() {
        this.bTMCourseDate = this._momentify(<Date>this.bTMCourseDate);
        this.cRAExpirationDate = this._momentify(<Date>this.cRAExpirationDate);
        this.fireCertificationDate = this._momentify(<Date>this.fireCertificationDate);
        this.fireCertificationExpireDate = this._momentify(<Date>this.fireCertificationExpireDate);
        this.healthCertificationExpireDate = this._momentify(<Date>this.healthCertificationExpireDate);
        this.medicalCertificationDate = this._momentify(<Date>this.medicalCertificationDate);
        this.medicalCertificationExpireDate = this._momentify(<Date>this.medicalCertificationExpireDate);
        this.passportExpirationDate = this._momentify(<Date>this.passportExpirationDate);
        this.sIRBExpireDate = this._momentify(<Date>this.sIRBExpireDate);
        this.sSOCourseDate = this._momentify(<Date>this.sSOCourseDate);
        this.dateOfBirth = this._momentify(<Date>this.dateOfBirth);
        this.dateOfEmployment = this._momentify(<Date>this.dateOfEmployment);
        this.endorsementDate = this._momentify(<Date>this.endorsementDate);
        this.endorsementExpirationDate = this._momentify(<Date>this.endorsementExpirationDate);
        this.tankerManOilDate = this._momentify(<Date>this.tankerManOilDate);
        this.tankerManOilExpirationDate = this._momentify(<Date>this.tankerManOilExpirationDate);
        this.tankerManChemDate = this._momentify(<Date>this.tankerManChemDate);
        this.tankerManChemExpirationDate = this._momentify(<Date>this.tankerManChemExpirationDate);
        this.gOCDate = this._momentify(<Date>this.gOCDate);
        this.gOCExpirationDate = this._momentify(<Date>this.gOCExpirationDate);
    }

    _momentify(date: Date): moment.Moment {
        if (date && !(date instanceof Date))
            return date;

        return date ? moment(date) : moment.invalid();
    }
}