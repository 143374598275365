﻿<h3 class="page-header">Documents expired or expiring</h3>

<div class="panel-group" id="expired-documents">
    <div *ngFor="let employee of employees" class="panel panel-default">
        <div class="panel-heading">
            <a role="button" data-toggle="collapse" [href]="'#panel-' + employee.employeeNr">
                {{employee.lastName}}, {{employee.firstName}} {{employee.middleName}}
            </a>
            <a *ngIf="employee && authService.hasRole('CrewWrite')" class="pull-right" [routerLink]="['/crew', employee.employeeNr]">Edit</a>
        </div>
        <div [id]="'panel-' + employee.employeeNr" class="panel-collapse collapse" role="tabpanel">
            <div class="panel-body">
                <ul class="list-unstyled">
                    <li *ngIf="documentExpired(employee, 'cRAExpirationDate')">
                        CRA valid until
                        <span [class]="getDateClass(employee.cRAExpirationDate)">{{employee.cRAExpirationDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'endorsementExpirationDate')">
                        Endorsement expire
                        <span [class]="getDateClass(employee.endorsementExpirationDate)">{{employee.endorsementExpirationDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'tankerManOilExpirationDate')">
                        Tankerman oil expire
                        <span [class]="getDateClass(employee.tankerManOilExpirationDate)">{{employee.tankerManOilExpirationDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'tankerManChemExpirationDate')">
                        Tankerman chem expire
                        <span [class]="getDateClass(employee.tankerManChemExpirationDate)">{{employee.tankerManChemExpirationDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'gOCExpirationDate')">
                        GOC/GMDSS expire
                        <span [class]="getDateClass(employee.gOCExpirationDate)">{{employee.gOCExpirationDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'fireCertificationExpireDate')">
                        Fire certificate expire
                        <span [class]="getDateClass(employee.fireCertificationExpireDate)">{{employee.fireCertificationExpireDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'healthCertificationExpireDate')">
                        Health certificate expire
                        <span [class]="getDateClass(employee.healthCertificationExpireDate)">{{employee.healthCertificationExpireDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'passportExpirationDate')">
                        Passport expire
                        <span [class]="getDateClass(employee.passportExpirationDate)">{{employee.passportExpirationDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'sIRBExpireDate')">
                        SIRB expire
                        <span [class]="getDateClass(employee.sIRBExpireDate)">{{employee.sIRBExpireDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                    <li *ngIf="documentExpired(employee, 'medicalCertificationExpireDate')">
                        Medical care expire
                        <span [class]="getDateClass(employee.medicalCertificationExpireDate)">{{employee.medicalCertificationExpireDate | date : 'yyyy-MM-dd'}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>