import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EntityState, Predicate } from 'breeze-client';

import { Vessel } from '../core/entities/vessel.model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';

@Component({
    selector: 'vessels',
    template: `<h3>Vessel administration</h3>
               <hr />
               <button class="btn btn-primary pull-right" (click)="new()">Add new&nbsp;<span class="glyphicon glyphicon-plus"></span></button>
               <list-table [list]="vessels" [headers]="displayFields" [props]="props" [type]="type" (delete)="delete($event)"></list-table>`
})
export class VesselsComponent implements OnInit
{
    type: string = 'sysadmin.vessel';
    displayFields: string[] = ['Name', 'Call Sign', 'Dt', 'Grt', 'Net', 'Me'];
    props: string[] = ['name', 'callSign', 'deadweightTonnage', 'grossRegisterTonnage', 'netTonnage', 'mE'];
    vessels: Vessel[];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, private router: Router, private messageService: MessageService) { }

    ngOnInit() {
        this.unitOfWork.vesselRepo.all(true).subscribe(cachedVessels => {
            if (cachedVessels.length === 0 || cachedVessels.length === 1)
                this.unitOfWork.vesselRepo.where(new Predicate('active', '==', true)).subscribe(dbVessels => this.vessels = dbVessels);
            else
                this.vessels = cachedVessels;
        });
    }

    edit(vessel: Vessel) {
        this.router.navigate(['/sysadmin/vessel', vessel.id]);
    }

    delete(vessel: Vessel) {
        this.messageService.displayConfirm('Delete vessel?', 'Really delete vessel "' + vessel.name + '"?', (yesNo: boolean) => {
            if (yesNo) {
                vessel.active = false;
                //vessel.entityAspect.entityState = EntityState.Deleted;
                this.unitOfWork.commit().then(response => this.vessels = this.unitOfWork.vesselRepo.whereInCache(new Predicate('active', '==', true)));
            }
        });   
    }

    new() {
        this.router.navigate(['/sysadmin/vessel']);
    }
}