﻿//export module enums {
    export enum CourseResults {
        OK = 0,
        TBS = 1,
        S = 2,
        NMAP = 3
    }
    export enum CourseType {
        Mandatory = 1,
        Optional = 2
    }
    export enum EmploymentStatus {
        Active = 0,
        InActive = 1,
        Applicant = 2
    }
    export enum ScheduleStatus {
        C = 0,
        P = 1
    }
    export enum CivilStatus {
        Married = 1,
        Single = 2,
        Cohabiting = 3
    }

    export enum TurnDocumentFlag {
        None = 0,
        Yes = 1,
        NotNeeded = 2
    }
//}