import {
    Component, OnInit, Inject, AfterViewInit
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Predicate } from 'breeze-client';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Employee, CrewType, Turn, TrainingPlan, Course, CourseResult, File, Vessel } from '../core/entities/entity-model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { MessageService } from '../core/message.service';
import { BusyService } from '../core/busy.service';
import { AuthService } from '../core/auth.service';

@Component({
        selector: 'dashboard-documents',
    templateUrl: 'dashboard-documents.component.html',
})
export class DashboardDocumentsComponent implements OnInit {
    employees: Employee[];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, formBuilder: FormBuilder, private messageService: MessageService
        , private route: ActivatedRoute, private router: Router, private busyService: BusyService, private authService: AuthService) { }

    ngOnInit() {
        var date = moment().subtract(30, 'days').startOf('day');
        var active = new Predicate("active", "==", "Active");

        let preds: Predicate[] = _.map(["cRA", "endorsement", "tankerManOil", "tankerManChem", "gOC", "passport"], (key) => Predicate.create(key + "ExpirationDate", ">=", date));
        preds.push(new Predicate("medicalCertificationExpireDate", ">=", date));
        preds.push(new Predicate("fireCertificationExpireDate", ">=", date));
        preds.push(new Predicate("healthCertificationExpireDate", ">=", date));
        preds.push(new Predicate("sIRBExpireDate", ">=", date));

        var documents = Predicate.or(preds);

        (this.busyService.busy(this.unitOfWork.employeeRepo.where(active.and(documents))) as Observable<Employee[]>).subscribe(emps =>
            this.employees = _.sortBy(emps, v => v.displayName.toLowerCase())
        );
    }

    ngAfterViewInit() {
        this.jQueryWait();
    }

    jQueryWait() {
        setTimeout(() => {
            var node = $("body") as any;
            if (node.collapse == undefined)
                setTimeout(this.jQueryWait, 500);
            else
                this.jQueryDidInit();
        }, 500);
    }

    jQueryDidInit() {
        ($("#expired-documents") as any).collapse();
    }

    documentExpired(employee: Employee, key: string): boolean {
        if (!employee)
            return false;

        if (employee[key]) {
            if (this.dateIsNull(employee[key]))
                return true;

            return this.dateIsInThePast(employee[key]) || this.dateInNextMonth(employee[key]);
        }

        return false;
    }

    dateIsNull(date: Date) {
        var mom = moment(date);
        if (moment.isMoment(mom))
            return mom.year() == 1;

        return true;
    }

    dateInNextMonth(date: Date): boolean {
        return moment(date).startOf('day').isBefore(moment().startOf('day').add(30, 'days'), 'day');
    }

    dateIsInThePast(date: Date): boolean {
        return moment(date).startOf('day').isBefore(moment().startOf('day'), 'day');
    }

    getDateClass(date: Date): string {
        if (!date)
            return '';

        if (this.dateIsNull(date) || this.dateIsInThePast(date))
            return 'text-danger';
        if (this.dateInNextMonth(date))
            return 'text-warning';

        return '';
    }
}
