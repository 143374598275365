﻿import { Component, Input } from '@angular/core';

@Component({
        selector: 'modal-window',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.css']
})
export class ModalComponent
{
    @Input() modal: boolean = false;
    @Input() large: boolean = false;
    @Input() title: string = '';
    @Input() show: boolean = false;
    @Input() closeable: boolean = false;
}
