﻿<h3>Edit crew group</h3>
<hr />
<form (ngSubmit)="saveChanges()" [formGroup]="crewGroupForm">
    <div class="form-group">
        <label for="NameTB">Name</label>
        <input id="NameTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': !crewGroupForm.controls['name'].valid && crewGroupForm.controls['name'].touched }"
               [(ngModel)]="crewGroup.name" [formControl]="crewGroupForm.controls['name']" />
        <div *ngIf="!crewGroupForm.controls['name'].valid" class="alert alert-danger">Name required!</div>
    </div>

    <button class="btn btn-primary" type="submit" [disabled]="!crewGroupForm.valid">Save</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
</form>