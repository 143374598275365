﻿<modal-window [title]="'Edit turn'" [modal]="true" [large]="true" [show]="turn">
    <div class="vt-modal-body">
        <form [formGroup]="turnForm" *ngIf="turn">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Crew member</label>
                        <ng-container *ngIf="shouldSelectEmployee">
                            <ng-select
                                [items]="employeeSelectList"
                                [placeholder]="'Select crew member'"
                                (formControlName)="employee"
                                (selected)="preselectCrewType($event)"
                            ></ng-select>
                            <div class="alert alert-danger" *ngIf="employee && employee.errors && (employee.dirty || employee.touched)">You must select a crew member</div>
                            <div class="alert alert-danger" *ngIf="crewmanNotAvailable">Selected crew member is not available during selected timespan</div>
                        </ng-container>
                        <ng-container *ngIf="!shouldSelectEmployee">
                            <select *ngIf="turn" class="form-control" formControlName="employee" [(ngModel)]="turn.employeeId" disabled="disabled">
                                <option value="{{turn?.employee?.id}}">{{turn?.employee?.displayName + ' (' + turn?.employee?.employeeNr + ')'}}</option>
                            </select>
                        </ng-container>
                    </div>

                    <div class="form-group">
                        <label>Position</label>
                        <select *ngIf="turn" class="form-control" formControlName="crewType" [(ngModel)]="turn.crewTypeId" (change)="updateCrewType($event, turn.crewTypeId)">
                            <option value="" selected>Select position</option>
                            <option *ngFor="let crewType of crewTypes" [value]="crewType.id">{{crewType.name}}</option>
                        </select>
                        <div *ngIf="crewType && crewType.errors && (crewType.dirty || crewType.touched)" class="alert alert-danger">
                            <span>Must select crewtype</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Relieves crew member</label>
                        <select *ngIf="turn" class="form-control select2" formControlName="relievesTurn" [(ngModel)]="turn.relievesTurnId">
                            <option value="" selected="selected">None</option>
                            <option *ngFor="let turn of turnsToRelieve" [value]="turn?.id">{{turn?.employee?.displayName + ' (' + turn?.employee?.employeeNr + ')'}}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Vessel</label>
                        <ng-container *ngIf="!shouldSelectVessel">
                            <select class="form-control" disabled="disabled" formControlName="vessel" [(ngModel)]="turn.vesselId">
                                <option value="{{turn?.vessel?.id}}">{{turn?.vessel?.name}}</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="shouldSelectVessel">
                            <select class="form-control" formControlName="vessel" [(ngModel)]="turn.vesselId">
                                <option value="">Select a vessel</option>
                                <option *ngFor="let vessel of vessels" [value]="vessel.id">{{vessel.name}}</option>
                            </select>
                        </ng-container>
                    </div>

                    <div class="form-group">
                        <label>Sign on</label>
                        <my-date-picker locale="sv" [options]="datePickerConfig" [selDate]="getDateString(turn ? turn.signOn : null)" (dateChanged)="updateDate($event, 'signOn')"></my-date-picker>
                    </div>

                    <div class="form-group">
                        <label>Sign off</label>
                        <my-date-picker locale="sv" [options]="datePickerConfig" [selDate]="getDateString(turn ? turn.signOff : null)" (dateChanged)="updateDate($event, 'signOff')"></my-date-picker>
                        <div *ngIf="invalidDates" class="alert alert-danger">
                            <span>Sign off must be after sign on!</span>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <turn-checks [turn]="turn"></turn-checks>
                </div>
            </div>
        </form>
    </div>
    <div class="vt-modal-footer">
        <div class="clearfix">
            <p class="pull-right">
                <button type="button" class="btn btn-primary" [disabled]="invalidDates || crewmanNotAvailable || !turnForm.valid" (click)="saveFunc()" style="margin-right:10px;">Save</button>
                <button type="button" class="btn btn-danger" (click)="cancelFunc()">Cancel</button>
            </p>
        </div>
    </div>
</modal-window>