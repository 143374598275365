﻿import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';

import {
    AdminComponent, AdminSidemenuComponent, CrewGroupComponent, CrewGroupsComponent
    , CoursesComponent, CrewTypesComponent, VesselsComponent, CourseComponent
    , VesselComponent, CrewTypeComponent, CourseResultsComponent, CourseResultComponent
    , UsersComponent, UserComponent, SalaryDetailsComponent
} from './main';
import { DataModule } from '../data/data.module';
import { SharedModule } from '../shared/shared.module';
import { SalaryDetailCalculatorService } from './salary-detail-calculator.service';

@NgModule({
    imports: [
        BrowserModule,
        DataModule,
        NgbModule,
        RouterModule,
        SharedModule,
        FormsModule,
        MyDatePickerModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [
        AdminComponent,
        AdminSidemenuComponent,
        CrewGroupComponent,
        CrewGroupsComponent,
        CoursesComponent,
        CourseComponent,
        CrewTypesComponent,
        VesselsComponent,
        VesselComponent,
        CrewTypeComponent,
        CourseResultsComponent,
        CourseResultComponent,
        UserComponent,
        UsersComponent,
        SalaryDetailsComponent
    ],
    providers: [
        SalaryDetailCalculatorService
    ]
})
export class SystemModule { }