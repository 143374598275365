﻿<div style="margin-top: 15px;" class="row">
    <div class="col-sm-6">
        <div class="form-group">
            <label>Employee nr: </label>
            <span>{{employee.employeeNr}}</span>
        </div>
        <div class="form-group">
            <label>Given name: </label>
            <span>{{employee.firstName}}</span>
        </div>
        <div class="form-group">
            <label>Surname: </label>
            <span>{{employee.lastName}}</span>
        </div>
        <div class="form-group">
            <label>Date of birth: </label>
            <span>{{employee.dateOfBirth | date: 'yyyy-MM-dd'}}</span>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="form-group">
            <label>Date of first employment: </label>
            <span>{{employee.dateOfEmployment | date: 'yyyy-MM-dd'}}</span>
        </div>
        <div class="form-group">
            <label>Middle name: </label>
            <span>{{employee.middleName}}</span>
        </div>
        <div class="form-group">
            <label>Position: </label>
            <span>{{employee.crewType.name}}</span>
        </div>
        <div class="form-group">
            <label>Place of birth: </label>
            <span>{{employee.placeOfBirth}}</span>
        </div>
    </div>
</div>