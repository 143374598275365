﻿<form [formGroup]="passwordForm" (ngSubmit)="submit()">
    <modal-window [title]="'Change password'" [modal]="false" [show]="true">
        <div class="vt-modal-body">
            <div class="form-horizontal">
                <div class="form-group">
                    <label for="oldPasswordTB" class="control-label col-sm-2">Old password</label>
                    <div class="col-sm-10">
                        <input id="oldPasswordTB" type="password" class="form-control" formControlName="oldPassword" [(ngModel)]="oldPassword" />
                        <div *ngIf="!passwordForm.controls['oldPassword'].valid && passwordForm.controls['oldPassword'].touched" class="alert alert-danger">Old password required!</div>
                        <div *ngIf="oldPasswordNotValid" class="alert alert-danger">Old password does not match!</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="passwordTB" class="control-label col-sm-2">New password</label>
                    <div class="col-sm-10">
                        <input type="password" class="form-control" id="passwordTB" formControlName="password" [(ngModel)]="newPassword" />
                        <div *ngIf="passwordForm.controls['password'].hasError('required') && passwordForm.controls['password'].touched" class="alert alert-danger">Password required!</div>
                        <div *ngIf="passwordForm.controls['password'].hasError('minlength') && passwordForm.controls['password'].touched" class="alert alert-danger">Password must be at least 4 characters!</div>
                        <div *ngIf="passwordForm.controls['password'].hasError('maxlength') && passwordForm.controls['password'].touched" class="alert alert-danger">Password can be at most 10 characters!</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="repeatPasswordTB" class="control-label col-sm-2">Repeat password</label>
                    <div class="col-sm-10">
                        <input type="password" class="form-control" id="repeatPasswordTB" formControlName="repeatPassword" [(ngModel)]="repeatPassword" />
                        <div class="alert alert-danger" *ngIf="passwordForm.controls['repeatPassword'].hasError('required') && passwordForm.controls['repeatPassword'].touched">Repeat password required</div>
                        <div class="alert alert-danger" *ngIf="passwordForm.controls['repeatPassword'].hasError('noMatch') && passwordForm.controls['repeatPassword'].touched">Passwords do not match</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vt-modal-footer">
            <div class="clearfix">
                <button type="submit" class="btn btn-lg btn-info btn-block" [disabled]="!passwordForm.valid">Ok</button>
                <button type="button" class="btn btn-lg btn-danger btn-block" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </modal-window>
</form>