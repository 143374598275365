﻿<h3 class="page-header">Upcoming relieves</h3>

<table class="table table-bordered table-hover table-striped">
    <thead>
        <tr>
            <th class="date">Date</th>
            <th>Vessel</th>
            <th>Position</th>
            <th>On</th>
            <th>Off</th>
            <th *ngIf="hasRole('CrewWrite')"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let turn of turns" [class]="getRowClass(turn)">
            <td class="date">{{turn.signOn | date: "yyyy-MM-dd" }}</td>
            <td>{{turn.vessel?.name}}</td>
            <td>{{turn.crewType?.name}}</td>
            <td>{{turn.employee?.displayName}}</td>
            <td>{{turn.relievesTurn?.employee?.displayName}}</td>
            <td *ngIf="hasRole('CrewWrite')">
                <a (click)="editTurn(turn)"><span class="glyphicon glyphicon-edit"></span></a>
            </td>
        </tr>
    </tbody>
</table>

<vt-turn-edit *ngIf="!!turnToEdit" [turn]="turnToEdit" [employee]="employee" [vessel]="vessel" [employees]="employees" [vessels]="vessels" [turns]="allTurns" [crewTypes]="crewTypes" (save)="saveTurn()" (cancel)="cancelEdit()"></vt-turn-edit>
