/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/list-table.component.ngfactory";
import * as i2 from "../shared/list-table.component";
import * as i3 from "@angular/router";
import * as i4 from "./crewgroups.component";
import * as i5 from "../core/vadero-tank-unit-of-work.service";
import * as i6 from "../core/message.service";
var styles_CrewGroupsComponent = [];
var RenderType_CrewGroupsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CrewGroupsComponent, data: {} });
export { RenderType_CrewGroupsComponent as RenderType_CrewGroupsComponent };
export function View_CrewGroupsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Crew group administration"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-primary pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.new() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Add new\u00A0"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "list-table", [], null, [[null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.delete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ListTableComponent_0, i1.RenderType_ListTableComponent)), i0.ɵdid(7, 638976, null, 0, i2.ListTableComponent, [i3.Router], { headers: [0, "headers"], list: [1, "list"], type: [2, "type"] }, { delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayFields; var currVal_1 = _co.groups; var currVal_2 = _co.type; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CrewGroupsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "crewgroups", [], null, null, null, View_CrewGroupsComponent_0, RenderType_CrewGroupsComponent)), i0.ɵdid(1, 114688, null, 0, i4.CrewGroupsComponent, [i5.VaderoTankUnitOfWorkService, i3.Router, i6.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CrewGroupsComponentNgFactory = i0.ɵccf("crewgroups", i4.CrewGroupsComponent, View_CrewGroupsComponent_Host_0, {}, {}, []);
export { CrewGroupsComponentNgFactory as CrewGroupsComponentNgFactory };
