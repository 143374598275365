﻿<div [formGroup]="turnForm">
    <label></label>

    <div class="form-group" *ngIf="turn">
        <div class="row">
            <div class="col-sm-5">
                <input type="hidden" formControlName="cOE" [(ngModel)]="turn.cOE">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="coeCheckOk(turn?.employee, turn?.signOff)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!coeCheckOk(turn?.employee, turn?.signOff)"></span>
                <label>
                    <input type="checkbox" formControlName="cOE" [(ngModel)]="turn.cOE" />
                    COE
                </label>
            </div>
        </div>
    </div>

    <div class="form-group">
        <div class="row">
            <div class="col-sm-5">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="healthCheckOk(turn?.employee, turn?.signOff)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!healthCheckOk(turn?.employee, turn?.signOff)"></span>
                <label>Health cert</label>
            </div>
            <div class="col-sm-5">
                {{getFormattedDate(turn?.employee?.healthCertificationExpireDate)}}
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="turnRequires(turn?.crewType, 'endorsementCOC')">
        <div class="row">
            <div class="col-sm-5">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="cocCheckOk(turn?.employee, turn?.signOff)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!cocCheckOk(turn?.employee, turn?.signOff)"></span>
                <label>COC Endorsement</label>
            </div>
            <div class="col-sm-5">
                {{getFormattedDate(turn?.employee?.endorsementExpirationDate)}}
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="turnRequires(turn?.crewType, 'endorsementTankOil')">
        <div class="row">
            <div class="col-sm-5">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="tankOilCheckOk(turn?.employee, turn?.signOff)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!tankOilCheckOk(turn?.employee, turn?.signOff)"></span>
                <label>TankerMan Oil</label>
            </div>
            <div class="col-sm-5">
                {{getFormattedDate(turn?.employee?.tankerManOilExpirationDate)}}
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="turnRequires(turn?.crewType, 'endorsementTankChem')">
        <div class="row">
            <div class="col-sm-5">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="tankChemCheckOk(turn?.employee, turn?.signOff)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!tankChemCheckOk(turn?.employee, turn?.signOff)"></span>
                <label>TankerMan Chem</label>
            </div>
            <div class="col-sm-5">
                {{getFormattedDate(turn?.employee?.tankerManChemExpirationDate)}}
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="turnRequires(turn?.crewType, 'endorsementGOC')">
        <div class="row">
            <div class="col-sm-5">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="gocCheckOk(turn?.employee, turn?.signOff)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!gocCheckOk(turn?.employee, turn?.signOff)"></span>
                <label>GOC/GMDSS</label>
            </div>
            <div class="col-sm-5">
                {{getFormattedDate(turn?.employee?.gOCExpirationDate)}}
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="turn">
        <div class="row">
            <div class="col-sm-5">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="visaCheckOk(turn)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!visaCheckOk(turn)"></span>
                <label>Visa</label>
            </div>
            <div class="col-sm-5">
                <select formControlName="visa" [(ngModel)]="turn.visa" class="form-control">
                    <option value="None">None</option>
                    <option value="Yes">Yes</option>
                    <option value="NotNeeded">Not needed</option>
                </select>
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="turn">
        <div class="row">
            <div class="col-sm-5">
                <span class="glyphicon glyphicon-ok text-success" *ngIf="logCheckOk(turn)"></span>
                <span class="glyphicon glyphicon-remove text-danger" *ngIf="!logCheckOk(turn)"></span>
                <label>LOG</label>
            </div>
            <div class="col-sm-5">
                <select formControlName="lOG" [(ngModel)]="turn.lOG" class="form-control">
                    <option value="None">None</option>
                    <option value="Yes">Yes</option>
                    <option value="NotNeeded">Not needed</option>
                </select>
            </div>
        </div>
    </div>
</div>