﻿import { EntityBase, Schedule, Turn } from './entity-model';
import { core } from 'breeze-client';

export class Vessel extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: Vessel) { }

    active: boolean;
    callSign: string;
    deadweightTonnage: number;
    grossRegisterTonnage: number;
    id: string;
    me: number;
    name: string;
    netTonnage: number;
    schedules: Schedule[];
    turns: Turn[];
}