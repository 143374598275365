﻿<h3>Edit course</h3>
<hr />
<form (ngSubmit)="saveChanges()" [formGroup]="courseForm">
    <div class="form-group">
        <label for="CodeTB">Code</label>
        <input id="CodeTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('code') }"
               [(ngModel)]="course.code" formControlName="code" />
        <div *ngIf="isInvalid('code')" class="alert alert-danger">Code required!</div>
    </div>
    <div class="form-group">
        <label for="NameTB">Name</label>
        <input id="NameTB" type="text" class="form-control"
               [ngClass]="{ 'invalid-control': isInvalid('name') }"
               [(ngModel)]="course.name" formControlName="name" />
        <div *ngIf="isInvalid('name')" class="alert alert-danger">Name required!</div>
    </div>
    <div class="form-group">
        <label>Type</label>
        <label>
            <input type="radio" formControlName="type" [(ngModel)]="course.type" value="Mandatory" checked />
            Mandatory
        </label>
        <label>
            <input type="radio" formControlName="type" [(ngModel)]="course.type" value="Optional" />
            Optional
        </label>
    </div>
    <div class="form-group">
        <label for="CrewGroupDD">Crew group</label>
        <select formControlName="crewGroup" [(ngModel)]="course.crewGroupId">
            <option value="">Select crewgroup</option>
            <option *ngFor="let group of crewGroups" [value]="group.id">{{group.name}}</option>
        </select>
    </div>
    <hr />
    <h4>Course files</h4>
    <table class="table table-hover">
        <thead>
            <tr>
                <th>Filename</th>
                <th>Size</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let file of course.courseFiles">
                <td>
                    <a target="_blank" [href]="apiBase + 'api/File/' + file.file?.id">{{file.file?.fileName}}</a>
                </td>
                <td></td>
                <td>
                    <button type="button" class="btn btn-link" style="color: red;" (click)="removeFile(file)"><span class="glyphicon glyphicon-remove"></span></button>
                </td>
            </tr>
        </tbody>
    </table>
    <hr />
    <div class="form-group">
        <label>Add new files</label>
        <vt-file-upload (update)="updateFiles()" [apiUrl]="saveFileUrl"></vt-file-upload>
    </div>

    <button class="btn btn-primary" type="submit" [disabled]="!courseForm.valid">Save</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
</form>