/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal.component.ngfactory";
import * as i2 from "./modal.component";
import * as i3 from "./confirm.component";
var styles_ConfirmComponent = [];
var RenderType_ConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
export function View_ConfirmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "modal-window", [], null, null, null, i1.View_ModalComponent_0, i1.RenderType_ModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalComponent, [], { modal: [0, "modal"], title: [1, "title"], show: [2, "show"] }, null), (_l()(), i0.ɵeld(2, 0, null, 1, 2, "div", [["class", "vt-modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, 2, 4, "div", [["class", "vt-modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-lg btn-info btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Yes"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-lg btn-danger btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["No"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.title; var currVal_2 = _co.show; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.message; _ck(_v, 4, 0, currVal_3); }); }
export function View_ConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirm", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i0.ɵdid(1, 49152, null, 0, i3.ConfirmComponent, [], null, null)], null, null); }
var ConfirmComponentNgFactory = i0.ɵccf("confirm", i3.ConfirmComponent, View_ConfirmComponent_Host_0, { show: "show", title: "title", message: "message" }, { confirmed: "confirmed" }, []);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };
