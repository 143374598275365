﻿import { EntityBase } from './entity-model';
import { core } from 'breeze-client';

export class User extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: User) {

    }

    id: string;
    name: string;
    vTUserRoles: string;
    userName: string;
}