﻿import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';

@Component({
        selector: 'admin-sidemenu',
    templateUrl: 'admin-sidemenu.component.html',
    styleUrls: ['admin-sidemenu.component.css']
})
export class AdminSidemenuComponent implements OnInit
{
    hasId: boolean = false;

    constructor(public router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                _.find(this.route.parent.children, x => x.outlet === 'primary')
                    .params
                    .subscribe(params => this.hasId = !!params['id']);
            }
        });
  }

  public routeIsActive(route: string): boolean {
    return this.router.isActive(route, false);
  }
}
