import { Component, OnInit, Inject } from '@angular/core';
import { Predicate } from 'breeze-client';
import * as moment from 'moment';
import * as _ from 'lodash';

import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { Vessel, Employee, Turn, CrewType } from '../core/entities/entity-model';
import { MessageService } from '../core/message.service';
import { AuthService } from '../core/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface CalendarElement {
  numColumns: number,
  name: string
}

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const dayNames = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

@Component({
  selector: 'vt-schedule',
  templateUrl: 'schedule.component.html',
  styleUrls: ['schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  turns: Turn[];
  turnsForVessel: Turn[];
  vessels: Vessel[];
  employees: Employee[];
  crewTypes: CrewType[];
  selectedVesselId: string;
  selectedDate: moment.Moment = moment().startOf('day');
  yearsArray: CalendarElement[] = [];
  monthsArray: CalendarElement[] = [];
  weeksArray: CalendarElement[] = [];
  datesArray: moment.Moment[] = [];
  datePickerConfig: {} = {};
  turnToEdit: Turn = null;
  showSearch: boolean = false;
  apiBase: string;

  constructor(private unitOfWork: VaderoTankUnitOfWorkService, private messageService: MessageService, private authService: AuthService) {
    this.apiBase = environment.apiBase;
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  ngOnInit() {
    Promise.all([
      this.unitOfWork.vesselRepo.where(new Predicate('active', '==', true)).toPromise()
      , this.unitOfWork.employeeRepo.all().toPromise()
      , this.unitOfWork.scheduleRepo.all().toPromise()
      , this.unitOfWork.crewTypeRepo.all().toPromise()
      , this.unitOfWork.turnRepo.all().toPromise()
    ]).then(x => {
      this.vessels = _.orderBy<Vessel>(x[0], v => v.name);
      this.employees = _.orderBy<Employee>(x[1], e => e.lastName + e.firstName);
      this.crewTypes = _.orderBy<CrewType>(x[3], t => t.ranking);
    });

    this.updateCalendar();
  }

  getTurnsForVessel(): Turn[] {
    let vesselPredicate = Predicate.create('vesselId', '==', this.selectedVesselId);
    let signOnPredicate = Predicate.create('signOn', '<', this.selectedDate.clone().add(30, 'days').toDate());
    let signOffPredicate = Predicate.create('signOff', '>', this.selectedDate);

    //return this.unitOfWork.turnRepo.whereInCache(Predicate.and(vesselPredicate, signOnPredicate, signOffPredicate));
    return this.unitOfWork.turnRepo.whereInCache(vesselPredicate);
  }

  getTurnsOnSchedule(): Turn[] {
    return _.orderBy<Turn>(_.filter(this.turnsForVessel, t =>
      moment(t.signOn).utc().isSameOrBefore(this.selectedDate.clone().add(30, 'days').utc(), 'day') &&
      moment(t.signOff).utc().isSameOrAfter(this.selectedDate.clone().utc(), 'day')),
      [t => t.crewType.ranking, t => t.signOff]); // FIXME: crewType is not always available here
  }

  updateSelectedVessel(vesselId: string) {
    this.selectedVesselId = vesselId;
    this.updateTurns();
  }

  updateDate(date: any) {
    if (date) {
      this.selectedDate = moment(date.formatted).startOf('day');
    }
    else
      this.selectedDate = moment(moment.now()).startOf('day');

    this.updateCalendar();
  }

  private formatWeekName(week: number) {
    return 'w.' + week;
  }

  updateCalendar() {
    let dates = [this.selectedDate.clone()];
    let dateCtr = this.selectedDate.clone();
    let weeks: CalendarElement[] = [{ numColumns: 1, name: this.formatWeekName(dateCtr.isoWeek()) }];
    let months: CalendarElement[] = [{ numColumns: 2, name: monthNames[dateCtr.month()] }];
    let years: CalendarElement[] = [{ numColumns: 2, name: dateCtr.year().toString() }];

    while (dates.length < 30) {
      dateCtr = dateCtr.add(1, 'days');

      let existingWeek = _.find(weeks, w => w.name === this.formatWeekName(dateCtr.isoWeek()));
      if (existingWeek)
        ++existingWeek.numColumns;
      else
        weeks.push({ numColumns: 1, name: this.formatWeekName(dateCtr.isoWeek()) });

      let existingMonth = _.find(months, m => m.name === monthNames[dateCtr.month()]);
      if (existingMonth)
        ++existingMonth.numColumns;
      else
        months.push({ numColumns: 2, name: monthNames[dateCtr.month()] });

      let existingYear = _.find(years, y => y.name === dateCtr.year().toString());
      if (existingYear)
        ++existingYear.numColumns;
      else
        years.push({ numColumns: 2, name: dateCtr.year().toString() });

      dates.push(dateCtr.clone());
    }

    this.datesArray = dates;
    this.weeksArray = weeks;
    this.monthsArray = months;
    this.yearsArray = years;

    this.updateTurns();
  }

  addDays(numDays: number) {
    this.selectedDate.add(numDays, 'days');
    this.updateCalendar();
  }

  getDateString(): string {
    return this.selectedDate.format('YYYY-MM-DD');
  }

  get selectedVessel(): Vessel {
    return _.find(this.vessels, v => v.id === this.selectedVesselId);
  }

  getHeaderClass(date: moment.Moment) {
    let yesterday = date.clone().subtract(1, 'days');
    if (yesterday.month() !== date.month())
      return 'month-bordered';
  }

  getCellClass(turn: Turn, date: moment.Moment): string {
    let active: boolean = false;

    let activeTurn = turn.vesselId === this.selectedVesselId && date.isBetween(moment(turn.signOn).startOf('day'), moment(turn.signOff).startOf('day'), 'day', '[]');

    let daysSinceStart: number = 0;
    let weeksSinceStart: number = 0;

    if (activeTurn) {
      daysSinceStart = moment(turn.signOn).startOf('day').diff(date, 'days') - 1;
      weeksSinceStart = Math.floor(daysSinceStart / 7);
    }

    let activeOrPlanned: string = '';
    if (activeTurn) {
      if (turn.status === 'Planned')
        activeOrPlanned = 'calendar-cell-planned';
      else
        if (turn.allChecksOk(turn.employee, turn.signOff))
          activeOrPlanned = 'calendar-cell-active';
        else
          activeOrPlanned = 'calendar-cell-docerror';
    }

    let alternate: string = ((weeksSinceStart % 2) ? 'calendar-cell-alternate' : '');

    return ['calendar-cell', activeOrPlanned, alternate].join(" ");
  }

  getRowSpacerClass(turn: Turn, nextTurn: Turn): string {
    let klass = "spacer-row";

    if (turn && turn.crewTypeId && nextTurn && nextTurn.crewTypeId) {
      let turnCrew = turn.crewTypeId;
      let prevCrew = nextTurn.crewTypeId;

      if (turnCrew != prevCrew) {
        return klass + " crew-change";
      }
    }

    return klass;
  }

  getDaysNumber(turn: Turn, date: moment.Moment): number {
    let active: boolean = false;

    let activeTurn = turn.vesselId === this.selectedVesselId && date.isBetween(moment(turn.signOn).startOf('day'), moment(turn.signOff).startOf('day'), 'day', '[]');
    let daysSinceStart: number = null;

    if (activeTurn) {
      daysSinceStart = date.diff(moment(turn.signOn).startOf('day'), 'days');
    }

    return daysSinceStart;
  }

  getDayOfWeek(date: moment.Moment) {
    return dayNames[date.isoWeekday() - 1];
  }

  getWeekNumberString(turn: Turn, date: moment.Moment): string {
    let numberOfDays = this.getDaysNumber(turn, date);

    return numberOfDays != null && !(numberOfDays % 7) ? (numberOfDays / 7 + 1).toString() : '';
  }

  removeTurn(turn: Turn) {
    let relievingTurn: Turn = _.find(this.turnsForVessel, t => t.relievesTurnId == turn.id);
    if (relievingTurn) {
      this.messageService.displayMessage("Unable to remove turn",
        "You cannot remove this turn, as another turn is set to relieve this turn. "
        + "If you really want to remove this, edit the relieving turn first. "
        + "The turn in question is relieved by "
        + relievingTurn.employee.displayName
        + " on "
        + moment(relievingTurn.signOn).format("YYYY-MM-DD"));
    } else {
      this.messageService.displayConfirm('Delete scheduled service?', 'Really delete scheduled service', (yesNo: boolean) => {
        if (yesNo) {
          turn.entityAspect.setDeleted();
          this.unitOfWork.commit().then(
            x => {
              this.unitOfWork.turnRepo.all().subscribe(t => this.updateTurns())
            }
          );
        }
      });
    }
  }

  editTurn(turn: Turn) {
    this.turnToEdit = turn;
  }

  addTurn(employee: Employee) {
    this.turnToEdit = this.unitOfWork.createTurnFactory().create();
    this.turnToEdit.vesselId = this.selectedVesselId;
    this.turnToEdit.vessel = this.selectedVessel;
    this.turnToEdit.employeeId = employee.id;
    this.turnToEdit.employee = employee;
    this.turnToEdit.relievesTurnId = "";
    this.turnToEdit.signOff = new Date();
    this.turnToEdit.signOn = new Date();
    this.turnToEdit.status = 'Planned';
    this.turnToEdit.visa = 'None';
    this.turnToEdit.lOG = 'None';
  }

  addCrew() {
    this.turnToEdit = this.unitOfWork.createTurnFactory().create();
    this.turnToEdit.vesselId = this.selectedVesselId;
    this.turnToEdit.vessel = this.selectedVessel;
    this.turnToEdit.signOff = moment(new Date()).add(1, 'day').toDate();
    this.turnToEdit.signOn = new Date();
    this.turnToEdit.status = 'Planned';
    this.turnToEdit.visa = 'None';
    this.turnToEdit.lOG = 'None';
    this.turnToEdit.relievesTurnId = "";
  }

  confirmTurn(turn: Turn) {
    turn.status = 'Confirmed';
    this.unitOfWork.commit().then(
      x => {
        this.unitOfWork.turnRepo.all().subscribe(t => this.updateTurns());
      }
    );
  }

  updateTurns() {
    this.turnsForVessel = this.getTurnsForVessel();
    this.turns = this.getTurnsOnSchedule();
  }

  saveTurn(turn: Turn) {
    this.turnToEdit = null;
    this.unitOfWork.turnRepo.all().subscribe(t => {
      this.updateTurns();
    });
  }

  cancelTurn() {
    this.turnToEdit = null;
  }

  searchCrew() {
    this.showSearch = true;
  }

  closeSearch() {
    this.showSearch = false;
  }

  printSchedule() {
    (<any>jQuery('.calendar-table')).print();
  }

  getExportLink(): string {
    return this.apiBase + 'api/Schedules/Export/' + this.selectedVessel.id + '/' + this.selectedDate.format('YYYY-MM-DD');
  }
}
