
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './core/auth.service';
import { MessageService } from './core/message.service';
import { ILoginData } from './auth.interface';

import { IChangePasswordRequest } from './core/entities/changepasswordrequest';

@Component({
        selector: 'change-password-component',
    templateUrl: 'change-password.component.html'
})
export class ChangePasswordComponent
{
    passwordForm: FormGroup;
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
    oldPasswordNotValid: boolean = false;

    constructor(fb: FormBuilder, private authService: AuthService, private router: Router, private messageService: MessageService)
    {
        this.passwordForm = fb.group({
            'oldPassword': [null, Validators.compose([Validators.required])],
            'password': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(16)])],
            'repeatPassword': [null, Validators.compose([Validators.required, this.isEqualValidator('password')])]
        });
    }

    isEqualValidator(controlName: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.parent)
                return null;

            let compareControl = control.parent.controls[controlName];
            let compareValue = compareControl.value;

            return control.value != compareControl.value ? { 'noMatch': true } : null;
        }
    }

    submit() {
        let request: IChangePasswordRequest = { oldPassword: this.oldPassword, password: this.newPassword };
        this.authService.changePassword(request).pipe(catchError((err, caught) => {
            return observableOf(false);
        })).subscribe(x => {
            if (!x)
                this.oldPasswordNotValid = true;
            else
                this.messageService.displayMessage('Password changed', 'Password successfully changed', () => this.router.navigateByUrl('/home'));
        });
    }

    cancel() {
        this.router.navigate(['home']);
    }
}
