﻿import { EntityBase } from './entity-model';
import { core } from 'breeze-client';

export class File extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: File) {

    }

    fileUrl: string;

    id: string;
    fileType: string;
    fileName: string;
}