﻿import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';

@Component({
        selector: 'vt-file-upload',
    templateUrl: 'file-upload.component.html',
    styleUrls: ['file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnChanges {
    @Input() apiUrl: string;
    @Output() update: EventEmitter<void> = new EventEmitter<void>();
    uploader: FileUploader;
    hasDropZoneOver: boolean = false;

    constructor() {
    }

    ngOnInit() {
        this.uploader = new FileUploader({
            url: this.apiUrl
            , allowedFileType: ['pdf', 'doc', 'image']
        });
        this.uploader.onCompleteItem = () => this.update.emit();
    }

    ngOnChanges() {
        this.uploader = new FileUploader({
            url: this.apiUrl, allowedFileType: ['pdf', 'doc', 'image']
        });
        this.uploader.onCompleteItem = () => this.update.emit();
    }

    fileOver(over: boolean): void {
        this.hasDropZoneOver = over;
    }
}
