import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';

import { Employee } from '../core/entities/entity-model';
import { VaderoTankUnitOfWorkService } from '../core/vadero-tank-unit-of-work.service';
import { BusyService } from '../core/busy.service';

@Component({
        selector: 'vadero-dashboard',
    templateUrl: 'dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    employees: Employee[];

    constructor(private unitOfWork: VaderoTankUnitOfWorkService, private busyService: BusyService) { }

    ngOnInit() {
    }
}
