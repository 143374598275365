﻿import { Component } from '@angular/core';

import { MessageService } from '../core/message.service';

@Component({
    selector: 'message-center',
    template: `<message-box [title]="messageService.title" [show]="messageService.show" [message]="messageService.message" (closed)="closeMessage()"></message-box>
               <confirm [title]="messageService.title" [show]="messageService.showConfirm" [message]="messageService.message" (confirmed)="confirm($event)"></confirm>`
})
export class MessageCenterComponent {
    constructor(public messageService: MessageService) { }

    closeMessage() {
        this.messageService.runClose();
    }

    public confirm(yesNo: boolean) {
        this.messageService.runConfirm(yesNo);
    }
}
