﻿import { Injectable } from '@angular/core';

@Injectable()
export class MessageService {
    show: boolean = false;
    showConfirm: boolean = false;
    message: string = '';
    title: string = '';
    confirm: (yesNo: boolean) => void;
    close: () => void;

    runConfirm(confirmed: boolean) {
        if (this.confirm != void 0 && this.confirm && typeof this.confirm === 'function')
            this.confirm(confirmed);

        this.clear();
    }

    runClose() {
        if (this.close != void 0 && this.close && typeof this.close === 'function')
            this.close();

        this.clear();
    }

    displayMessage(title: string, message: string, close?: () => void) {
        this.show = true;
        this.title = title;
        this.message = message;
        this.close = close || null;
    }

    displayConfirm(title: string, message: string, confirm?: (yesNo: boolean) => void) {
        this.showConfirm = true;
        this.title = title;
        this.message = message;
        this.confirm = confirm || null;
    }

    clear() {
        this.title = '';
        this.show = false;
        this.showConfirm = false;
        this.message = '';
        this.confirm = null;
        this.close = null;
    }
}