﻿import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CamelCasePipe } from './camelcase.pipe';

interface IBaseItem {
    id: string;
}

@Component({
        selector: 'list-table',
    template: `<table class="table table-hover">
                    <thead>
                        <tr>
                            <th *ngFor="let header of headers">
                                <button type="button" class="btn btn-link" (click)="order(header)">{{header}}&nbsp;<span *ngIf="orderedBy(header)" class="glyphicon" [ngClass]="{ 'glyphicon-chevron-up': reversed, 'glyphicon-chevron-down': !reversed }"></span></button>
                            </th>
                            <th class="icon-col"></th>
                            <th class="icon-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of orderedList">
                            <td *ngFor="let header of headers">
                                <a [routerLink]="getStateLink(row)">{{getRowProperty(row, header)}}</a>
                            </td>
                            <td>
                                <button type="button" (click)="edit(row)" class="btn btn-link glyphicon glyphicon-pencil"></button>
                            </td>
                            <td>
                                <button type="button" (click)="deleteRow(row)" class="btn btn-link glyphicon glyphicon-remove"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>`,
    styleUrls: ['list-table.component.css']
})
export class ListTableComponent implements OnInit, OnChanges
{
    @Input() headers: string[];
    @Input() props: string[];
    @Input() orderBy: string;
    @Input() reversed: boolean;
    @Input() list: IBaseItem[];
    @Input() type: string;
    @Output() delete = new EventEmitter<IBaseItem>();
    orderedList: any[];

    constructor(private router: Router) { }

    orderedBy(header: string): boolean {
        return new CamelCasePipe().transform(header) === this.orderBy;
    }

    ngOnInit() {
        if (typeof this.reversed !== 'boolean')
            this.reversed = false;
        if (Array.isArray(this.headers) && this.headers.length > 0)
            this.order(this.headers[0]);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.order();
    }

    order(header?: string): void {
        let sortBy = header;
        let camelCasePipe = new CamelCasePipe();

        if (!Array.isArray(this.list)) {
            this.orderedList = [];
            return;
        }

        if (this.reversed == void 0) {
            this.reversed = false;
        }

        if (this.useProps) {
            sortBy = this.props[this.headers.indexOf(header)];
        }

        if (sortBy && this.orderBy === camelCasePipe.transform(sortBy)) {
            this.reversed = !this.reversed;
        }

        this.orderBy = sortBy ? camelCasePipe.transform(sortBy) : this.orderBy ? this.orderBy : camelCasePipe.transform(this.headers[0]);

        this.orderedList = this.list.sort((a, b) => a[this.orderBy] > b[this.orderBy] ? 1 : a[this.orderBy] < b[this.orderBy] ? -1 : 0);

        if (this.reversed)
            this.orderedList = this.orderedList.reverse();
    }

    getRowProperty(item: any, propertyName: string): any {
        let prop = propertyName;
        let camelCasePipe = new CamelCasePipe();

        if (this.useProps)
            prop = this.props[this.headers.indexOf(propertyName)];

        if (item[camelCasePipe.transform(prop)])
            return item[camelCasePipe.transform(prop)];

        return null;
    }

    private get useProps(): boolean {
        return this.props && Array.isArray(this.props) && this.props.length === this.headers.length;
    }

    getStateLink(item: IBaseItem): string {
        return '/' + this.type.replace('.', '/') + '/' + item.id;
    }

    edit(item: IBaseItem): void {
        this.router.navigate([this.type.replace('.', '/'), item.id]);
    }

    deleteRow(item: IBaseItem): void {
        this.delete.emit(item);
    }
}
