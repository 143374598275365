﻿import { NgModule } from '@angular/core';
import { EntityManagerProvider } from './entity-manager-provider.service';
import { UnitOfWork, EntityFactory } from './unit-of-work.service';
import { PrepareGuard, CanDeactivateGuard } from './guards';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        EntityManagerProvider,
        UnitOfWork,
        PrepareGuard,
        CanDeactivateGuard
    ]
})
export class DataModule { }