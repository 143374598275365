﻿import { EntityBase, Course, CrewType } from './entity-model';
import { core } from 'breeze-client';

export class CrewGroup extends EntityBase {
    constructor() {
        super();
        this.id = core.getUuid();
    }

    static initializer(entity: CrewGroup) {

    }

    id: string;
    name: string;
    courses: Course[];
    crewTypes: CrewType[];
}